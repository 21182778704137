import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    menu: {
        position: 'absolute',
        right: 0,
        top: theme.spacing(6),
        padding: '16px',
        width: 300,
        background: 'rgba(255, 255, 255, 1)',
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            left: '0',
        },
    },
    menubutton: {
        width: '100%',
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary[900],
        '&:last-child': { marginBottom: '0px', },
        marginBottom: '16px',
        '&:hover': {
            backgroundColor: theme.palette.primary[400],
        },
    },
}))

export default useStyles
