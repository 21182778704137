import PropTypes from 'prop-types'

const DrugWayType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    start_effect: PropTypes.string.isRequired,
    peak_effect: PropTypes.string.isRequired,
    duration_effect: PropTypes.string.isRequired,
})

export default DrugWayType
