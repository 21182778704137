import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Layout from 'components/Layout/Layout'
import { selectPostsByType } from 'stores/post/post.selectors'
import PostType from 'types/Post.type'
import MoreContent from './MoreContent'
import { _ } from '../../helpers/lang'

const mapStateToProps = (state) => {
    return {
        posts: selectPostsByType(state, 'menu-utilitaire'),
    }
}

More.propTypes = {
    posts: PropTypes.arrayOf(PostType),
}

function More(props) {
    return (
        <Layout container title={_('app.more')} hideNavHeader={true}>
            <MoreContent posts={props.posts} />
        </Layout>
    )
}

export default connect(mapStateToProps)(More)
