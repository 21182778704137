import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    tab: {
        color: theme.palette.yellow.main,
    },
    indicator: {
        backgroundColor: theme.palette.yellow.main,
    },
    root: {
        position: 'relative',
        paddingLeft: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            height: theme.spacing(6),
            width: '2px',
        },
        '&:last-child': {
            marginBottom: 0,
        },
        chip: {
            margin: theme.spacing(1, 1, 0, 0),
            borderRadius: 7,
            fontWeight: 'bold',
        },
        emptyValue: {
            height: theme.spacing(4),
            fontWeight: 'bold',
            lineHeight: '2em',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            padding: theme.spacing(2, 3, 2, 3),
            textAlign: 'center',
            [theme.breakpoints.up('xs')]: {
                width: '80%',
            },
            [theme.breakpoints.up('md')]: {
                width: '40%',
            },
        }
    },
    consommationDiv:{
        display: "flex",
        justifyContent: 'start',
        width: "100%",
        paddingBottom: "10px"
    },
    consommationElement:{
        paddingRight: "5px",
        paddingLeft:"12px"
    }

}))
