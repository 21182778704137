import clinicalObservationConstants from 'stores/clinicalObservation/clinicalObservation.constants'

const initialState = []

// all state changes manipulations happen here
function clinicalObservationStore(state = initialState, { type, payload }) {
    switch (type) {
        case clinicalObservationConstants.SEARCH_SUCCESS:
        case clinicalObservationConstants.LIST_SUCCESS:
            return payload.data
        case clinicalObservationConstants.SHOW_SUCCESS:
            return [...initialState, payload.data]
        case clinicalObservationConstants.SEARCH_FAILURE:
            return []
        default:
            return state
    }
}

export default clinicalObservationStore
