import { createSelector } from 'reselect'
import groupBy from 'lodash/groupBy'

export class ClinicalObservationsSelector {
    static selectClinicalObservations(clinicalObservations) {
        return clinicalObservations
    }

    static selectClinicalObservationsByCategory(clinicalObservations, slug) {
        if (slug) {
            return clinicalObservations.filter((d) => d.category.slug === slug)
        }

        return groupBy(clinicalObservations, (d) => d.category.id)
    }

    static selectClinicalObservationBySlug(clinicalObservations, slug) {
        return clinicalObservations.find((d) => d.slug === slug)
    }

    static selectClinicalObservationById(clinicalObservations, id) {
        return clinicalObservations.find((d) => d.id === id)
    }
}

export const selectClinicalObservations = createSelector(
    (state) => state.clinicalObservations,
    ClinicalObservationsSelector.selectClinicalObservations
)

export const selectClinicalObservationById = createSelector(
    (state) => state.clinicalObservations,
    (state, id) => id,
    ClinicalObservationsSelector.selectClinicalObservationById
)

export const selectClinicalObservationBySlug = createSelector(
    (state) => state.clinicalObservations,
    (state, slug) => slug,
    ClinicalObservationsSelector.selectClinicalObservationBySlug
)
