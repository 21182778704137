import React from 'react'
import { useSelector } from 'react-redux'

import Link from 'components/Link/Link'
import Image from 'components/Image/Image'
import { selectWidgetById } from 'stores/widget/widget.selectors'

function WidgetRenderer(props) {
    const id = parseInt(props.data.hProperties.id, 10)
    const widget = useSelector((state) => selectWidgetById(state, id))

    if (!widget) return null

    let component = <span dangerouslySetInnerHTML={{ __html: widget.html }} />

    switch (widget.type) {
        case 'image':
            component = <Image alt={widget.name} src={widget.value} />
            break
        case 'pdf':
        case 'lien':
            component = <Link href={widget.value}>{widget.name}</Link>
            break
        default:
    }

    return <span>{component}</span>
}

export default WidgetRenderer
