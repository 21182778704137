import { createSelector } from 'reselect'

export class DefinitionsSelector {
    static selectDefinitions(definitions) {
        return definitions
    }
}

export const selectDefinitions = createSelector(
    (state) => state.definitions,
    DefinitionsSelector.selectDefinitions
)

