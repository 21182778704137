import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import MuiCard from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { history } from 'stores/root-store'
import { _ } from 'helpers/lang'
import links from 'helpers/links'
import { displayDate } from 'utils/dates'
import ProfileType from 'types/Profile.type'
import useStyles from './ProfileCard.styles'
import Avatar from 'components/Avatar/Avatar'

ProfileCard.propTypes = {
    profile: ProfileType.isRequired,
    reloadProfile: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
}

function ProfileCard(props) {
    const classes = useStyles()
    const [expanded, setExpanded] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const { profile, children, reloadProfile } = props

    const handleExpandClick = (e) => setExpanded(!expanded)

    const handleEditClick = (e) =>
        history.push(links.profile.edit(profile.number))

    const handleOpenMenu = (e) => setAnchorEl(e.currentTarget)

    const handleClose = (e) => setAnchorEl(null)

    const handleReloadClick = (e) => {
        reloadProfile()
        setAnchorEl(null)
    }

    return (
        <MuiCard classes={{ root: classes.Card }}>
            <CardHeader
                classes={{ root: classes.CardHeader }}
                avatar={<Avatar gender={profile.gender} />}
                action={
                    <IconButton
                        onClick={handleOpenMenu}
                        aria-label="profile-menu"
                    >
                        <MoreVertIcon />
                    </IconButton>
                }
                title={<Typography variant="h1">{profile.number}</Typography>}
                subheader={_('profil.updated_at', {
                    value: displayDate(profile.updated_at),
                })}
            />
            <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleEditClick}>
                    {_('profil.editButton')}
                </MenuItem>
                <MenuItem onClick={handleReloadClick}>
                    {_('profil.reloadButton')}
                </MenuItem>
            </Menu>
            <CardActions disableSpacing classes={{ root: classes.CardActions }}>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="Voir plus"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent classes={{ root: classes.CardContent }}>
                    {children}
                </CardContent>
            </Collapse>
        </MuiCard>
    )
}

export default ProfileCard
