import dependencyTypeConstants from 'stores/dependencyType/dependencyType.constants'

const initialState = []

// all state changes manipulations happen here
function dependencyTypeStore(state = initialState, { type, payload }) {
    switch (type) {
        case dependencyTypeConstants.SEARCH_SUCCESS:
        case dependencyTypeConstants.LIST_SUCCESS:
            return payload.data
        case dependencyTypeConstants.SHOW_SUCCESS:
            return [...initialState, payload.data]
        case dependencyTypeConstants.SEARCH_FAILURE:
            return []
        default:
            return state
    }
}

export default dependencyTypeStore
