import React from 'react'
import PropTypes from 'prop-types'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import useStyles from './CategoryAccordions.styles'
import ListButton from 'components/Button/ListButton'
import PostType from 'types/Post.type'

CategoryAccordions.propTypes = {
    
}

function CategoryAccordions({ category, model, link}) {
    const classes = useStyles()
    const [expanded, setExpanded] = React.useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <div className={classes.root}>
            <Accordion
                key={category}
                expanded={expanded === category}
                onChange={handleChange(category)}
                className={classes.accordionRoot}
                style={{margin:'0px !important'}}
            >
                <AccordionSummary
                    expandIcon={expanded === category ? <RemoveIcon className={classes.buttonIcon} /> : <AddIcon className={classes.buttonIcon} />}
                    className={classes.summary}
                >
                    <div className={classes.textContainer}>{category}</div>
                    
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    {model.map((modelData) =>
                        modelData.is_leaf ? (
                            <ListButton
                                key={modelData.id}
                                to={(link)?link.show(modelData.slug):'/'}
                            >
                                <div className={classes.textContainer}>{modelData.name}</div>

                            </ListButton>
                        ) : (
                            <CategoryAccordions
                                category={modelData.name}
                                model={modelData.children}
                                link={link}
                            >
                                <div className={classes.textContainer}>{modelData.name}</div>

                            </CategoryAccordions>
                        )
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default CategoryAccordions
