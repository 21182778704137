import React from 'react'
import PropTypes from 'prop-types'

import { _ } from 'helpers/lang'
import LargeButton from '../../components/Button/LargeButton'
import ListButton from 'components/Button/ListButton'
import ListTypesEnum from '../../constants/enums/ListTypeEnum'
import links from 'helpers/links'
import Inner from 'components/Inner/Inner'
import DrugIcon from 'assets/svg/drugs.svg'
import MedicationIcon from 'assets/svg/medicaments.svg'
import PostType from 'types/Post.type'
import ButtonList from '../../components/Button/ButtonList'
import useStyles from './MoreContent.styles'

MoreContent.propTypes = {
    posts: PropTypes.arrayOf(PostType),
}

function MoreContent() {
    const classes = useStyles
    return (
        <div className={classes.global}>
            <Inner>
                <ListButton
                    to={links[ListTypesEnum.Drug].list}
                    startIcon={<img alt={_('app.drugs')} src={DrugIcon} />}
                >
                    {_('app.drugs')}
                </ListButton>
                <ListButton
                    to={links[ListTypesEnum.Medication].list}
                    startIcon={
                        <img alt={_('app.medications')} src={MedicationIcon} />
                    }
                >
                    {_('app.medications')}
                </ListButton>
                {/* This has to be develop soon
                <ListButton>{_('app.about')}</ListButton>
                <ListButton>{_('app.contact')}</ListButton> */}
            </Inner>
        </div>
    )
}

export default MoreContent
