import React from 'react'
import PropTypes from 'prop-types'
import Topbar from 'components/Topbar/Topbar'
import Bottombar from 'components/Bottombar/Bottombar'
import useStyles from './Layout.styles'
import Header from '../Header/Header'
import ErrorBoundary from 'sentry/ErrorBoundary'
import ScrollToTop from "react-scroll-to-top";
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { Tooltip as MuiTooltip } from '@material-ui/core';

Layout.propTypes = {
    
}

function Layout(props) {
    const { children, container, title, hideNavHeader, isGreyBackground } = props
    const classes = useStyles({isGreyBackground})
    const {deactivateSearch} = props
    return (
        <div className={classes.root}>

            <Topbar deactivateSearch={deactivateSearch} />
            <Header title={title} hideNavHeader={hideNavHeader} />
            <ErrorBoundary>
                {container && <div>{children}</div>}
                {!container && children}
            </ErrorBoundary>

                <ScrollToTop smooth component={
                    <MuiTooltip title={"Haut de la page"}>
                        <ArrowUpward fontSize={"15px"}/>
                    </MuiTooltip>
                } />
            <Bottombar />

        </div>
    )
}

export default Layout
