const wayConstants = {
    LIST_PENDING: 'WAY/LIST_PENDING',
    LIST_SUCCESS: 'WAY/LIST_SUCCESS',
    LIST_FAILURE: 'WAY/LIST_FAILURE',

    SHOW_PENDING: 'WAY/SHOW_PENDING',
    SHOW_SUCCESS: 'WAY/SHOW_SUCCESS',
    SHOW_FAILURE: 'WAY/SHOW_FAILURE',

    SEARCH_PENDING: 'WAY/SEARCH_PENDING',
    SEARCH_SUCCESS: 'WAY/SEARCH_SUCCESS',
    SEARCH_FAILURE: 'WAY/SEARCH_FAILURE',

    CREATE_PENDING: 'WAY/CREATE_PENDING',
    CREATE_SUCCESS: 'WAY/CREATE_SUCCESS',
    CREATE_FAILURE: 'WAY/CREATE_FAILURE',

    UPDATE_PENDING: 'WAY/UPDATE_PENDING',
    UPDATE_SUCCESS: 'WAY/UPDATE_SUCCESS',
    UPDATE_FAILURE: 'WAY/UPDATE_FAILURE',

    DELETE_PENDING: 'WAY/DELETE_PENDING',
    DELETE_SUCCESS: 'WAY/DELETE_SUCCESS',
    DELETE_FAILURE: 'WAY/DELETE_FAILURE',
}

export default wayConstants
