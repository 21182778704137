import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        '@media only screen and (max-width: 600px)': {
            padding: theme.spacing(2, 2),
        },
        padding: theme.spacing(4, 4),
        position: 'relative',
    },
}))

export default useStyles
