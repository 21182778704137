import React from 'react'
import clsx from 'clsx'

import useStyles from './Button.styles'

function ButtonList({ children, center }) {
    const classes = useStyles()
    const classNames = clsx(classes.buttonlist, {
        [classes.center]: center,
    })
    return <div className={classNames}>{children}</div>
}

export default ButtonList
