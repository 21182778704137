import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Link as BaseLink } from 'react-router-dom'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import AttachmentIcon from '@material-ui/icons/Attachment'

import { isOnline } from 'helpers/data'
import env from 'environments/base'
import useStyles from './Link.styles'

Link.propTypes = {
    href: PropTypes.string.isRequired,
    className: PropTypes.string,
}

function Link(props) {
    const { href, children, className } = props
    const classes = useStyles()
    const classNames = clsx(classes.root, className)

    const internal = href.includes(env.appUrl) || href.startsWith('/')
    const pdf =
        href.includes(env.pdfUrl) ||
        href.includes(encodeURIComponent(env.pdfUrl))

    if (internal) {
        const url = href.replace(env.appUrl, '/')
        return (
            <BaseLink className={classNames} to={url}>
                {children}
            </BaseLink>
        )
    }

    return (
        <a
            className={classNames}
            href={href}
            rel="noopener noreferrer"
            target="_blank"
        >
            {!isOnline() && <LinkOffIcon classes={{ root: classes.icon }} />}
            {pdf && <AttachmentIcon classes={{ root: classes.icon }} />}
            {children}
        </a>
    )
}

export default Link
