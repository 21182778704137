import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'
import useStyles from './NavButton.styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import Grid from '@material-ui/core/Grid'
import { IconButton } from '@material-ui/core'

NavButton.propTypes = {
    title: PropTypes.any,
}

function NavButton(props) {
    const classes = useStyles()
    const history = useHistory()
    const { title, link } = props
    return (
        
        <Grid container item className={classes.navButton}>
            <Grid item>
                <IconButton onClick={() => link != undefined ? history.push(link) : history.goBack()}>
                    <ChevronLeftIcon />
                </IconButton>
            </Grid>
            <Grid item className={classes.textRow}>
                <Typography variant="h2" className={classes.text}>
                    {title}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default NavButton
