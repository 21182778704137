import React from 'react'
import {Box, Paper} from '@material-ui/core'
import Inner from 'components/Inner/Inner'
import useStyles from "../../../components/Table/Table.styles";
import {_} from "../../../helpers/lang";
import ModelShow from "../../../components/ModelShow/ModelShow";
import Image from "../../../components/Image/Image";


function PostHeader({post}) {
    const classes = useStyles()

    const nullVerification = (data) => {
        return (data == null ? _('Aucune information disponible') : data)
    }

    const headerInfos = (post === undefined ? [] : [
        {
            label: _('app.objective'),
            data: nullVerification(post.objective)
        },
        {
            label: _('app.control_number'),
            data: nullVerification(post.control_number)
        },
        {
            label: _('app.replaces'),
            data: nullVerification(post.replaces)
        },
        {
            label: _('app.last_revision_date'),
            data: nullVerification(post.last_revision_date)
        },
        {
            label: _('app.scheduled_revision_date'),
            data: nullVerification(post.scheduled_revision_date)
        },
        {
            label: _('app.effective_date'),
            data: nullVerification(post.effective_date)
        },
        {
            label: _('app.approved_by'),
            data: nullVerification(post.approved_by)
        },
    ])
    return (


        <Paper>
            <Inner>

                <ModelShow
                    description={''}
                    header={headerInfos}
                    images={[]}
                    logo={post.logo}
                    signature={post.signature}
                    ways={[]}
                    dosageInfo={[]}
                    selectorsInfos={[]}
                    modelType={""}
                    link={""}
                />
            </Inner>
        </Paper>
    );
}

export default PostHeader