import React, { useMemo, useRef } from "react"
import { Grid, TextField } from "../../../node_modules/@material-ui/core/index"
import SearchIcon from '@material-ui/icons/Search'
import { Formik, Form } from 'formik'
import Button from "components/Button/Button"
import validate from './SearchInput.helpers'
import useStyles from './SearchListInput.styles'
import { _ } from '../../helpers/lang'

function SearchListInput({ onSearch, placeholder, isOnSubmitSearch, initialValue }) {
    const classes = useStyles()

    const handleSearch = ((e) => {
        onSearch(e)
    })

    return (
        <div className={classes.headerDiv}>
            <Grid xs={12} md={10} lg={8} xl={6} item>
                <Grid container className={classes.root} spacing={3} columns={3}>
                    <Grid item xs={12}>
                        <Grid container item className={classes.searchBar}>
                            <Grid item className={classes.inputDiv}>
                                <div className={classes.formContainer}>
                                    <Formik
                                        initialValues={{ term: (initialValue ? initialValue : '') }}
                                        onSubmit={(e) => { isOnSubmitSearch && handleSearch(e.term) }}
                                        validate={validate}
                                    >
                                        {({ values, handleChange, handleBlur }) => {
                                            return (
                                                <Form onChange={(e) => { !isOnSubmitSearch && handleSearch(e.target.value) }}>
                                                    <div style={{display: 'flex'}}>
                                                        <TextField
                                                            className={classes.input}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={values.term}
                                                            name="term"
                                                            inputProps={{ 'aria-label': _('app.Searching') }}
                                                            placeholder={placeholder}
                                                        />
                                                        <button type={isOnSubmitSearch && "submit"} className={classes.button}><SearchIcon style={{ color: '#f1c651' }} /></button>
                                                    </div>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default SearchListInput