import React, { useState } from "react"
import Card from "components/Card/Card"
import ImageSlider from "components/Sliders/ImageSlider"
import SpecText from "components/Spec/SpecText"
import { Tab, Tabs, Typography, Box, Chip, Paper, } from "../../../node_modules/@material-ui/core/index"
import { useStyles } from './ModelShow.styles'
import SpecChip from "components/Spec/SpecChip"
import TabPanel from "components/TabPanel/TabPanel"
import Markdown from "components/Markdown/Markdown"
import Inner from "components/Inner/Inner"
import { _ } from 'helpers/lang'
import SingleWay from "components/Way/SingleWay"
import Image from "../Image/Image";

const ModelShow = ({ description, header, selectorsInfos, images, ways, dosageInfo, modelType, link,
                       selectors = [
                           _('modelShow.intoxication'),
                           _('modelShow.withdrawal'),
                           _('modelShow.interaction')
                       ], definitions, logo, signature}) => {
    const classes = useStyles()
    const [tab, setTab] = useState(0)
    const handleChange = (e, v) => setTab(v)
    let tabIndex = 0

    const sliderBreakpoints = {
        0: {
            slidesPerView: 1,
            spaceBetween: 5,
        },
        540: {
            slidesPerView: 2,
            spaceBetween: 10,
        },
        900: {
            slidesPerView: 3,
            spaceBetween: 5,
        },
        1475: {
            slidesPerView: 4,
            spaceBetween: 5,
        },
        2000: {
            slidesPerView: 6,
            spaceBetween: 5,
        },
    }

    const displayConsumptionWays = (ways) => {
        if (ways && ways.length > 0) {
            return (
                <div>
                    <Typography variant="h6" className={classes.consommationElement}>
                        {_('modelShow.consumption_way')}
                    </Typography>
                    <div className={classes.consommationDiv}>
                        {ways.map((info) => {
                            return (
                                <div className={classes.consommationElement}>
                                    <SingleWay
                                        way={info}
                                        modelType={modelType}
                                        link={link}
                                        definitions={definitions}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        } else {
            return <></>
        }
    }

    const displayChipOrTextHeader = (info) => {
        return (<div> {info !== null &&
            Array.isArray(info.data) ? (
            <Box mt={2}>
                <>
                    <SpecChip
                        label={info.label}
                        data={info.data}
                        link={link}
                        key={info.data}
                        definitions={definitions}
                    />
                </>
            </Box>
        ) : (<Box mt={2}><SpecText key={info.label} label={info.label} data={info.data} /></Box>)}</div>)

    }

    const displayChipOrMarkdown = (info) => {
        if (info !== null) {
            return  Array.isArray(info.data) ? (
            <>
                <Box pt={3} pb={1}>
                    <Typography variant="h4">{info.label}</Typography>
                    <Markdown source={info.introduction} />
                    <SpecChip
                        data={info.data}
                        link={link}
                        definitions={definitions}
                    />
                </Box>
            </>
            ) : (<Box pt={2} pb={3}><Typography variant="h4">{info.label}</Typography><Markdown source={info.data} /></Box>)
        }

    }



    return (
        <div>
                <Card>
                    {(logo && logo.length > 0 ? (
                        logo && (
                            <Box pt={2}>
                                {logo.map((image) => (
                                    <div  style={{ paddingLeft: "13px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Image
                                            alt={image.title}
                                            src={image.url}
                                            width={200}
                                            className="logo_image"
                                        />
                                    </div>
                                ))}
                            </Box>

                        )
                    ) : (<div></div>))}



                    {(logo && logo.length > 0 ? (
                        logo && (
                            <Box pt={2}>
                                {logo.map((image) => (
                                    <div  style={{ paddingRight: "13px", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>

                                    </div>
                                ))}
                            </Box>
                        )
                    ) : (<div></div>))}


                    {(images.length > 0 ? (
                        images && (
                            <Box pt={2}>
                                <ImageSlider
                                    images={images}
                                    breakpoints={sliderBreakpoints}
                                />
                            </Box>
                        )
                    ) : (<div></div>))}


                    <Box pt={2}>
                        {description && <p style={{ padding: "13px" }}>{description}</p>}
                        {header.map((info) => {
                            return displayChipOrTextHeader(info)
                        }
                        )}

                        <Box pt={"14px"}>
                            {displayConsumptionWays(ways)}
                        </Box>

                        {dosageInfo.map((info) => {
                            return displayChipOrTextHeader(info)
                        }
                        )}
                    </Box>
                    {signature ?
                        <Box pt={0}>
                            {signature.map((image) => (
                                <div  style={{ paddingLeft: "13px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Image
                                        alt={image.title}
                                        src={image.url}
                                        width={200}
                                        height={50}
                                        className="signature_image"
                                    />
                                </div>
                            ))}
                        </Box>
                    :''}
                </Card>
            {selectorsInfos.length > 0 ? <>
            <Tabs
                value={tab}
                onChange={handleChange}
                indicatorColor="secondary"
                variant="scrollable"
                scrollButtons="on"
                classes={{ indicator: classes.indicator }}
            >
                {selectors.map((selector) => {
                    return <Tab
                        key={selector}
                        label={selector}
                        classes={{ selected: classes.tab }}
                    />
                })}
            </Tabs>

                <Paper style={{ padding: '24px' }}>
                    {Array.isArray(selectorsInfos) ? (
                        selectorsInfos.map((singleSelectorInfos) => {
                            return (
                                <TabPanel index={tabIndex++} key={0} value={tab}>
                                    {singleSelectorInfos.map((info) => {
                                        return (
                                            displayChipOrMarkdown(info)
                                        )
                                    })}
                                </TabPanel>
                            )
                        })
                    ) : (
                        <></>
                    )}
                </Paper>
            </>
                : <></>}
        </div>
    )
}

export default ModelShow