import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box } from '@material-ui/core'
import Layout from 'components/Layout/Layout'
import Image from 'components/Image/Image'
import PostCard from './PostCard/PostCard'
import { selectPosts } from '../../stores/post/post.selectors'
import postActions from '../../stores/post/post.actions'
import PropTypes from 'prop-types'
import PostType from '../../types/Post.type'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import PostDetail from './PostDetail/PostDetail'
import useStyles from './PostShow.styles'
import Typography from '@material-ui/core/Typography'
import NavButton from 'components/Button/NavButton/NavButton'

function mapStateToProps(state, { match }) {
    const { slug } = match.params
    return {
        slug: slug,
        post: selectPosts(state),
    }
}

const mapDispatchToProps = {
    showPost: postActions.show,
}

PostShow.propTypes = {
    slug: PropTypes.string,
    post: PostType,
    showPost: PropTypes.func.isRequired,
}

function PostShow(props) {
    const classes = useStyles()
    const { slug, post, showPost } = props
    const [isLoading, setIsLoading] = useState(true)
    const [expanded, setExpanded] = React.useState(false)
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }


    useEffect(async () => {
        await showPost(slug.split('-', 1)[0])
        setIsLoading(false)
    }, [setIsLoading])

    const transformStringToObject = (string) => {
        let object = {}
        let array = string.split(/\n## | ##\r/)
        for (let i = 0; i < array.length; i += 2) {
            object = {
                ...object,
                [array[i].replace('## ', '')]: array[i + 1]
            }
        }
        return object
    }

    const postTextObject = transformStringToObject((post.text) ? post.text : '')

    return (
        <Layout title={''}>
            {isLoading || post === undefined ? (
                <LoadingSpinner style={{ marginTop: '5%' }} />
            ) : (
                <div>
                    <div className={{ backgroundColor: '#f0f0f0' }}>
                        <Typography variant="h4">
                            <NavButton title={post.name} />
                        </Typography>
                    </div>

                    <PostDetail post={postTextObject} />
                </div>
            )}
        </Layout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PostShow)
