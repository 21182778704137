import drugCategoryService from 'stores/drug-category/drug-category.service'
import drugCategoryConstants from 'stores/drug-category/drug-category.constants'

function list() {
    function request() {
        return { type: drugCategoryConstants.LIST_PENDING }
    }

    function success(payload) {
        return { type: drugCategoryConstants.LIST_SUCCESS, payload }
    }

    function failure(error) {
        return { type: drugCategoryConstants.LIST_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request())
        return drugCategoryService.list().then(
            (data) => {
                dispatch(success(data))
                return true
            },
            (error) => {
                dispatch(failure(error))
                return false
            }
        )
    }
}

const drugCategoryActions = {
    list,
}

export default drugCategoryActions
