import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import {
    Box,
    Grid,
    Typography,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    FormHelperText,
    ListItemText,
    Checkbox,
} from '@material-ui/core'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { ColorContext } from 'helpers/theme'
import { _ } from 'helpers/lang'
import genres from 'constants/lists/genres'
import consommateurTypes from 'constants/lists/consommateur-types'
import programmes from 'constants/lists/programmes'
import stades from 'constants/lists/stades'
import { displayDrugName } from 'utils/functions'
import Layout from 'components/Layout/Layout'
import Button from 'components/Button/Button'
import { selectProfileByNumber } from 'stores/profile/profile.selectors'
import { selectDrugs } from 'stores/drug/drug.selectors'
import profileActions from 'stores/profile/profile.actions'
import { selectMedicaments } from 'stores/medicament/medicament.selectors'
import { selectTroubles } from 'stores/trouble/trouble.selectors'
import useStyles from './ProfileForm.styles'
import validate from './ProfileForm.helpers'
import ProfileType from 'types/Profile.type'
import DrugType from 'types/Drug.type'
import MedicamentType from 'types/Medicament.type'
import TroubleType from 'types/Trouble.type'
import ProfilsIcon from 'assets/svg/profils.svg'

const mapStateToProps = (state, { match }) => {
    return {
        profile: selectProfileByNumber(state, match.params.slug),
        drugs: selectDrugs(state),
        medicaments: selectMedicaments(state),
        troubles: selectTroubles(state),
    }
}

const mapDispatchToProps = {
    create: profileActions.create,
    update: profileActions.update,
}

ProfileForm.propTypes = {
    profile: ProfileType,
    drugs: PropTypes.arrayOf(DrugType).isRequired,
    medicaments: PropTypes.arrayOf(MedicamentType).isRequired,
    troubles: PropTypes.arrayOf(TroubleType).isRequired,
    create: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
}

const initialValues = {
    number: '',
    gender: '',
    birthday: null,
    consommateur: '',
    drugs: [],
    medicaments: [],
    trouble_id: '',
    start: null,
    programme: '',
    stade: '',
}

function ProfileForm(props) {
    const classes = useStyles()

    const { profile, drugs, medicaments, troubles, create, update } = props

    const editMode = !!profile
    const formValues = editMode ? profile : initialValues
    const submit = editMode ? update : create
    const buttonLabel = editMode ? _('app.edit') : _('app.add')

    const handleSubmit = (values, { setSubmitting }) => {
        setSubmitting(true)

        submit(values).then((success) => {
            if (!success) setSubmitting(false)
        })
    }

    return (
        <Layout title={_('app.profiles')}>
            <Box py={3} px={2} mb={2} bgcolor="primary.light">
                <Grid container justifyContent="center" align="center">
                    <Grid item>
                        <div className={classes.headerPicto}>
                            <img alt="Profils" src={ProfilsIcon} />
                            {!editMode && <span>+</span>}
                        </div>
                    </Grid>
                    <Grid item>
                        <Typography variant="h3">
                            {editMode
                                ? _('profil.editTitle')
                                : _('profil.createTitle')}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Formik
                initialValues={formValues}
                validate={validate}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        setFieldValue,
                        handleBlur,
                    } = props

                    const checkErrors = (name) => {
                        // (bug) touched[name] vaut un empty array au submit pour les select multiples.
                        // https://github.com/formium/formik/issues/1712
                        let isTouched
                        if (Array.isArray(touched[name])) {
                            isTouched = true
                        } else {
                            isTouched = touched[name]
                        }

                        return errors[name] && isTouched
                    }

                    const disabled = isSubmitting

                    return (
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <div className={classes.inner}>
                                <Form className={classes.form}>
                                    <TextField
                                        disabled={editMode}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                        label={_('field.number')}
                                        id="number"
                                        name="number"
                                        value={values.number}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={checkErrors('number')}
                                        helperText={
                                            checkErrors('number')
                                                ? errors.number
                                                : undefined
                                        }
                                    />

                                    <TextField
                                        select
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                        label={_('field.gender')}
                                        id="gender"
                                        name="gender"
                                        value={values.gender}
                                        onChange={handleChange('gender')}
                                        onBlur={handleBlur}
                                        error={checkErrors('gender')}
                                        helperText={
                                            checkErrors('gender')
                                                ? errors.gender
                                                : undefined
                                        }
                                    >
                                        {genres.map((i, index) => (
                                            <MenuItem
                                                key={index}
                                                value={i.value}
                                            >
                                                {i.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    <KeyboardDatePicker
                                        autoOk
                                        fullWidth
                                        disableFuture
                                        inputVariant="outlined"
                                        size="small"
                                        margin="dense"
                                        label={_('field.birthday')}
                                        id="birthday"
                                        name="birthday"
                                        format="yyyy-MM-DD"
                                        value={values.birthday}
                                        onChange={(v) =>
                                            setFieldValue(
                                                'birthday',
                                                v.format('yyyy-MM-DD')
                                            )
                                        }
                                        onBlur={handleBlur}
                                        error={checkErrors('birthday')}
                                        helperText={
                                            checkErrors('birthday')
                                                ? errors.birthday
                                                : undefined
                                        }
                                    />

                                    <TextField
                                        select
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                        label={_('field.consommateur')}
                                        id="consommateur"
                                        name="consommateur"
                                        value={values.consommateur}
                                        onChange={handleChange('consommateur')}
                                        onBlur={handleBlur}
                                        error={checkErrors('consommateur')}
                                        helperText={
                                            checkErrors('consommateur')
                                                ? errors.consommateur
                                                : undefined
                                        }
                                    >
                                        {consommateurTypes.map((i) => (
                                            <MenuItem
                                                key={i.value}
                                                value={i.value}
                                            >
                                                {i.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <InputLabel
                                            className="MuiInputLabel-marginDense MuiInputLabel-outlined"
                                            id="label-drugs"
                                        >
                                            {_('field.drugs')}
                                        </InputLabel>

                                        <Select
                                            multiple
                                            fullWidth
                                            labelId="label-drugs"
                                            variant="outlined"
                                            size="small"
                                            margin="dense"
                                            label={_('field.drugs')}
                                            id="drugs"
                                            name="drugs"
                                            value={values.drugs}
                                            renderValue={(values) =>
                                                values
                                                    .map((v) =>
                                                        displayDrugName(
                                                            drugs,
                                                            v
                                                        )
                                                    )
                                                    .join(', ')
                                            }
                                            onChange={handleChange('drugs')}
                                            onBlur={handleBlur}
                                            error={checkErrors('drugs')}
                                        >
                                            {drugs.map((i) => (
                                                <MenuItem
                                                    key={i.id}
                                                    value={i.id}
                                                >
                                                    <Checkbox
                                                        color="primary"
                                                        checked={values.drugs.includes(
                                                            i.id
                                                        )}
                                                    />
                                                    <ListItemText
                                                        primary={i.name}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Select>

                                        {checkErrors('drugs') && (
                                            <FormHelperText className="MuiFormHelperText-contained Mui-error">
                                                {errors.drugs}
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <InputLabel
                                            className="MuiInputLabel-marginDense MuiInputLabel-outlined"
                                            id="label-medicaments"
                                        >
                                            {_('field.medicaments')}
                                        </InputLabel>

                                        <Select
                                            multiple
                                            fullWidth
                                            labelId="label-medicaments"
                                            variant="outlined"
                                            size="small"
                                            margin="dense"
                                            label={_('field.medicaments')}
                                            id="medicaments"
                                            name="medicaments"
                                            value={values.medicaments}
                                            renderValue={(values) =>
                                                values
                                                    .map((v) =>
                                                        displayDrugName(
                                                            medicaments,
                                                            v
                                                        )
                                                    )
                                                    .join(', ')
                                            }
                                            onChange={handleChange(
                                                'medicaments'
                                            )}
                                            onBlur={handleBlur}
                                            error={checkErrors('medicaments')}
                                        >
                                            {medicaments.map((i) => (
                                                <MenuItem
                                                    key={i.id}
                                                    value={i.id}
                                                >
                                                    <Checkbox
                                                        color="primary"
                                                        checked={values.medicaments.includes(
                                                            i.id
                                                        )}
                                                    />
                                                    <ListItemText
                                                        primary={i.name}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Select>

                                        {checkErrors('medicaments') && (
                                            <FormHelperText className="MuiFormHelperText-contained Mui-error">
                                                {errors.medicaments}
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                    <TextField
                                        select
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                        label={_('field.trouble')}
                                        id="trouble_id"
                                        name="trouble_id"
                                        value={values.trouble_id}
                                        onChange={handleChange('trouble_id')}
                                        onBlur={handleBlur}
                                        error={checkErrors('trouble_id')}
                                        helperText={
                                            checkErrors('trouble_id')
                                                ? errors.trouble_id
                                                : undefined
                                        }
                                    >
                                        {troubles.map((i) => (
                                            <MenuItem key={i.id} value={i.id}>
                                                {i.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    <KeyboardDatePicker
                                        autoOk
                                        fullWidth
                                        inputVariant="outlined"
                                        size="small"
                                        margin="dense"
                                        label={_('field.start_date')}
                                        id="start"
                                        name="start"
                                        format="yyyy-MM-DD"
                                        value={values.start}
                                        onChange={(v) =>
                                            setFieldValue(
                                                'start',
                                                v.format('yyyy-MM-DD')
                                            )
                                        }
                                        onBlur={handleBlur}
                                        error={checkErrors('start')}
                                        helperText={
                                            checkErrors('start')
                                                ? errors.start
                                                : undefined
                                        }
                                    />

                                    <TextField
                                        select
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                        label={_('field.programme')}
                                        id="programme"
                                        name="programme"
                                        value={values.programme}
                                        onChange={handleChange('programme')}
                                        onBlur={handleBlur}
                                        error={checkErrors('programme')}
                                        helperText={
                                            checkErrors('programme')
                                                ? errors.programme
                                                : undefined
                                        }
                                    >
                                        {programmes.map((i) => (
                                            <MenuItem
                                                key={i.value}
                                                value={i.value}
                                            >
                                                {i.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    <TextField
                                        select
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                        label={_('field.stade')}
                                        id="stade"
                                        name="stade"
                                        value={values.stade}
                                        onChange={handleChange('stade')}
                                        onBlur={handleBlur}
                                        error={checkErrors('stade')}
                                        helperText={
                                            checkErrors('stade')
                                                ? errors.stade
                                                : undefined
                                        }
                                    >
                                        {stades.map((i) => (
                                            <MenuItem
                                                key={i.value}
                                                value={i.value}
                                            >
                                                {i.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    <Button type="submit" disabled={disabled}>
                                        {buttonLabel}
                                    </Button>
                                </Form>
                            </div>
                        </MuiPickersUtilsProvider>
                    )
                }}
            </Formik>
        </Layout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm)
