import React from 'react'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import SimpleReactLightbox from 'simple-react-lightbox'
import ErrorBoundary from '../sentry/ErrorBoundary'
import './App.css'
import Routes from 'app/Routes'
import Alert from 'components/Alert/Alert'
import { theme, ColorContext } from 'helpers/theme'
import { HistoryType } from 'types/ReactRouter.type'
import { BrowserRouter } from 'react-router-dom'
import TagManager from 'react-gtm-module'

const propTypes = {
    history: HistoryType.isRequired,
}

const tagManagerArgs = {
    gtmId: 'GTM-M43SNRM'
};
TagManager.initialize(tagManagerArgs)

class App extends React.Component {
    state = { topbarColor: 'yellow', bgColor: 'primary' }
    
    setTopbarColor = (topbarColor) => this.setState({ topbarColor })

    setBgColor = (bgColor) => this.setState({ bgColor })

    render() {
        const value = {
            colors: { ...this.state },
            setTopbarColor: this.setTopbarColor,
            setBgColor: this.setBgColor,
        }
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ColorContext.Provider value={value}>
                    <SimpleReactLightbox>
                        <ErrorBoundary>
                            <BrowserRouter>
                                <Routes />
                            </BrowserRouter>
                        </ErrorBoundary>
                    </SimpleReactLightbox>
                </ColorContext.Provider>
                <Alert />
            </ThemeProvider>
        )
    }
}

App.propTypes = propTypes

export default App
