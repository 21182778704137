import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Layout from 'components/Layout/Layout'
import { selectProfileByNumber } from 'stores/profile/profile.selectors'
import profileActions from 'stores/profile/profile.actions'
import useStyles from './ProfileShowPhase.styles'
import ProfileType from 'types/Profile.type'
import { history } from 'stores/root-store'
import links from 'helpers/links'
import PhaseContent from './PhaseContent/PhaseContent'
import { _ } from '../../helpers/lang'

const mapStateToProps = (state, { match }) => {
    const profile = selectProfileByNumber(state, match.params.slug)
    return {
        profile,
        phase: parseInt(match.params.phase, 10) || 1,
    }
}

const mapDispatchToProps = {
    show: profileActions.show,
}

Phase.propTypes = {
    profile: ProfileType,
    phase: PropTypes.number,
    show: PropTypes.func.isRequired,
}

Phase.defaultProps = {
    phase: 1,
}

function Phase({ profile, phase, show }) {
    const classes = useStyles()
    const [chemin, setChemin] = useState()

    const reloadProfile = () => show(profile.number)

    const handleChange = (e, v) =>
        history.push(links.profile.phase(profile.number, v + 1))

    useEffect(() => {
        if (!profile) return
        reloadProfile()
    }, [])

    useEffect(() => {
        setChemin(profile.chemins.find((c) => parseInt(c.phase, 10) === phase))
    }, [profile, phase])

    // todo 404
    if (!profile || !chemin) return null

    return (
        <Layout title={_('app.profiles')}>
            <ListItem
                button
                onClick={() => history.push(links.profile.show(profile.number))}
            >
                <ListItemIcon>
                    <ChevronLeftIcon />
                </ListItemIcon>
                <ListItemText primary={profile.number} />
            </ListItem>

            <Tabs
                value={phase - 1}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="on"
            >
                {profile.chemins.map((chemin) => (
                    <Tab key={chemin.id} label={`Phase ${chemin.phase}`} />
                ))}
            </Tabs>

            <Box p={3} pb={0}>
                <Typography variant="h2">{chemin.name}</Typography>
            </Box>

            <PhaseContent chemin={chemin} />
        </Layout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Phase)
