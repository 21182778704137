import React from 'react'
import MuiCard from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import useStyles from './PostCard.styles'
import PostType from 'types/Post.type'
import NavButton from 'components/Button/NavButton/NavButton'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'

PostCard.propTypes = {
    post: PostType.isRequired,
}

function PostCard(props) {
    const classes = useStyles()
    const { post, children } = props
    
    return (
        <MuiCard classes={{ root: classes.Card }}>
            <CardHeader
                classes={{ root: classes.CardHeader }}
                title={
                    <div className={{ backgroundColor: '#f0f0f0' }}>
                        <Typography variant="h4">
                            <NavButton title={post.name} />
                        </Typography>
                    </div>
                }
            />
            <CardContent classes={{ root: classes.CardContent }}>
                {children}
            </CardContent>
        </MuiCard>
    )
}

export default PostCard
