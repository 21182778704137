import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Box, Button, Modal, Typography } from '@material-ui/core'
import Image from '../Image/Image'
import './ImageSlider.css'
import 'swiper/swiper-bundle.min.css'
import PropTypes from 'prop-types'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import merge from 'lodash/merge'

ImageSlider.propTypes = {
    images: PropTypes.array.isRequired,
    slidesPerView: PropTypes.number,
    loopable: PropTypes.bool,
    breakpoints: PropTypes.object,
    lightBoxOptions: PropTypes.object,
}

ImageSlider.defaultProps = {
    slidesPerView: 3,
    loopable: false,
    breakpoints: {},
    lightBoxOptions: {
        buttons: {
            showAutoplayButton: false,
            showCloseButton: true,
            showDownloadButton: false,
            showFullscreenButton: true,
            showNextButton: true,
            showPrevButton: true,
            showThumbnailsButton: false,
            size: '40px',
        },
    },
}

function ImageSlider(props) {
    const lightBoxOptions = merge(
        {
            buttons: {
                showAutoplayButton: false,
                showCloseButton: true,
                showDownloadButton: false,
                showFullscreenButton: true,
                showNextButton: true,
                showPrevButton: true,
                showThumbnailsButton: false,
                size: '40px',
            },
        },
        props.lightBoxOptions
    )

    return (
        <SimpleReactLightbox>
            <SRLWrapper options={lightBoxOptions}>
                <Swiper
                    breakpoints={props.breakpoints}
                    loop={props.loopable}
                    className="mySwiper"
                >
                    {props.images.map((image) => (
                        <SwiperSlide key={image.id}>
                            <div className={`swiperimage swiper-${image.id}`}>
                                <a href={image.url}>
                                    <Image
                                        alt={image.title}
                                        src={image.url}
                                        className="slider_image"
                                    />
                                </a>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </SRLWrapper>
        </SimpleReactLightbox>
    )
}

export default ImageSlider
