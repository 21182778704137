import { createSelector } from 'reselect'

export class DrugsCategoriesSelector {
    static selectDrugsCategories(drugCategories) {
        return drugCategories
    }

    static selectDrugCategoryBySlug(drugCategories, slug) {
        return drugCategories.find((c) => c.slug === slug)
    }
}

export const selectDrugsCategories = createSelector(
    (state) => state.drugCategories,
    DrugsCategoriesSelector.selectDrugsCategories
)

export const selectDrugCategoryBySlug = createSelector(
    (state) => state.drugCategories,
    (state, slug) => slug,
    DrugsCategoriesSelector.selectDrugCategoryBySlug
)
