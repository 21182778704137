import React, { useRef } from 'react'
import { Formik, Form } from 'formik'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import { _ } from 'helpers/lang'
import useStyles from './SearchInput.styles'
import validate from './SearchInput.helpers'
import links from 'helpers/links'
import PropTypes from 'prop-types'
import postActions from '../../stores/post/post.actions'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

const mapStateToProps = (state, { match }) => {
    return {}
}

const mapDispatchToProps = {
    search: postActions.search,
}

SearchInput.propTypes = {
    search: PropTypes.func.isRequired,
}

function SearchInput({ search }) {
    const classes = useStyles()
    const history = useHistory()
    
    const handleSubmit = (e) => {
        history.push(links.post.search(e.term))
    }

    return (
        <Formik
            initialValues={{ term: '' }}
            validate={validate}
            onSubmit={(e) => handleSubmit(e)}
        >
            {({ values, handleChange, handleBlur }) => {
                return (
                    <Form>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                value={values.term}
                                name="term"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputProps={{ 'aria-label': 'Recherche' }}
                                placeholder={_('app.search')}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                            />
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}
//export default SearchInput
export default connect(mapStateToProps, mapDispatchToProps)(SearchInput)
