import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { selectDrugs } from 'stores/drug/drug.selectors'
import { selectDrugsCategories } from 'stores/drug-category/drug-category.selectors'
import { selectDependencyTypes } from 'stores/dependencyType/dependencyType.selectors'
import { selectWays } from 'stores/way/way.selectors'
import { selectClinicalObservations } from 'stores/clinicalObservation/clinicalObservation.selectors'
import Listing from '../../components/Listing/Listing'
import ListTypesEnum from '../../constants/enums/ListTypeEnum'
import { _ } from '../../helpers/lang'
import drugActions from '../../stores/drug/drug.actions'
import drugCategoryActions from 'stores/drug-category/drug-category.actions'
import dependencyTypeActions from 'stores/dependencyType/dependencyType.actions'
import wayActions from 'stores/way/way.actions'
import clinicalObservationActions from 'stores/clinicalObservation/clinicalObservation.actions'
import ModelLayout from 'components/ModelLayout/ModelLayout'
import { getLocationInitialFilter } from 'utils/model'
import useSearchFilter from 'hooks/useSearchFilter'
import { useLocation } from "react-router-dom";
import {selectDefinitions} from "../../stores/definition/definition.selectors";
import definitionActions from "../../stores/definition/definition.actions";
import {convertFormToObjectValues} from "../../components/Filters/utils";

function mapStateToProps(state) {
    return {
        drugs: selectDrugs(state),
        drugCategories: selectDrugsCategories(state),
        dependencyTypes: selectDependencyTypes(state),
        ways: selectWays(state),
        clinicalObservations: selectClinicalObservations(state),
        definitions: selectDefinitions(state),
    }
}

const mapDispatchToProps = {
    listDrug: drugActions.list,
    listDrugCategory: drugCategoryActions.list,
    listDependencyType: dependencyTypeActions.list,
    listWay: wayActions.list,
    listClinicalObservation: clinicalObservationActions.list,
    listDefinitions: definitionActions.list
}

DrugList.propTypes = {

}

function DrugList(props) {
    const { drugs, listDrug } = props
    const { drugCategories, listDrugCategory } = props
    const { dependencyTypes, listDependencyType } = props
    const { definitions, listDefinitions } = props
    const { ways, listWay } = props
    const { clinicalObservations, listClinicalObservation } = props
    const title = _('app.drugs')
    const [isLoading, setIsLoading] = useState(true)
    const [filteredDrugs, handleSearch] = useSearchFilter(Array.isArray(drugs[0]) ? drugs[0] : drugs, (drug) => drug.name)
    const location = useLocation();
    const [filters, setFilter] = useState(location.state ? location.state.filters : null)

    const handleOnFilter = (result) => {
        setFilter(result.filters)
    }

    const filterBaseObject = {
        category: {},
        dependencyName: {},
        wayName: {},
        intoxicationObservation: {},
        withdrawalObservation: {}
    }

    useEffect(async () => {
        setIsLoading(true)
        await listDrug(filters && JSON.stringify(filters))
        setIsLoading(false)
    }, [filters])

    useEffect(async () => {
        setIsLoading(true)
        await Promise.all([
            listDrugCategory(),
            listDependencyType(),
            listWay(),
            listClinicalObservation(),
            listDefinitions()
        ])
        setIsLoading(false)
    }, [])

    const fillFilterArray = (infos) => {
        let filterArray = []
        infos.forEach(info => {
            filterArray.push({
                value: info.name,
                label: info.name,
                name: info.name
            })
        })
        return filterArray
    }

    const drugCategoriesFilters = {
        label: _('drug.drug_category'),
        key: 'category',
        filters: []
    }

    drugCategories.forEach(category => {
        drugCategoriesFilters.filters.push({
            value: category.id.toString(),
            label: category.name,
            name: category.id.toString()
        })
    })

    const dependenciesFilters = {
        label: _('drug.dependency'),
        key: 'dependencyName',
        filters: fillFilterArray(dependencyTypes)
    }

    const consumptionWaysFilters = {
        label: _('drug.consumption_way'),
        key: 'wayName',
        filters: fillFilterArray(ways)
    }

    const intoxicationObservationsFilters = {
        label: _('drug.intoxication_observations'),
        key: 'intoxicationObservation',
        filters: fillFilterArray(clinicalObservations)
    }

    const withdrawalObservationsFilters = {
        label: _('drug.withdrawal_observations'),
        key: 'withdrawalObservation',
        filters: fillFilterArray(clinicalObservations)
    }

    const filterSections = [
        drugCategoriesFilters,
        dependenciesFilters,
        consumptionWaysFilters,
        intoxicationObservationsFilters,
        withdrawalObservationsFilters
    ]

    const handleRemoveAllAppliedFilters = (updatedObject = null) => {
        if(updatedObject) {
            setFilter(convertFormToObjectValues(updatedObject))
        } else {
            setFilter(null)
        }
    }

    return (
        <ModelLayout
            title={_('drug.title')}
            handleSearch={handleSearch}
            isLoading={isLoading}
        >
            {filteredDrugs &&
                <Listing
                    list={filteredDrugs}
                    listType={ListTypesEnum.Drug}
                    title={title}
                    filterSections={filterSections}
                    onFilter={handleOnFilter}
                    initialFilter={getLocationInitialFilter(location, filterBaseObject)}
                    onRemoveAllAppliedFilters={handleRemoveAllAppliedFilters}
                    definitions={definitions}
                />
            }
        </ModelLayout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(DrugList)

