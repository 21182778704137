import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { _ } from 'helpers/lang'
import links from 'helpers/links'
import { isAuthenticated } from 'helpers/auth'
import authActions from 'stores/auth/auth.actions'
import alertActions from 'stores/alert/alert.actions'
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm'
import { HistoryType } from 'types/ReactRouter.type'

const mapStateToProps = (state, { history }) => {
    return { history }
}

const mapDispatchToProps = {
    error: alertActions.error,
    sendForgotPasswordMail: authActions.sendForgotPasswordMail,
}

const propTypes = {
    history: HistoryType.isRequired,
    error: PropTypes.func.isRequired,
    sendForgotPasswordMail: PropTypes.func.isRequired,
}

class ForgotPassword extends React.Component {
    componentDidMount() {
        if (isAuthenticated()) {
            this.props.error(_('alert.alreadyLogged'))
            this.props.history.push(links.home)
        }
    }

    handleSubmit = ({ email }, { setSubmitting }) => {
        if (email) {
            setSubmitting(true)
            this.props.sendForgotPasswordMail(email).then((success) => {
                if (!success) setSubmitting(false)
            })
        }
    }

    render() {
        return <ForgotPasswordForm handleSubmit={this.handleSubmit} />
    }
}

ForgotPassword.propTypes = propTypes

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
