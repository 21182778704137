import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import { _ } from 'helpers/lang'
import { isOnline } from 'helpers/data'
import links from 'helpers/links'
import Button from 'components/Button/Button'
import useStyles from './LoginForm.styles'
import validate from './LoginForm.helpers'
import AddictaLogo from 'components/Logo/Addicta'

LoginForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
}

function LoginForm({ handleSubmit }) {
    const classes = useStyles()

    return (
        <Container className={classes.container} component="main" maxWidth="xs">
            <div className={classes.paper}>
                <div className={classes.header}>
                    <AddictaLogo className={classes.logo} alt={_('app.name')} />
                </div>

                {isOnline() ? (
                    <Formik
                        initialValues={{ email: '', password: '' }}
                        validate={validate}
                        onSubmit={handleSubmit}
                    >
                        {(props) => {
                            const {
                                values,
                                touched,
                                errors,
                                isSubmitting,
                                handleChange,
                                handleBlur,
                            } = props

                            const disabled = isSubmitting

                            return (
                                <Form className={classes.form}>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        type="email"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label={_('field.email')}
                                        name="email"
                                        autoFocus
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.email && touched.email}
                                        helperText={
                                            errors.email && touched.email
                                                ? errors.email
                                                : undefined
                                        }
                                    />

                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label={_('field.password')}
                                        type="password"
                                        id="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={
                                            errors.password && touched.password
                                        }
                                        helperText={
                                            errors.password && touched.password
                                                ? errors.password
                                                : undefined
                                        }
                                    />

                                    <div className={classes.buttonlist}>
                                        <Button
                                            type="submit"
                                            disabled={disabled}
                                        >
                                            {_('auth.loginButton')}
                                        </Button>
                                    </div>
                                    <Grid container>
                                        <Grid item xs>
                                            <Link
                                                className={classes.link}
                                                to={links.password.forgot}
                                            >
                                                {_('auth.forgotPasswordTitle')}
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                ) : (
                    <div>
                        <Typography variant="h2">
                            {_('app.offline_title')}
                        </Typography>
                        <Typography>{_('app.offline_text')}</Typography>
                    </div>
                )}
            </div>
        </Container>
    )
}

export default LoginForm
