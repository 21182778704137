const postConstants = {
    LIST_PENDING: 'POST/LIST_PENDING',
    LIST_SUCCESS: 'POST/LIST_SUCCESS',
    LIST_FAILURE: 'POST/LIST_FAILURE',

    SHOW_PENDING: 'POST/SHOW_PENDING',
    SHOW_SUCCESS: 'POST/SHOW_SUCCESS',
    SHOW_FAILURE: 'POST/SHOW_FAILURE',

    SEARCH_PENDING: 'POST/SEARCH_PENDING',
    SEARCH_SUCCESS: 'POST/SEARCH_SUCCESS',
    SEARCH_FAILURE: 'POST/SEARCH_FAILURE',

    CREATE_PENDING: 'POST/CREATE_PENDING',
    CREATE_SUCCESS: 'POST/CREATE_SUCCESS',
    CREATE_FAILURE: 'POST/CREATE_FAILURE',

    UPDATE_PENDING: 'POST/UPDATE_PENDING',
    UPDATE_SUCCESS: 'POST/UPDATE_SUCCESS',
    UPDATE_FAILURE: 'POST/UPDATE_FAILURE',

    DELETE_PENDING: 'POST/DELETE_PENDING',
    DELETE_SUCCESS: 'POST/DELETE_SUCCESS',
    DELETE_FAILURE: 'POST/DELETE_FAILURE',
}

export default postConstants
