import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    navButton: {
        height: '60px',
        alignItems: 'center',
        justifyItems: 'flex-start',
        padding: theme.spacing(1),
        flexWrap: 'nowrap',
    },
    text: {
        textOverflow: 'ellipsis',
        alignItems: 'flex-end',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    textRow: {
        width: '80%',
    },
}))

export default useStyles
