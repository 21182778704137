import PropTypes from 'prop-types'
import CheminType from './Chemin.type'

const ProfileType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    birthday: PropTypes.string.isRequired,
    consommateur: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired,
    programme: PropTypes.string.isRequired,
    stade: PropTypes.string.isRequired,
    trouble_id: PropTypes.number.isRequired,
    drugs: PropTypes.arrayOf(PropTypes.number).isRequired,
    medicaments: PropTypes.arrayOf(PropTypes.number).isRequired,
    chemins: PropTypes.arrayOf(CheminType).isRequired,
    updated_at: PropTypes.string.isRequired,
})

export default ProfileType
