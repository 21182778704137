import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import useStyles from './Spec.styles'

SpecText.propTypes = {
    label: PropTypes.string.isRequired,
    data: PropTypes.string.isRequired,
}

export default function SpecText({ label, data }) {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Typography variant="h6">{label}</Typography>
            <Typography className={classes.text}>{data}</Typography>
        </div>
    )
}
