import React from 'react'
import Typography from '@material-ui/core/Typography'
import Layout from 'components/Layout/Layout'
import BackButton from 'components/Button/BackButton'
import ButtonList from 'components/Button/ButtonList'
import { _ } from 'helpers/lang'

const NotFound = () => {
    return (
        <Layout container>
            <Typography variant="h1" paragraph>
                404
            </Typography>
            <Typography paragraph>{_('alert.404')}</Typography>
            <ButtonList>
                <BackButton />
            </ButtonList>
        </Layout>
    )
}

export default NotFound
