import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    emergency: {
        background: '#e4002b',
        color: 'white',
        '&:hover': {
            background: '#e4002b',
        },
    },
    global:{
        padding:'5px'
    }
}))

export default useStyles
