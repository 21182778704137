const drugConstants = {
    LIST_PENDING: 'DRUG/LIST_PENDING',
    LIST_SUCCESS: 'DRUG/LIST_SUCCESS',
    LIST_FAILURE: 'DRUG/LIST_FAILURE',

    SHOW_PENDING: 'DRUG/SHOW_PENDING',
    SHOW_SUCCESS: 'DRUG/SHOW_SUCCESS',
    SHOW_FAILURE: 'DRUG/SHOW_FAILURE',

    SEARCH_PENDING: 'DRUG/SEARCH_PENDING',
    SEARCH_SUCCESS: 'DRUG/SEARCH_SUCCESS',
    SEARCH_FAILURE: 'DRUG/SEARCH_FAILURE',

    CREATE_PENDING: 'DRUG/CREATE_PENDING',
    CREATE_SUCCESS: 'DRUG/CREATE_SUCCESS',
    CREATE_FAILURE: 'DRUG/CREATE_FAILURE',

    UPDATE_PENDING: 'DRUG/UPDATE_PENDING',
    UPDATE_SUCCESS: 'DRUG/UPDATE_SUCCESS',
    UPDATE_FAILURE: 'DRUG/UPDATE_FAILURE',

    DELETE_PENDING: 'DRUG/DELETE_PENDING',
    DELETE_SUCCESS: 'DRUG/DELETE_SUCCESS',
    DELETE_FAILURE: 'DRUG/DELETE_FAILURE',
}

export default drugConstants
