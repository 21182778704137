import dependencyTypeService from 'stores/dependencyType/dependencyType.service'
import dependencyTypeConstants from 'stores/dependencyType/dependencyType.constants'
import { history } from '../root-store'
import links from '../../helpers/links'
import alertActions from '../alert/alert.actions'

function list() {
    function request() {
        return { type: dependencyTypeConstants.LIST_PENDING }
    }

    function success(payload) {
        return { type: dependencyTypeConstants.LIST_SUCCESS, payload }
    }

    function failure(error) {
        return { type: dependencyTypeConstants.LIST_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request())
        return dependencyTypeService.list().then(
            (data) => {
                dispatch(success(data))
                return true
            },
            (error) => {
                dispatch(failure(error))
                return false
            }
        )
    }
}

function show(id) {
    function request(id) {
        return { type: dependencyTypeConstants.SHOW_PENDING, id }
    }

    function success(payload) {
        return { type: dependencyTypeConstants.SHOW_SUCCESS, payload }
    }

    function failure(error) {
        return { type: dependencyTypeConstants.SHOW_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request(id))
        return dependencyTypeService.show(id).then(
            (data) => {
                dispatch(success(data))
                return true
            },
            (error) => {
                dispatch(failure(error))
                return false
            }
        )
    }
}

function search(term, redirect = false) {
    function request(id) {
        return { type: dependencyTypeConstants.SEARCH_PENDING, id }
    }

    function success(payload) {
        return { type: dependencyTypeConstants.SEARCH_SUCCESS, payload }
    }

    function failure(error) {
        return { type: dependencyTypeConstants.SEARCH_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request(term))
        return dependencyTypeService.search(term).then(
            (payload) => {
                dispatch(success(payload))
                if (redirect) history.push(links.post.search(term))
                return true
            },
            (error) => {
                dispatch(alertActions.error(error))
                dispatch(failure(error))
                return false
            }
        )
    }
}

const dependencyTypeActions = {
    list,
    show,
    search,
}

export default dependencyTypeActions
