import { makeStyles } from '@material-ui/core/styles'
import { cycleColors } from 'helpers/theme'

const useStyles = makeStyles((theme) => ({
    root: {
        height: 8,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: 'white',
    },
    bar: {
        borderRadius: 5,
        background: ({ index }) => theme.palette[cycleColors(index)].main,
    },
}))

export default useStyles
