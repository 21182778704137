import React from 'react'

function getCoreProps(props) {
    return props['data-sourcepos']
        ? {
              'data-sourcepos': props['data-sourcepos'],
          }
        : {}
}

function TableCellRenderer(props) {
    let coreProps = getCoreProps(props)

    const style = props.align
        ? {
              textAlign: props.align,
          }
        : undefined

    coreProps = style
        ? {
              ...coreProps,
              style,
          }
        : coreProps

    let colspan = false

    const { children } = props

    if (children[0]) {
        const { value } = children[0].props
        if (value) {
            const stringStart = value.substring(0, 8)
            if (stringStart.includes('@cols=')) {
                colspan = parseInt(
                    stringStart.replace('@cols=', '').replace(':', '')
                )
                children[0] = children[0].props.value.replace(stringStart, '')
            }
        }
    }

    if (props.isHeader) {
        return (
            <th {...coreProps} colSpan={colspan || null}>
                {children}
            </th>
        )
    }

    return (
        <td {...coreProps} colSpan={colspan || null}>
            {children}
        </td>
    )
}

export default TableCellRenderer
