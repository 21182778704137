import PropTypes from 'prop-types'
import TroublePlanType from './TroublePlan.type'

const TroubleType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    definition: PropTypes.string.isRequired,
    critere: PropTypes.string.isRequired,
    observance: PropTypes.string.isRequired,
    plans: PropTypes.arrayOf(TroublePlanType).isRequired,
})

export default TroubleType
