import React from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@material-ui/core/LinearProgress'

import useStyles from './Progress.styles'

Progress.propTypes = {
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
}

export default function Progress(props) {
    const classes = useStyles(props)
    const { value } = props
    return (
        <LinearProgress
            variant="determinate"
            classes={{ ...classes }}
            value={value}
        />
    )
}
