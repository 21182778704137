import React from "react"
import useStyles from "./MediaLink.styles"
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Markdown from "components/Markdown/Markdown"
import FileIcon from '@material-ui/icons/InsertDriveFileOutlined';

function MediaLink({ link, text }) {
    const classes = useStyles()

    return (
        <a href={link} target="_blank" style={{ textDecoration: 'none' }}>
            <div className={classes.root}>
                <div className={classes.leftPart}>
                    <FileIcon className={classes.leftIcon} />
                    <Markdown className={classes.text}>{text}</Markdown>
                </div>
                <div className={classes.rightPart}><OpenInNewIcon className={classes.rightIcon} /></div>
            </div>
        </a>
    )
}

export default MediaLink