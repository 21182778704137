import medicamentCategoryConstants from 'stores/medicament-category/medicament-category.constants'

const initialState = []

// all state changes manipulations happen here
function medicamentCategoryStore(state = initialState, { type, payload }) {
    switch (type) {
        case medicamentCategoryConstants.LIST_SUCCESS:
            return payload.data

        default:
            return state
    }
}

export default medicamentCategoryStore
