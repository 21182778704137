import { makeStyles } from '@material-ui/core/styles'
import { alpha } from '@material-ui/core/styles/colorManipulator'
const useStyles = makeStyles((theme) => ({
    details: {
        display: 'flex',
        flexDirection: "column",
        padding: theme.spacing(3, 2, 2),
        backgroundColor: alpha(theme.palette.white.main, 0.6),
        border: ({ isRisk }) =>
            `2px solid ${
                isRisk ? theme.palette.red[500] : theme.palette.grey[200]
            }`,
    },
    buttonIcon:{
        color: theme.palette.yellow.main,
    },
    summaryRisk: {
        backgroundColor: theme.palette.red.main,
        color: 'white'
    }
}))

export default useStyles
