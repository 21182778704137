import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import useStyles from './CibleAccordion.styles'
import CheminCibleType from 'types/CheminCible.type'
import Markdown from 'components/Markdown/Markdown'
import Action from '../Action/Action'
import Modal from 'components/Modal/Modal'
import LargeButton from 'components/Button/LargeButton'

CibleAccordion.propTypes = {
    cibles: PropTypes.arrayOf(CheminCibleType).isRequired,
}

function CibleAccordion({ cibles, chemin, updateChemin }) {
    const classes = useStyles()
    const [expanded, setExpanded] = React.useState(false)
    const [interventions, setInterventions] = React.useState()

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const handleUpdateChemin = (action, value, cibleIndex) => {
        const newChemin = { ...chemin, cibles: [...chemin.cibles] }
        newChemin.cibles[cibleIndex].actions[action].value = value
        updateChemin(newChemin)
    }

    function CustomAccordion({ cible, index }) {
        const classes = useStyles()
        const semaines =
            isNaN(cible.duration) ||
            (!isNaN(cible.duration) && cible.duration > 1)
                ? 'semaines'
                : 'semaine'
        const title = (
            <>
                <strong>{cible.name}</strong> - {cible.duration} {semaines}
            </>
        )
        return (
            <Accordion
                expanded={expanded === index}
                onChange={handleChange(index)}
            >
                <AccordionSummary
                    className={classes.summary}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography variant="h3">{title}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <Grid container>
                        <Grid item xs>
                            <LargeButton
                                onClick={(e) =>
                                    setInterventions(cible.interventions)
                                }
                            >
                                Interventions
                            </LargeButton>
                            <div className={classes.actionContainer}>
                                {cible.actions.map((action, i) => {
                                    return (
                                        <Action
                                            key={i}
                                            actionIndex={i}
                                            action={action}
                                            onChange={handleUpdateChemin}
                                        />
                                    )
                                })}
                            </div>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        )
    }

    return (
        <div className={classes.root}>
            {cibles.map((cible, i) => (
                <CustomAccordion key={i} cible={cible} index={i} />
            ))}
            <Modal
                open={interventions ? true : false}
                handleClose={(e) => setInterventions(null)}
            >
                <Markdown source={interventions} />
            </Modal>
        </div>
    )
}

export default CibleAccordion
