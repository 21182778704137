import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popper from '@material-ui/core/Popper'

const useStyles = makeStyles((theme) => ({
    span: {
        fontWeight: '600',
        textDecoration: 'underline',
        cursor: 'pointer',
        position: 'relative',
        '&::after': {
            content: "''",
            display: 'inline-block',
            width: '5px',
            height: '5px',
            background: 'black',
            borderRadius: '100%',
            position: 'absolute',
            right: '1px',
            // margin: '0 0 1px 3px',
        },
    },
    popper: {
        width: 'calc(100vw - 40px)',
        maxWidth: '400px',
        boxSizing: 'border-box',
        background: '#ececec',
        border: 'solid 1px #cfcfcf',
        padding: '0.5em 0.8em',
        borderRadius: '0.4em',
        fontSize: '12px',
        lineHeight: '15px',
        boxShadow: '0px 2px 2px 0px rgba(0,0,0,0.2)',
    },
}))

export const DefinitionNode = ({ value, children }) => {
    // value represents the first children
    value = value.split('--')
    const title = value[0].trim()

    // value.shift() // remove the title part of the first line
    // children.shift() // remove the first line of the children
    // children = [ value, ...children ] // add the first line without the title backint

    children[0] = value[1]

    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popper' : undefined

    return (
        <>
            <span
                className={classes.span}
                aria-describedby={id}
                onClick={handleClick}
            >
                {title}
            </span>
            <Popper id={id} open={open} anchorEl={anchorEl} placement="top">
                <div className={classes.popper}>{children}</div>
            </Popper>
        </>
    )
}

export default DefinitionNode
