import { createSelector } from 'reselect'
import groupBy from 'lodash/groupBy'

export class DependencyTypesSelector {
    static selectDependencyTypes(dependencyTypes) {
        return dependencyTypes
    }
}

export const selectDependencyTypes = createSelector(
    (state) => state.dependencyTypes,
    DependencyTypesSelector.selectDependencyTypes
)
