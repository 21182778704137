import { makeStyles } from '@material-ui/core/styles'
import { theme } from 'helpers/theme'

const useStyles = makeStyles(() => ({
    root: {
        justifyContent: 'center',
    },
    header: {
        paddingTop: '1em',
        position: 'relative',
        backgroundColor: theme.palette.primary.light,
    },
}))

export default useStyles
