import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import useStyles from './CheminCard.styles'
import Progress from 'components/Progress/Progress'

CheminCard.propTypes = {
    name: PropTypes.string.isRequired,
    phase: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    index: PropTypes.number,
    onClick: PropTypes.func,
}

CheminCard.defaultProps = {
    index: 0,
    // eslint-disable-next-line no-console
    onClick: console.log,
}

export default function CheminCard(props) {
    const classes = useStyles(props)
    const { name, phase, progress, date, index, onClick } = props

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={onClick}>
                <CardContent>
                    <Typography
                        className={classes.title}
                        variant="h2"
                        gutterBottom
                    >
                        Phase {phase}
                    </Typography>
                    <Typography
                        className={classes.pos}
                        variant="h3"
                        gutterBottom
                    >
                        {name}
                    </Typography>
                    <Typography paragraph></Typography>
                    {progress > 0 && (
                        <Progress value={progress} index={index} />
                    )}
                    {progress === 0 && (
                        <Typography variant="h2">
                            Début prévu le {date}
                        </Typography>
                    )}
                </CardContent>
            </CardActionArea>
        </Card>
    )
}
