import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'hidden',
        width: '100%',
        maxWidth: '-moz-available',
        marginBottom: '16px',
        '@media only screen and (maxWidth: 600px)': {
            margin: theme.spacing(1),
        },
    },
    accordionRoot: {
        backgroundColor: 'transparent',
        padding: '0px',
        '&:last-child': {
            margin:'0px !important',
        },
    },
    buttonIcon:{
        color: theme.palette.yellow.main,
    },
    details: {
        '@media only screen and (maxWidth: 600px)': {

            paddingTop:theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        paddingTop:theme.spacing(2),
        paddingBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    summary:{
        backgroundColor: '#f0f0f0',
        border: '0px !important'
    },
    textContainer:{
        fontWeight: '400',
        fontSize: '0.95rem !important',
        maxWidth: '250px',
        textAlign: 'left'
    }
}))

export default useStyles
