import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { selectMedicaments } from 'stores/medicament/medicament.selectors'
import { selectMedicamentsCategories } from 'stores/medicament-category/medicament-category.selectors'
import { selectWays } from 'stores/way/way.selectors'
import { selectClinicalObservations } from 'stores/clinicalObservation/clinicalObservation.selectors'
import Listing from '../../components/Listing/Listing'
import ListTypesEnum from '../../constants/enums/ListTypeEnum'
import { _ } from '../../helpers/lang'
import ModelLayout from 'components/ModelLayout/ModelLayout'
import medicamentActions from '../../stores/medicament/medicament.actions'
import medicamentCategoryActions from 'stores/medicament-category/medicament-category.actions'
import wayActions from 'stores/way/way.actions'
import clinicalObservationActions from 'stores/clinicalObservation/clinicalObservation.actions'
import { getLocationInitialFilter } from 'utils/model'
import useSearchFilter from 'hooks/useSearchFilter'
import { useLocation } from "react-router-dom";
import {selectDefinitions} from "../../stores/definition/definition.selectors";
import definitionActions from "../../stores/definition/definition.actions";
import {convertFormToObjectValues} from "../../components/Filters/utils";

function mapStateToProps(state) {
    return {
        medications: selectMedicaments(state),
        medicationCategories: selectMedicamentsCategories(state),
        ways: selectWays(state),
        clinicalObservations: selectClinicalObservations(state),
        definitions: selectDefinitions(state)
    }
}

const mapDispatchToProps = {
    listMedicament: medicamentActions.list,
    listCategoryMedicament: medicamentCategoryActions.list,
    listWay: wayActions.list,
    listClinicalObservation: clinicalObservationActions.list,
    listDefinitions: definitionActions.list
}

MedicationList.propTypes = {

}

function MedicationList(props) {
    const { medications, listMedicament } = props
    const { medicationCategories, listCategoryMedicament } = props
    const { definitions, listDefinitions } = props
    const { ways, listWay } = props
    const { clinicalObservations, listClinicalObservation } = props
    const [isLoading, setIsLoading] = useState(true)
    const location = useLocation();
    const [filters, setFilter] = useState(location.state ? location.state.filters : null)
    const [filteredMedications, handleSearch] = useSearchFilter(Array.isArray(medications[0]) ? medications[0] : medications, (medication) => medication.name);

    const handleOnFilter = (result) => {
        setFilter(result.filters)
    }

    const filterBaseObject = {
        category: {},
        wayName: {},
        sideEffect: {},
        intoxicationObservation: {},
        withdrawalObservation: {}
    }

    useEffect(async () => {
        setIsLoading(true)
        await listMedicament(filters && JSON.stringify(filters))
        setIsLoading(false)
    }, [filters])

    useEffect(async () => {
        await Promise.all([
            listCategoryMedicament(),
            listWay(),
            listClinicalObservation(),
            listDefinitions()
        ])
    }, [])

    const fillFilterArray = (infos) => {
        let filterArray = []
        infos.forEach(info => {
            filterArray.push({
                value: info.name,
                label: info.name,
                name: info.name
            })
        })
        return filterArray
    }

    const consumptionWays = {
        label: _('medication.consommation_type'),
        key: 'wayName',
        filters: fillFilterArray(ways)
    }


    const categoryMedication = {
        label: _('medication.category'),
        key: 'category',
        filters: []
    }

    medicationCategories.forEach(category => {
        categoryMedication.filters.push({
            value: category.id.toString(),
            label: category.name,
            name: category.id.toString(),
        })
    })

    const sideEffectsFilters = {
        label: _('medication.secondary_effects'),
        key: 'sideEffect',
        filters: fillFilterArray(clinicalObservations)
    }

    const intoxicationObservationsFilters = {
        label: _('medication.intoxication_observations'),
        key: 'intoxicationObservation',
        filters: fillFilterArray(clinicalObservations)
    }

    const withdrawalObservationsFilters = {
        label: _('medication.withdrawal_observations'),
        key: 'withdrawalObservation',
        filters: fillFilterArray(clinicalObservations)
    }

    const filterSections = [
        categoryMedication,
        consumptionWays,
        sideEffectsFilters,
        intoxicationObservationsFilters,
        withdrawalObservationsFilters
    ]

    const handleRemoveAllAppliedFilters = (updatedObject) => {
        if(updatedObject) {
            setFilter(convertFormToObjectValues(updatedObject))
        } else {
            setFilter(null)
        }
    }

    return (
        <ModelLayout
            title={_('app.medications')}
            handleSearch={handleSearch}
            isLoading={isLoading}
        >{filteredMedications && <div>
            <Listing
                list={filteredMedications}
                listType={ListTypesEnum.Medication}
                title={_('app.medications')}
                filterSections={filterSections}
                onFilter={handleOnFilter}
                initialFilter={getLocationInitialFilter(location, filterBaseObject)}
                onRemoveAllAppliedFilters={handleRemoveAllAppliedFilters}
                definitions={definitions}
            />
        </div>}
        </ModelLayout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicationList)