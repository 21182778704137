import React from "react";
import { useHistory } from 'react-router-dom'
import Chip from '@material-ui/core/Chip'
import useStyles from './Spec.styles'
import { capitalizeFirstLetter } from "utils/functions";
import { Tooltip as MuiTooltip } from '@material-ui/core';


function SingleSpecChip({link, label, keyName, dataKey, filterValue, definitions}) {
    const classes = useStyles()
    const history = useHistory()
    const handleClick = (e) => {
        history.push(
            link,
            {
                filters:
                {
                    [dataKey]: filterValue
                }
            }

        )
    }

    const definition = _.find(definitions, (definition) => definition.name.toLowerCase().trim().includes(label.toLowerCase().trim().slice(0, -1)));
    return (
        <MuiTooltip title={definition !== undefined ? definition.text : ''} classes={{ tooltip: classes.tooltip }}>
            <Chip
                classes={{ root: classes.chip }}
                variant={'outlined'}
                key={keyName}
                size="small"
                label={capitalizeFirstLetter(label)}
                onClick={handleClick}
            />
        </MuiTooltip>
    )
}

export default SingleSpecChip