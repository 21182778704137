import { createSelector } from 'reselect'

export class MedicamentsCategoriesSelector {
    static selectMedicamentsCategories(medicamentCategories) {
        return medicamentCategories
    }

    static selectMedicamentCategoryBySlug(medicamentCategories, slug) {
        return medicamentCategories.find((c) => c.slug === slug)
    }
}

export const selectMedicamentsCategories = createSelector(
    (state) => state.medicamentCategories,
    MedicamentsCategoriesSelector.selectMedicamentsCategories
)

export const selectMedicamentCategoryBySlug = createSelector(
    (state) => state.medicamentCategories,
    (state, slug) => slug,
    MedicamentsCategoriesSelector.selectMedicamentCategoryBySlug
)
