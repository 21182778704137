import React, { useEffect, useState } from 'react'
import Layout from 'components/Layout/Layout'
import { selectPostBySlug } from '../../stores/post/post.selectors'
import { connect } from 'react-redux'
import { _ } from '../../helpers/lang'
import postActions from '../../stores/post/post.actions'
import PropTypes from 'prop-types'
import PostType from '../../types/Post.type'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import Header from 'components/Header/Header'
import NavButton from 'components/Button/NavButton/NavButton'
import PostDetail from 'app/PostShow/PostDetail/PostDetail'
import PostHeader from 'app/PostShow/PostHeader/PostHeader'
import {initArrayOfObjectForFilter} from "../../utils/model";
import ListTypesEnum from "../../constants/enums/ListTypeEnum";
import links from "../../helpers/links";
import ModelShow from "../../components/ModelShow/ModelShow";

function mapStateToProps(state, { match }) {
    const { slug } = match.params
    return {
        slug: slug,
        policyProcedures: selectPostBySlug(state, slug),
        searchTerm: match.params.searchTerm
    }
}

const mapDispatchToProps = {
    showPost: postActions.show,
}

PoliciesProceduresShow.propTypes = {
    slug: PropTypes.string,
    post: PostType,
    showPost: PropTypes.func.isRequired,
    searchTerm: PropTypes.string,
}

function PoliciesProceduresShow(props) {
    const { slug, policyProcedures, showPost, searchTerm } = props
    const [isLoading, setIsLoading] = useState(true)

    useEffect(async () => {
        await showPost(slug.split('-', 1)[0])
        setIsLoading(false)
    }, [setIsLoading])

    function formatPostTextObject() {
        if(policyProcedures.text !== null) {
            policyProcedures.text.forEach((postText) => {
                if (postText && postText.content !== undefined) {
                    if(searchTerm !== '' && searchTerm !== undefined) {
                        postText.content = postText.content.replace(new RegExp(searchTerm, 'gi'), '<mark>' + searchTerm + '</mark>');
                    }
                    if(postText.content.includes('<mark>')) {
                        postText.title = "<mark>" + postText.title + "</mark>";
                    }
                }
            })
            return policyProcedures.text;
        }
        return [{
            title: '',
            content: ''
        }];
    }

    return (
        <Layout title=''>
            {isLoading  ? (
                <LoadingSpinner style={{ marginTop: '5%' }} />
            ) : (
                <>
                    <Header>
                        <NavButton title={policyProcedures.fullname} />
                    </Header>
                    <PostHeader post={policyProcedures}/>
                    <PostDetail post={formatPostTextObject()} headerSize={450} />
                </>
            )}
        </Layout>
    )
}

export default connect(mapStateToProps,mapDispatchToProps)(PoliciesProceduresShow)
