import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import withStyles from '@material-ui/core/styles/withStyles'

import useStyles from './Action.styles'
import CheminOptionType from 'types/CheminOption.type'

const BlackCheckbox = withStyles({
    root: {
        color: 'rgba(0, 0, 0, 0.87)',
        '&$checked': {
            color: 'rgba(0, 0, 0, 0.87)',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />)

Action.propTypes = {
    action: CheminOptionType.isRequired,
    actionIndex: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
}

Action.defaultProps = {
    action: { value: false },
}

function Action({ action, actionIndex, onChange }) {
    const classes = useStyles()
    const [value, setValue] = useState(action.value)

    const handleClickCheckBox = (e) => {
        setValue(e.target.checked)
        onChange(actionIndex, e.target.checked)
    }

    return (
        <div className={classes.root}>
            <Typography variant="h3">{action.action}</Typography>
            <BlackCheckbox checked={value} onChange={handleClickCheckBox} />
        </div>
    )
}

export default Action
