const { REACT_APP_ENV, REACT_APP_SENTRY_DSN } = process.env

const environment = require(`./${REACT_APP_ENV}`).default

const env = {
    sentryDSN: REACT_APP_SENTRY_DSN,
    requestTimeout: 15000,
    ...environment,
}

export default env
