import PropTypes from 'prop-types'
import React from 'react'
import MuiCard from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import useStyles from './Card.styles'


function Card(props) {
    const classes = useStyles()
    const { children } = props

    return (
        <MuiCard classes={{ root: classes.Card }}>
            <CardContent classes={{ root: classes.CardContent }}>
                {children}
            </CardContent>
        </MuiCard>
    )
}

export default Card
