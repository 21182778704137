import {makeStyles} from '@material-ui/core/styles'
import { findLastIndex } from 'lodash'

export const useStyles = makeStyles(theme => ({
    root:{
        width:'inherit'
    },
    filter: {
        color: theme.palette.yellow.main,
        textTransform: "none"
    },
    filterSvg: {
        fill: theme.palette.yellow.main
    },
    filterCloseIcon:{
        right: '10px'
    },
    filterHeader:{
        display: 'flex',
        justifyContent: 'flex-start'
    },
    filterTitle:{
        left: '20px'
    },
    buttonFilter:{
        position: 'sticky',
        bottom: '0',
        backgroundColor: '#fff',
        right: '0',
        width: '100%',
        margin: '0',
        padding: '15px',
        display: 'flex',
        justifyContent: 'right',
        boxShadow: '0px -2px 20px rgb(0 0 0 / 10%)'
    },
    resetButton:{
        marginRight: '30px !important',
        fontWeight: '600',
        textTransform: 'none'
    },
    applyButton:{
        border: 'solid 1px rgb(241, 198, 81) !important',
        borderRadius: '0 !important',
        color: 'rgb(241, 198, 81) !important',
        fontWeight: '600',
        textTransform: 'none'
    },
    formStyle:{
        position: 'relative'
    },
    resetFilterButton:{
        textTransform: "none"
    },
    resetFilterSvg:{
        color: theme.palette.red.main
    },
    filterButtonContainer:{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    
}))