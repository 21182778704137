import React from "react"
import { Grid, TextField } from "../../../node_modules/@material-ui/core/index"
import NavButton from "components/Button/NavButton/NavButton"
import useStyles from './ListHeader.styles'
import { _ } from '../../helpers/lang'
import SearchListInput from "components/SearchInput/SearchListInput"

function ListHeader({title, onSearch, isOnSubmitSearch, initialInputValue, link}) {
    const classes = useStyles()
    const placeholder= `${_('app.Search_in')} ${title}`

    const handleSearch = ((e) => {
        onSearch(e)
    })

    return (
        <Grid className={classes.header} container>
            <Grid xs={12} md={12} lg={12} xl={12} item>
                <NavButton title={title} link={link} />
                <SearchListInput 
                    placeholder={placeholder} 
                    onSearch={handleSearch} 
                    isOnSubmitSearch={isOnSubmitSearch} 
                    initialValue={initialInputValue}
                />
            </Grid>
        </Grid>
    )
}

export default ListHeader