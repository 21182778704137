import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import useStyles from './ProfileListItem.styles'
import { _ } from 'helpers/lang'
import { displayDate } from 'utils/dates'
import ProfileType from 'types/Profile.type'
import { history } from 'stores/root-store'
import links from 'helpers/links'
import Avatar from 'components/Avatar/Avatar'

ProfileListItem.propTypes = {
    className: PropTypes.string,
    profile: ProfileType.isRequired,
}

export function ProfileListItem(props) {
    const classes = useStyles()
    const { profile } = props

    const className = clsx(classes.root, props.className)

    const handleClick = (e) => {
        history.push(links.profile.show(profile.number))
    }

    return (
        <div className={className} onClick={handleClick}>
            <div className={classes.media}>
                <Avatar gender={profile.gender} />
            </div>
            <div className={classes.content}>
                <Typography variant="h2">{profile.number}</Typography>
                <Typography variant="h6">
                    {_('profil.updated_at', {
                        value: displayDate(profile.updated_at),
                    })}
                </Typography>
            </div>
        </div>
    )
}

export default ProfileListItem
