import React, { useState} from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
    FormGroup,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography
} from "@material-ui/core";
import { Field } from 'formik';
import '../FilterAccordion/FilterAccordion.css';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import useStyles from "../Way/SingleWay.styles";

function FilterAccordion({ label, filters, keyValue, definitions}) {
    const [expanded, setExpanded] = useState(true);

    function getDefinition(label) {
        return _.find(definitions, (definition) => definition.name.toLowerCase().trim().includes(label.toLowerCase().trim().slice(0, -1)))
    }

    const classes = useStyles()
    return (
        <div>
            <Accordion
                expanded={expanded}
                onChange={() => setExpanded((expanded) => !expanded)}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ width: "100%" }}>
                        {label}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className="accordion">
                    <FormGroup>
                        {filters.map((filter, index) => {
                            return (
                                <MuiTooltip title={getDefinition(filter.value)?.text} classes={{ tooltip: classes.tooltip }}>

                                <FormControlLabel
                                        key={index}
                                        control={
                                            <Field
                                                type="checkbox"
                                                name={keyValue+'['+filter.value+']'}
                                            />

                                        }
                                        label={filter.label}
                                    />
                                </MuiTooltip>
                            )
                        })}
                    </FormGroup>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default FilterAccordion