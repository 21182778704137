import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3, 0, 0),
    },
    resultDetail: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        '@media only screen and (maxWidth: 600px)': {
            marginLeft: theme.spacing(1),
        },
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
        margin: '16px 0'
    },
    categoryImage: {
        width: '20px',
        marginRight: '0.5em'
    },
    nameText:{
        flexGrow: '2',
        overflow: 'hidden',
        textOverflow:'ellipsis',
        
    },
    parentNameText:{
        flexShrink: '3',
        color: 'darkgray',
        overflow: 'hidden',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap',
        marginLeft: '20px',
        
        fontSize: '0.9em'  
    },
    textContainer:{
        fontWeight: '400',
        fontSize: '0.95rem !important',
        width:'calc(100% - 30px)',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        whiteSpace:'nowrap',
    },
    singlePostSearchTitle: {
        display:'flex', 
        alignContent:'left', 
        paddingLeft:'2em',
        margin: '16px 0'
    }
}))

export default useStyles
