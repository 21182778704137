import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    bottombar: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: 'white',
        zIndex: 10,
        boxShadow: '0px 3px 8px 0px rgba(0,0,0,0.5)',
    },
    actionRoot: {
        zoom: 1,
        padding: 0,
        minWidth: '50px',
        borderLeft: '1px solid rgba(0,0,0,0.2)',
        '&:first-child': {
            borderLeft: 'none',
        },
    },
    actionWrapper: {
        position: 'absolute',
        top: '0.5rem',
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'flex-start',
    },
    actionLabel: {
        display: 'flex',
        alignItems: 'center',
        height: '2.4em',
        lineHeight: '1em',
        color: 'black',
        fontSize: '9px',
        fontWeight: '600',
        letterSpacing: '-0.03em',
    },
    active: {
        background: '#DBDBDB',
    },
}))

export default useStyles
