import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/Layout/Layout'
import NavButton from '../../components/Button/NavButton/NavButton'
import Header from '../../components/Header/Header'
import postActions from 'stores/post/post.actions'
import PostType from 'types/Post.type'
import PostDetail from 'app/PostShow/PostDetail/PostDetail'
import { _ } from '../../helpers/lang'
import { selectPosts } from 'stores/post/post.selectors'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import { connect } from 'react-redux'

const mapStateToProps = (state, { match }) => {
    return {
        slug: match.params.slug,
        clinicalProgram: selectPosts(state),
        searchTerm: match.params.searchTerm
    }
}

const mapDispatchToProps = {
    showClinicalProgram: postActions.show,
}

function ClinicalProgramShow(props){
    const { slug, clinicalProgram, showClinicalProgram, searchTerm } = props
    const [isLoading, setIsLoading] = useState(true)

    useEffect(async () => {
        await showClinicalProgram(slug.split('-', 1)[0])
        setIsLoading(false)
    }, [setIsLoading])

    function formatPostTextObject() {
        if(clinicalProgram.text !== null) {
            clinicalProgram.text.forEach((postText) => {
                if (postText && postText.content !== undefined) {
                    if(searchTerm !== '' && searchTerm !== undefined) {
                        postText.content = postText.content.replace(new RegExp(searchTerm, 'gi'), '<mark>' + searchTerm + '</mark>');
                    }
                    if(postText.content.includes('<mark>')) {
                        postText.title = "<mark>" + postText.title + "</mark>";
                    }
                }
            })
            return clinicalProgram.text;
        }
        return [{
            title: '',
            content: ''
        }];
    }

    return (
        <Layout title=''>
            {isLoading || clinicalProgram === undefined ? (
                <LoadingSpinner style={{ marginTop: '5%' }} />
            ) : (
                <div>
                    <Header>
                        <NavButton title={clinicalProgram.fullname} />
                        
                    </Header>
                    <PostDetail post={formatPostTextObject()} />
                </div>
            )}
        </Layout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicalProgramShow)