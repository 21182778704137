import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    inner: {
        background: 'white',
        padding: theme.spacing(0, 3, 3),
    },
    form: {
        maxWidth: '400px',
        margin: 'auto',
    },
    formControl: {
        margin: theme.spacing(0.75, 0, 0.75),
        width: '100%',
    },
    headerPicto: {
        margin: theme.spacing(0, 2, 0, 0),
    },
}))

export default useStyles
