import medicamentCategoryService from 'stores/medicament-category/medicament-category.service'
import medicamentCategoryConstants from 'stores/medicament-category/medicament-category.constants'

function list() {
    function request() {
        return { type: medicamentCategoryConstants.LIST_PENDING }
    }

    function success(payload) {
        return { type: medicamentCategoryConstants.LIST_SUCCESS, payload }
    }

    function failure(error) {
        return { type: medicamentCategoryConstants.LIST_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request())
        return medicamentCategoryService.list().then(
            (data) => {
                dispatch(success(data))
                return true
            },
            (error) => {
                dispatch(failure(error))
                return false
            }
        )
    }
}

const medicamentCategoryActions = {
    list,
}

export default medicamentCategoryActions
