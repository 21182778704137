const medicamentCategoryConstants = {
    LIST_PENDING: 'MEDICAMENTCATEGORY/LIST_PENDING',
    LIST_SUCCESS: 'MEDICAMENTCATEGORY/LIST_SUCCESS',
    LIST_FAILURE: 'MEDICAMENTCATEGORY/LIST_FAILURE',

    SHOW_PENDING: 'MEDICAMENTCATEGORY/SHOW_PENDING',
    SHOW_SUCCESS: 'MEDICAMENTCATEGORY/SHOW_SUCCESS',
    SHOW_FAILURE: 'MEDICAMENTCATEGORY/SHOW_FAILURE',

    CREATE_PENDING: 'MEDICAMENTCATEGORY/CREATE_PENDING',
    CREATE_SUCCESS: 'MEDICAMENTCATEGORY/CREATE_SUCCESS',
    CREATE_FAILURE: 'MEDICAMENTCATEGORY/CREATE_FAILURE',

    UPDATE_PENDING: 'MEDICAMENTCATEGORY/UPDATE_PENDING',
    UPDATE_SUCCESS: 'MEDICAMENTCATEGORY/UPDATE_SUCCESS',
    UPDATE_FAILURE: 'MEDICAMENTCATEGORY/UPDATE_FAILURE',

    DELETE_PENDING: 'MEDICAMENTCATEGORY/DELETE_PENDING',
    DELETE_SUCCESS: 'MEDICAMENTCATEGORY/DELETE_SUCCESS',
    DELETE_FAILURE: 'MEDICAMENTCATEGORY/DELETE_FAILURE',
}

export default medicamentCategoryConstants
