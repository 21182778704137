import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Layout from 'components/Layout/Layout'
import NavButton from '../../components/Button/NavButton/NavButton'
import Header from '../../components/Header/Header'
import postActions from 'stores/post/post.actions'
import PostType from 'types/Post.type'
import PostDetail from 'app/PostShow/PostDetail/PostDetail'
import { selectPosts } from 'stores/post/post.selectors'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import useStyles from "../../components/Card/Card.styles";

const mapStateToProps = (state, { match }) => {
    return {
        slug: match.params.slug,
        guidePratique: selectPosts(state),
        searchTerm: match.params.searchTerm
    }
}

const mapDispatchToProps = {
    showGuidePratique: postActions.show,
}

GuidePratiqueShow.propTypes = {
    slug: PropTypes.string,
    searchTerm: PropTypes.string,
    guidePratique: PostType,
    showGuidePratique: PropTypes.func.isRequired,
}

function GuidePratiqueShow(props) {
    const { slug, guidePratique, showGuidePratique, searchTerm } = props
    const [isLoading, setIsLoading] = useState(true)

    useEffect(async () => {
        await showGuidePratique(slug.split('-', 1)[0])
        setIsLoading(false)
    }, [setIsLoading])



    function formatPostTextObject() {
        if(guidePratique.text !== null) {
            guidePratique.text.forEach((postText) => {
                if (postText && postText.content !== undefined) {
                    if(searchTerm !== '' && searchTerm !== undefined) {
                        postText.content = postText.content.replace(new RegExp(searchTerm, 'gi'), '<mark>' + searchTerm + '</mark>');
                    }
                    if(postText.content.includes('<mark>')) {
                        postText.title = "<mark>" + postText.title + "</mark>";
                    }
                }
            })
            return guidePratique.text;
        }
        return [{
            title: '',
            content: ''
        }];
    }
    return (
        <Layout title=''>
            {isLoading ||  guidePratique === undefined ? (
                <LoadingSpinner style={{ marginTop: '5%' }} />
            ) : (
                <div>
                    <Header>
                        <NavButton title={ guidePratique.fullname} />
                    </Header>
                    <PostDetail post={formatPostTextObject()} />
                </div>
            )}

            <style>{`
          .c65{
                background-color: #1d1d1b;
                }

                .c84 {
                border-right-style: solid;
                padding: 0pt 5.8pt 0pt 5.8pt;
                border-bottom-color: #1d1d1b;
                border-top-width: 1pt;
                border-right-width: 1pt;
                border-left-color: #1d1d1b;
                vertical-align: middle;
                border-right-color: #1d1d1b;
                border-left-width: 1pt;
                border-top-style: solid;
                border-left-style: solid;
                border-bottom-width: 1pt;
                width: 52.3pt;
                border-top-color: #1d1d1b;
                border-bottom-style: solid;
            }
            
            .c104 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #1d1d1b;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #1d1d1b;
    vertical-align: top;
    border-right-color: #1d1d1b;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 5.5pt;
    border-top-color: #1d1d1b;
    border-bottom-style: solid;
}

c6 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    text-align: left;
    height: 11pt;
}

.c53 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #1d1d1b;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #1d1d1b;
    vertical-align: middle;
    border-right-color: #1d1d1b;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #f1c651;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 44.6pt;
    border-top-color: #1d1d1b;
    border-bottom-style: solid;
}
.c19 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: center;
}
.c125 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #1d1d1b;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #1d1d1b;
    vertical-align: middle;
    border-right-color: #1d1d1b;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #f1c651;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 545.5pt;
    border-top-color: #1d1d1b;
    border-bottom-style: solid;
}

c1 {
    font-size: 20pt;
    font-family: "Montserrat";
    font-weight: 700;
}

.c87 {
    height: 354.4pt;
}

.c104 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #1d1d1b;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #1d1d1b;
    vertical-align: top;
    border-right-color: #1d1d1b;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 5.5pt;
    border-top-color: #1d1d1b;
    border-bottom-style: solid;
}

.c179 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #1d1d1b;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #1d1d1b;
    vertical-align: middle;
    border-right-color: #1d1d1b;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 156.4pt;
    border-top-color: #1d1d1b;
    border-bottom-style: solid;
}
.c147 {
    background-color: #f1c651;
}
          `}</style>

        </Layout>


    )
}

export default connect(mapStateToProps, mapDispatchToProps)(GuidePratiqueShow)
