import Layout from 'components/Layout/Layout'
import ListHeader from 'components/ListHeader/ListHeader'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Inner from 'components/Inner/Inner'
import { selectTroubles } from 'stores/trouble/trouble.selectors'
import links from 'helpers/links'
import ListButton from 'components/Button/ListButton'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import { useHistory } from 'react-router-dom'
import troubleActions from '../../stores/trouble/trouble.actions'
import { _ } from '../../helpers/lang'

const mapStateToProps = (state) => {
    return {
        troubles: selectTroubles(state),
    }
}

const mapDispatchToProps = {
    listTrouble: troubleActions.list,
}

function PlanList(props) {
    const { troubles, listTrouble } = props
    const [isLoading, setIsLoading] = useState(true)
    const history = useHistory()
    const handleSearch = (e) => {
        history.push(links.plan.search(e))
    }

    useEffect(async () => {
        await listTrouble()
        setIsLoading(false)
    }, [setIsLoading])

    return (
        <Layout title={_('app.plan_de_soins')}>
            <ListHeader 
                title={_('app.plan_de_soins')} 
                onSearch={handleSearch} 
                isOnSubmitSearch={true} 
                link={links.home}
            />
            <Inner>
                <LoadingContainer isLoading={isLoading}>
                    {Array.isArray(troubles) && troubles.map((trouble) => (
                        <ListButton
                            key={trouble.id}
                            to={links.plan.show(trouble.slug)}
                        >
                            {trouble.name}
                        </ListButton>
                    ))}
                </LoadingContainer>
            </Inner>
        </Layout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanList)
