import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme.palette.primary.light,
        padding: theme.spacing(1),
        margin: theme.spacing(1, 1, 2),
    },
}))

export default useStyles
