import { createSelector } from 'reselect'
import groupBy from 'lodash/groupBy'

export class DrugsSelector {
    static selectDrugs(drugs) {
        return drugs
    }

    static selectDrugsByCategory(drugs, slug) {
        if (slug) {
            return drugs.filter((d) => d.category.slug === slug)
        }

        return groupBy(drugs, (d) => d.category.id)
    }

    static selectDrugBySlug(drugs, slug) {
        return drugs.find((d) => d.slug === slug)
    }

    static selectDrugById(drugs, id) {
        return drugs.find((d) => d.id === id)
    }
}

export const selectDrugs = createSelector(
    (state) => state.drugs,
    DrugsSelector.selectDrugs
)

export const selectDrugById = createSelector(
    (state) => state.drugs,
    (state, id) => id,
    DrugsSelector.selectDrugById
)

export const selectDrugBySlug = createSelector(
    (state) => state.drugs,
    (state, slug) => slug,
    DrugsSelector.selectDrugBySlug
)
