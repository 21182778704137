import troubleConstants from 'stores/trouble/trouble.constants'

const initialState = []

// all state changes manipulations happen here
function troubleStore(state = initialState, { type, payload }) {
    switch (type) {
        case troubleConstants.LIST_SUCCESS:
        case troubleConstants.SEARCH_SUCCESS:
            return payload.data
        case troubleConstants.SEARCH_FAILURE:
            return {}
        default:
            return state
    }
}

export default troubleStore
