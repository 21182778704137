export const getLocationInitialFilter = (location, filterBaseObject) => {
    if (location.state === undefined) {
        return filterBaseObject;
    }
    const [firstFilterKey] = Object.keys(location.state.filters);
    const firstFilterValue = location.state.filters[firstFilterKey];
    return {
        ...filterBaseObject,
        [firstFilterKey]: {
            [firstFilterValue]: true,
        },
    };
};

export const initArrayOfObjectForFilter = (arrayData, dataKey) =>
    arrayData &&
    arrayData.map((data) => ({
        ...data,
        dataKey: dataKey,
        filterValue: data.name,
    }));

export const initArrayOfStringForFilter = (arrayData, dataKey) =>
    arrayData &&
    arrayData.map((data) => ({
        ...data,
        name: data,
        dataKey: dataKey,
        filterValue: data
    }));