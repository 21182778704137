import { createBrowserHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import sentryMiddleware from 'sentry/sentryMiddleware'
import env from 'environments/base'
import { loadStore, saveStore } from 'helpers/localStorage'
import createRootReducer from './root-reducer'

export const history = createBrowserHistory()

function configureStore(initialState) {
    const middlewares = [
        routerMiddleware(history),
        thunkMiddleware,
        env.sentryDSN ? sentryMiddleware : null,
    ].filter((m) => m !== null)

    const composeEnhancers = composeWithDevTools({ trace: env.debugMode }) // enable action tracing in dev

    return createStore(
        createRootReducer(history), // root reducer with router state
        initialState,
        composeEnhancers(applyMiddleware(...middlewares))
    )
}

const store = configureStore(loadStore())

// save specific state to locale storage
store.subscribe(() => {
    saveStore({
        drugs: store.getState().drugs,
        drugCategories: store.getState().drugCategories,
        medicaments: store.getState().medicaments,
        medicamentCategories: store.getState().medicamentCategories,
        posts: store.getState().posts,
        profiles: store.getState().profiles,
        troubles: store.getState().troubles,
        widgets: store.getState().widgets,
        definitions: store.getState().definitions,
    })
})

export default store
