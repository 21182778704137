import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import useStyles from './Inner.styles'

Inner.propTypes = {
    children: PropTypes.any,
}

function Inner({ children }) {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Grid container justifyContent="center">
                <Grid xs={12} md={10} lg={8} xl={6} item>
                    {children}
                </Grid>
            </Grid>
        </div>
    )
}

export default Inner
