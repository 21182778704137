import { removeEmpty } from 'utils/functions'
import { required, exactLength, date, minArrayLength } from 'utils/validation'

const validate = ({
    number,
    gender,
    birthday,
    consommateur,
    drugs,
    medicaments,
    trouble_id,
    start,
    programme,
    stade,
}) => {
    const errors = {
        number: [required(number), exactLength(6)(number)].join(' '),
        gender: required(gender),
        birthday: [required(birthday), date(birthday)].join(' '),
        consommateur: required(consommateur),
        drugs: minArrayLength(1)(drugs),
        medicaments: minArrayLength(1)(medicaments),
        trouble_id: required(trouble_id),
        start: [required(start), date(start)].join(' '),
        programme: required(programme),
        stade: required(stade),
    }

    return removeEmpty(errors)
}

export default validate
