import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { _ } from 'helpers/lang'
import links from 'helpers/links'
import alertActions from 'stores/alert/alert.actions'
import useAuth from 'hooks/useAuth'

function mapStateToProps({ auth, user }) {
    // will send these props to the child component.
    return {
        auth,
        user,
    }
}

const mapDispatchToProps = {
    alert: alertActions.info,
}

const propTypes = {
    alert: PropTypes.func.isRequired,
    redirect: PropTypes.bool,
    redirectPath: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    inverse: PropTypes.bool,

    // Base route component
    path: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    exact: PropTypes.bool,
    strict: PropTypes.bool,
    sensitive: PropTypes.bool,
    component: PropTypes.any,
}

const defaultProps = {
    redirect: false,
    redirectPath: links.login,
    message: _('alert.authError'),
    inverse: false,
}

// Only render if user is authenticated
// Can redirect user
// can be inverted to only display if unauthenticated
function PrivateRoute(props) {
    const { isAuthenticated } = useAuth()
    const {
        alert,
        redirect,
        redirectPath,
        inverse,
        message,
        component: Component,
        render = (props) => <Component {...props} />,
        ...rest
    } = props

    let invalid = !isAuthenticated
    if (inverse) invalid = !invalid // switcharoo

    if (invalid) {
        if (redirect) {
            if (message) alert(message)
            return <Redirect to={redirectPath} />
        }
        return null
    }

    return <Route {...rest} render={render} />
}

PrivateRoute.propTypes = propTypes
PrivateRoute.defaultProps = defaultProps

const connectedPrivateRoute = connect(
    mapStateToProps,
    mapDispatchToProps
)(PrivateRoute)

export default connectedPrivateRoute 
