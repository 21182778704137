import React from 'react'
import useBreadcrumbs from 'use-react-router-breadcrumbs'

import { routeConfig } from 'app/Routes'
import Link from 'components/Link/Link'

// export const DynamicDrugBreadCrumb = ({ match }) => {
//     const drug = useSelector(state => selectDrugBySlug(state, match.params.slug))
//     return <span>{drug.name}</span>
// }

export default function Breadcrumbs() {
    const breadcrumbs = useBreadcrumbs(routeConfig, { excludePaths: ['/'] })
    const size = breadcrumbs.length - 1

    return (
        <>
            {breadcrumbs.map((item, i) => {
                const { breadcrumb, key } = item
                if (i >= size) return null
                return (
                    <Link key={key} href={key}>
                        {breadcrumb} {'>'}
                    </Link>
                )
            })}
        </>
    )
}
