import moment from 'moment'
import { displayDate } from 'utils/dates'

export const getCheminProgress = (semaines) => {
    let total = 0
    let completed = 0

    semaines.forEach((semaine) => {
        if (semaine.option === '0') {
            semaine.options.forEach((option) => {
                total++
                if (option.value === true) {
                    completed++
                }
            })
        }
    })

    if (total === 0) return false

    return (completed / total) * 100
}

const phaseDurations = [0, 1, 4, 6]

export const getStartDates = (profile) =>
    profile.chemins.map((chemin) => {
        let weeksToAdd = phaseDurations[parseInt(chemin.phase, 10)]
        weeksToAdd = weeksToAdd >= 0 ? weeksToAdd : 1
        return displayDate(moment(profile.start).add(weeksToAdd, 'weeks'))
    })

export const shouldDisplayWeek = (semaine) => {
    let shouldDisplay = false

    // if optional week was edited, display it
    if (semaine.option !== '0') {
        semaine.options.forEach((o) => {
            if (o.value === true) {
                shouldDisplay = true
            }
        })
    } else {
        shouldDisplay = true
    }

    return shouldDisplay
}
