import { createSelector } from 'reselect'

export class TroublesSelector {
    static selectTroubles(troubles) {
        return troubles
    }

    static selectTroubleById(troubles, id) {
        return troubles.find((t) => t.id === id)
    }

    static selectTroubleBySlug(troubles, slug) {
        return troubles.find((t) => t.slug === slug)
    }

    static selectRisksByTroubleId(troubles, id) {
        const trouble = troubles.find((t) => t.id === id)

        return trouble
            ? trouble.plans.filter((p) => p.is_risk === 1)
            : undefined
    }
}

export const selectTroubles = createSelector(
    (state) => state.troubles,
    TroublesSelector.selectTroubles
)

export const selectTroubleById = createSelector(
    (state) => state.troubles,
    (state, id) => id,
    TroublesSelector.selectTroubleById
)

export const selectTroubleBySlug = createSelector(
    (state) => state.troubles,
    (state, slug) => slug,
    TroublesSelector.selectTroubleBySlug
)

export const selectRisksByTroubleId = createSelector(
    (state) => state.troubles,
    (state, id) => id,
    TroublesSelector.selectRisksByTroubleId
)
