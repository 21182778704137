import PropTypes from 'prop-types'
import CategoryType from './Category.type'
import MediaType from './Media.type'

const MedicamentType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    generic_name: PropTypes.string,
    slug: PropTypes.string.isRequired,
    indications: PropTypes.string.isRequired,
    effects: PropTypes.string.isRequired,
    available_doses: PropTypes.string.isRequired,
    dosage: PropTypes.string.isRequired,
    teen_dose: PropTypes.string.isRequired,
    stop_strategy: PropTypes.string.isRequired,
    secondary_effects: PropTypes.arrayOf(PropTypes.string).isRequired,
    start_effect: PropTypes.string.isRequired,
    peak_effect: PropTypes.string.isRequired,
    duration_effect: PropTypes.string.isRequired,
    category: CategoryType.isRequired,
    image: MediaType,
})

export default MedicamentType
