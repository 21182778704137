import { useMemo, useState } from 'react'

function useSearchFilter(source, getName) {
    const [search, setSearch] = useState(null)
    const handleSearch = (e) => {
        if (e.trim() !== '') {
            setSearch(e.trim().toLowerCase())
        } else {
            setSearch(null)
        }
    }
    const filtered = useMemo(
        () =>
            search
                ? source.filter((item) =>
                      getName(item).toLowerCase().includes(search)
                  )
                : null,
        [search]
    )

    return [filtered || source, handleSearch]
}

export default useSearchFilter
