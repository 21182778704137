import React, {useEffect} from 'react'
import Typography from '@material-ui/core/Typography'
import useStyles from './PlanAccordions.styles'
import AccordionWithScroll from 'components/Accordions/AccordionWithScroll'
import TextLinkMarkdown from 'components/TextLinkMarkdown/TextLinkMarkdown'

PlanAccordions.defaultProps = {
    risk: false,
}

function PlanAccordions({ plans, risk, expanded, onExpand }) {
    const classes = useStyles({ risk })

    return (
        <div className={classes.root}>
            {plans.map((plan, index) => (
                <AccordionWithScroll
                    key={index}
                    index={index}
                    expanded={expanded === plan.name}
                    title={plan.name}
                    onChange={onExpand}
                    headerSize={120}
                    isRisk = {risk}
                >
                    <div>
                        <Typography variant="h4" paragraph>Données</Typography>
                        <TextLinkMarkdown text={plan.text_a} />
                        <Typography variant="h4" className={classes.h4} paragraph>Intervention psychosociale</Typography>
                        <TextLinkMarkdown text={plan.text_b} />
                        <Typography variant="h4" className={classes.h4} paragraph>Prescription médicale</Typography>
                        <TextLinkMarkdown text={plan.medical_prescription} />
                        <Typography variant="h4" className={classes.h4} paragraph>Outils d'évaluation</Typography>
                        <TextLinkMarkdown text={plan.text_c} />
                        <Typography variant="h4" className={classes.h4} paragraph>Outils d'intervention</Typography>
                        <TextLinkMarkdown text={plan.text_d} />
                        <Typography variant="h4" className={classes.h4} paragraph>Résultats attendus</Typography>
                        <TextLinkMarkdown text={plan.text_e} />
                        <Typography variant="h4" className={classes.h4} paragraph>
                            Argumentation théorique et fondement
                            scientifique
                        </Typography>
                        <TextLinkMarkdown text={plan.argumentation} />
                    </div>
                </AccordionWithScroll>
            ))}
        </div>
    )
}

export default PlanAccordions
