import medicamentConstants from 'stores/medicament/medicament.constants'

const initialState = []

// all state changes manipulations happen here
function medicamentStore(state = initialState, { type, payload }) {
    switch (type) {
        case medicamentConstants.LIST_SUCCESS:
        case medicamentConstants.SEARCH_SUCCESS:
        case medicamentConstants.SHOW_SUCCESS:
            return payload.data
        case medicamentConstants.SEARCH_FAILURE:
            return {}
        default:
            return state
    }
}

export default medicamentStore
