import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2, 0),
        borderBottom: '1px solid rgba(227,227,227,1)',
        cursor: 'pointer',
    },
    media: {
        marginRight: theme.spacing(3),
    },
    content: {},
}))

export default useStyles
