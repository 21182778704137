import React from 'react'
import { Link } from 'react-router-dom'

const AddictaSmall = (props) => (
    <Link to="/" style={{ display: 'flex' }}>
        <svg
            width="128"
            height="25"
            viewBox="0 0 128 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g>
                <path
                    d="M40.5869 0.842529V21.4325H37.2467V19.5206C36.69 20.2244 35.9589 20.7665 35.1249 21.0963C34.2384 21.4598 33.2888 21.6384 32.3309 21.6216C30.9906 21.6489 29.6671 21.3295 28.4865 20.6971C27.3626 20.0689 26.4382 19.1382 25.8185 18.0078C25.1526 16.785 24.8206 15.4089 24.8521 14.0159C24.8227 12.6313 25.1547 11.2615 25.8185 10.045C26.4445 8.93145 27.3689 8.0154 28.4865 7.3977C29.665 6.75899 30.9906 6.43334 32.3309 6.45225C33.2573 6.44384 34.1754 6.60772 35.0409 6.93548C35.8413 7.27374 36.5535 7.79059 37.1207 8.44821V0.842529H40.5869ZM34.9989 18.0919C35.669 17.7095 36.2215 17.1485 36.5955 16.4741C36.9841 15.7135 37.1795 14.8689 37.1627 14.0159C37.1795 13.1692 36.9841 12.3309 36.5955 11.5787C36.2215 10.9043 35.669 10.3433 34.9989 9.96095C33.5956 9.20668 31.9065 9.20668 30.5032 9.96095C29.8352 10.3391 29.289 10.9001 28.9276 11.5787C28.5222 12.3267 28.3205 13.1671 28.3394 14.0159C28.3205 14.8731 28.5222 15.7198 28.9276 16.4741C29.289 17.1527 29.8352 17.7137 30.5032 18.0919C31.9065 18.8461 33.5956 18.8461 34.9989 18.0919Z"
                    fill="#1D1D1B"
                />
                <path
                    d="M61.0484 0.842529V21.4325H57.7081V19.5206C57.1514 20.2244 56.4204 20.7665 55.5863 21.0963C54.6998 21.4598 53.7503 21.6384 52.7923 21.6216C51.452 21.651 50.1285 21.3316 48.9479 20.6971C47.824 20.0689 46.8997 19.1382 46.2799 18.0078C45.6077 16.7871 45.2674 15.411 45.2926 14.0159C45.2695 12.6292 45.6098 11.2594 46.2799 10.045C46.906 8.93145 47.8303 8.0154 48.9479 7.3977C50.1264 6.75689 51.4499 6.43123 52.7923 6.45224C53.7188 6.44384 54.6368 6.60772 55.5023 6.93548C56.3027 7.27374 57.0149 7.79059 57.5821 8.44821V0.842529H61.0484ZM55.4603 18.0919C56.1283 17.7137 56.6745 17.1527 57.0359 16.4741C57.4413 15.7198 57.643 14.8731 57.6241 14.0159C57.643 13.1671 57.4413 12.3267 57.0359 11.5787C56.6745 10.9001 56.1283 10.3391 55.4603 9.96094C54.057 9.20668 52.368 9.20668 50.9646 9.96094C50.2966 10.3391 49.7504 10.9001 49.3891 11.5787C48.9836 12.3267 48.7819 13.1671 48.8009 14.0159C48.7819 14.8731 48.9836 15.7198 49.3891 16.4741C49.7504 17.1527 50.2966 17.7137 50.9646 18.0919C52.368 18.8461 54.057 18.8461 55.4603 18.0919Z"
                    fill="#1D1D1B"
                />
                <path
                    d="M67.1616 3.57384C66.7351 3.19565 66.4978 2.65149 66.5104 2.08211C66.4978 1.51274 66.7351 0.968576 67.1616 0.590393C67.5881 0.191199 68.1553 -0.0189023 68.7372 0.00210787C69.3149 -0.0168013 69.88 0.186997 70.3128 0.569382C70.7287 0.926555 70.9661 1.44971 70.964 1.99807C70.9808 2.57585 70.7518 3.13262 70.3338 3.53182C69.9136 3.95622 69.3338 4.18313 68.7372 4.16212C68.1553 4.18313 67.5881 3.97093 67.1616 3.57384ZM66.9935 6.62031H70.4598V21.4325H66.9935V6.62031Z"
                    fill="#1D1D1B"
                />
                <path
                    d="M79.052 20.6551C77.8672 20.0332 76.8798 19.0941 76.195 17.9448C75.5059 16.7514 75.1488 15.3942 75.1656 14.0159C75.153 12.6439 75.508 11.2951 76.195 10.108C76.8798 8.96296 77.8693 8.03221 79.052 7.41871C80.3272 6.75269 81.7515 6.42073 83.1906 6.45224C84.5266 6.42073 85.848 6.72328 87.035 7.33467C88.1127 7.89985 88.9866 8.78857 89.5349 9.8769L86.8669 11.4317C86.472 10.7929 85.9237 10.2656 85.2703 9.89791C84.6212 9.56175 83.9006 9.38947 83.1695 9.39367C81.97 9.35165 80.8062 9.80757 79.9554 10.6543C79.0941 11.4947 78.6739 12.6082 78.6739 14.0159C78.6739 15.4236 79.0941 16.5581 79.9344 17.3985C80.7747 18.2389 81.8461 18.6592 83.1695 18.6592C84.6863 18.7033 86.1043 17.9133 86.8669 16.6002L89.5349 18.1549C88.9677 19.2474 88.0917 20.1467 87.014 20.7392C85.8333 21.3485 84.5182 21.651 83.1906 21.6216C81.7515 21.6447 80.3314 21.3127 79.052 20.6551Z"
                    fill="#1D1D1B"
                />
                <path
                    d="M103.022 20.6341C102.581 20.985 102.074 21.2413 101.53 21.3904C100.927 21.5543 100.306 21.6321 99.6817 21.6216C98.0641 21.6216 96.8036 21.2014 95.9213 20.361C95.039 19.5205 94.5768 18.2599 94.5768 16.6422V9.49869H92.1399V6.72535H94.5768V3.34271H98.0431V6.72535H102.014V9.49869H98.0431V16.5581C98.0431 17.2725 98.2321 17.8187 98.5682 18.1969C98.9044 18.5751 99.4296 18.7642 100.102 18.7642C100.803 18.7936 101.495 18.5793 102.056 18.1549L103.022 20.6341Z"
                    fill="#1D1D1B"
                />
                <path
                    d="M16.9532 16.9363H7.24763L5.31492 21.4325H1.59656L10.3568 1.99805H13.9071L22.6673 21.4325H18.9069L16.9532 16.9363ZM15.7558 14.0999L12.1004 5.6118L8.46608 14.0999H15.7558Z"
                    fill="#1D1D1B"
                />

                <path
                    d="M122.286 16.9363H112.58L110.648 21.4325H106.929L115.69 1.99805H119.24L128 21.4325H124.24L122.286 16.9363ZM121.089 14.0999L117.433 5.6118L113.799 14.0999H121.089Z"
                    fill="#1D1D1B"
                />
            </g>
        </svg>
    </Link>
)

export default AddictaSmall
