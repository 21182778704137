import profileConstants from 'stores/profile/profile.constants'

const storedData = JSON.parse(localStorage.getItem('profiles'))
const initialState = storedData ? [...storedData] : []

// all state changes manipulations happen here
function profileStore(state = initialState, { type, payload }) {
    switch (type) {
        case profileConstants.LIST_SUCCESS:
            return payload.data

        case profileConstants.SHOW_SUCCESS:
        case profileConstants.CREATE_SUCCESS:
        case profileConstants.UPDATE_SUCCESS:
        case profileConstants.UPDATECHEMIN_SUCCESS:
            return [
                ...state.filter((profile) => profile.id !== payload.data.id), // remove old entry if it exists
                payload.data,
            ]
        default:
            return state
    }
}

export default profileStore
