const authConstants = {
    REGISTER_PENDING: 'AUTH/REGISTER_PENDING',
    REGISTER_SUCCESS: 'AUTH/REGISTER_SUCCESS',
    REGISTER_FAILURE: 'AUTH/REGISTER_FAILURE',

    LOGIN_PENDING: 'AUTH/LOGIN_PENDING',
    LOGIN_SUCCESS: 'AUTH/LOGIN_SUCCESS',
    LOGIN_FAILURE: 'AUTH/LOGIN_FAILURE',

    LOGOUT_PENDING: 'AUTH/LOGOUT_PENDING',
    LOGOUT_SUCCESS: 'AUTH/LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'AUTH/LOGOUT_FAILURE',

    LOGOUT_DEVICES_PENDING: 'AUTH/LOGOUT_DEVICES_PENDING',
    LOGOUT_DEVICES_SUCCESS: 'AUTH/LOGOUT_DEVICES_SUCCESS',
    LOGOUT_DEVICES_FAILURE: 'AUTH/LOGOUT_DEVICES_FAILURE',

    SEND_PASSWORD_MAIL_PENDING: 'AUTH/SEND_PASSWORD_MAIL_PENDING',
    SEND_PASSWORD_MAIL_SUCCESS: 'AUTH/SEND_PASSWORD_MAIL_SUCCESS',
    SEND_PASSWORD_MAIL_FAILURE: 'AUTH/SEND_PASSWORD_MAIL_FAILURE',

    SEND_CONFIRMATION_MAIL_PENDING: 'AUTH/SEND_CONFIRMATION_MAIL_PENDING',
    SEND_CONFIRMATION_MAIL_SUCCESS: 'AUTH/SEND_CONFIRMATION_MAIL_SUCCESS',
    SEND_CONFIRMATION_MAIL_FAILURE: 'AUTH/SEND_CONFIRMATION_MAIL_FAILURE',

    RESET_PASSWORD_PENDING: 'AUTH/RESET_PASSWORD_PENDING',
    RESET_PASSWORD_SUCCESS: 'AUTH/RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'AUTH/RESET_PASSWORD_FAILURE',
}

export default authConstants
