import React from 'react'
import useStyles from './SinglePostSearch.styles'
import Typography from '@material-ui/core/Typography'
import { _ } from 'helpers/lang'
import ListButton from 'components/Button/ListButton'

function SinglePostSearch({ singlePost, icon, link, title, isSinglePostSearch, searchTerm }) {
    const classes = useStyles()
    const removeLastCharacter = (string) => {
        return string.slice(0, -2)
    }
    return (
        <>
            {(Array.isArray(singlePost)) && Object.keys(singlePost).length !== 0 ? (
                <>
                    {isSinglePostSearch ? (
                        <div className={classes.singlePostSearchTitle}>
                            <Typography variant="h2" align="center">
                                {`(${singlePost.length}) ${_('app.Results')}:`}
                            </Typography>
                        </div>
                    ) : (
                        <div className={classes.resultDetail}>
                            <img
                                src={icon}
                                className={classes.categoryImage}
                            />
                            <Typography variant="h5" align="center">
                                {singlePost && `${title} (${singlePost.length})`}
                            </Typography>
                        </div>
                    )}

                    {singlePost.map((post) => (
                        <ListButton
                            key={post.id}
                            to={(post.is_leaf || post.is_leaf === undefined) ? link.show(post.slug, searchTerm) : link.list}
                        >
                            <div className={classes.textContainer}>
                                <div className={classes.nameText}>{post.name}</div>
                                <div className={classes.parentNameText}>{(post.parentName) && removeLastCharacter(post.parentName)}</div>
                            </div>
                        </ListButton>
                    ))}

                </>
            ) : null}
        </>
    )
}

export default SinglePostSearch
