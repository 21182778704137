import {Box, Button, Drawer} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {convertFormToObjectValues} from './utils'
import {Form, Formik} from 'formik'
import React, {useEffect, useRef, useState} from 'react'
import {_} from '../../helpers/lang'
import './Filter.css'
import {ReactComponent as FilterAlt} from '../../assets/svg/filterAlt.svg'
import {ReactComponent as FilterAltOff} from '../../assets/svg/filterAltOff.svg'
import FilterAccordion from '../FilterAccordion/FilterAccordion'
import '../FilterAccordion/FilterAccordion.css'
import {useStyles} from './Filters.styles'
import Typography from "@material-ui/core/Typography";

Filters.propTypes = {
    //filterTypes: PropTypes.any
}

export default function Filters({filterSections, onFilter, initialFilter, onRemoveAllAppliedFilters, definitions}) {
    const ref = useRef(null);
    const [formValue, setFormValue] = useState(initialFilter)
    const [initialFormValue, setInitialFormValue] = useState(initialFilter)
    const [appliedFilters, setAppliedFilters] = useState([])
    const [state, setState] = React.useState({
        right: false
    })
    const classes = useStyles()

    console.log("INTIALFILTER",initialFilter);
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return
        }
        setState({...state, [anchor]: open})
    }

    const getEmptyFilters = (prevObject) => {
        const object = {...prevObject}
        Object.keys(object).map((key) => {
            object[key] = {}
        })
        return object
    }

    const resetFilters = (filterName = "") => {
        if (filterName) {
            const updatedObject = Object.keys(ref.current.values).reduce((acc, sectionName) => {
                const section = ref.current.values[sectionName];
                const keyExists = Object.hasOwn(section, filterName);

                if (keyExists) {
                    acc[sectionName] = {
                        ...section,
                        [filterName]: false
                    };
                } else {
                    acc[sectionName] = section;
                }

                return acc;
            }, {});
            ref.current.setValues(updatedObject);
            setFormValue(updatedObject);
            handleAppliedFilters(updatedObject);
            onRemoveAllAppliedFilters(updatedObject);
        } else {
            setFormValue(getEmptyFilters(initialFilter))
            ref.current.setValues(getEmptyFilters(initialFilter))
            setInitialFormValue(getEmptyFilters(initialFilter))
            onRemoveAllAppliedFilters()
        }
    }

    const getCurrentNbApplyFilter = () => {
        let nbFilterApply = 0
        if (ref.current && ref.current.values) {
            Object.keys(ref.current.values).forEach((key) => {
                Object.keys(ref.current.values[key]).forEach((key2) => {
                    ref.current.values[key][key2] && nbFilterApply++
                })
            })
        }
        return nbFilterApply
    }

    const getInitialFilterCount = () => {
        let nbFilterApply = 0
        if (initialFormValue) {
            Object.keys(initialFormValue).forEach((key) => {
                Object.keys(initialFormValue[key]).forEach((key2) => {
                    initialFormValue[key][key2] && nbFilterApply++
                })
            })
        }
        return nbFilterApply
    }


    const handleAppliedFilters = (refValues) => {
        let appliedFiltersArray = []
        if (refValues) {
            Object.keys(refValues).forEach((key) => {
                Object.keys(refValues[key]).forEach((key2) => {
                    if(refValues[key][key2]) {
                        let appliedFilterObject = {
                            label: key2,
                            value: key2,
                            parent: key
                        }
                        if(/^\d+$/.test(key2)) {
                            for (let i = 0; i <filterSections[0].filters.length; i++) {
                                if (filterSections[0].filters[i].value === key2) {
                                    appliedFilterObject.label = filterSections[0].filters[i].label
                                }
                            }
                        }
                        refValues[key][key2] && appliedFiltersArray.push(appliedFilterObject)
                    }

                })
            })
        }
        setAppliedFilters(appliedFiltersArray);
    }

    const handleSubmit = (values) => {
        setState({right: false})
        onFilter(
            {
                filters: convertFormToObjectValues(values)
            }
        )
    }

    useEffect(() => {
        handleAppliedFilters(ref?.current?.values);
    }, [ref?.current?.values]);

    const anchor = 'right'
    return (
        <div className={classes.root}>
            <React.Fragment key={'right'}>
                <div className={classes.filterButtonContainer}>
                    <Button
                        onClick={toggleDrawer('right', true)}
                        className={classes.filter}
                        endIcon={<FilterAlt className={classes.filterSvg}/>}
                    >
                        {_('app.filters')}
                    </Button>
                    {getCurrentNbApplyFilter() > 0 &&
                        <>
                            <div class="filters-container">
                                <Button
                                    onClick={() => resetFilters()}
                                    className={classes.resetFilterButton}
                                    endIcon={<FilterAltOff className={classes.resetFilterSvg} />}
                                >
                                    {ref ? getCurrentNbApplyFilter() : 0} {_('app.Apply_filter')}
                                </Button>

                            </div>
                        </>
                    }

                </div>

                {getInitialFilterCount() === 1 && (

                    <Box sx={{display:'flex', padding:'6px 8px'}}>

                        {appliedFilters?.map((appliedFilter) => {
                            return (
                                <>
                                    <Typography variant="body1">{`${_(`app.${appliedFilter.parent}`)}: `}</Typography>
                                    <Typography variant="body1" style={{marginLeft: '10px', fontWeight: '500'}}>{appliedFilter.label}</Typography>
                                </>

                            )
                        })}
                    </Box>

                )
                }
                <Formik
                    onSubmit={(e) => handleSubmit(e)}
                    initialValues={formValue}
                    innerRef={ref}
                >

                    {(props) => {
                        return (
                            <Drawer
                                anchor={'right'}
                                open={state['right']}
                                onClose={toggleDrawer('right', false)}
                                style={{
                                    width: '100%',
                                    maxWidth: '400px'
                                }}
                            >
                                <div className={classes.filterHeader}>
                                    <h5 className="filter_title">{_('app.filters')}</h5>
                                    <Button onClick={toggleDrawer(anchor, false)} className="button_close"><CloseIcon/></Button>
                                </div>

                                <Form>
                                    <div className={classes.formStyle}>
                                        {filterSections.map((section, key) => {
                                            return (
                                                <div key={key} role="group" aria-labelledby="checkbox-group">
                                                    <FilterAccordion
                                                        label={section.label}
                                                        filters={section.filters}
                                                        keyValue={section.key}
                                                        definitions={definitions}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className={classes.buttonFilter}>
                                        <Button className={classes.resetButton} onClick={() => {
                                            props.setValues(getEmptyFilters(initialFilter))
                                        }}>{_('app.reset')}</Button>
                                        <Button type="submit" className={classes.applyButton}>{_('app.apply')}</Button>
                                    </div>
                                </Form>
                            </Drawer>
                        )
                    }}
                </Formik>
            </React.Fragment>
        </div>
    )
}
