import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: '90vw',
        maxHeight: '90vh',
        width: 300,
        height: 300,
    },
    content: {
        textAlign: 'center',
    },
}))

export default useStyles
