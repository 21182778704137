import wayService from 'stores/way/way.service'
import wayConstants from 'stores/way/way.constants'
import { history } from '../root-store'
import links from '../../helpers/links'
import alertActions from '../alert/alert.actions'

function list() {
    function request() {
        return { type: wayConstants.LIST_PENDING}
    }

    function success(payload) {
        return { type: wayConstants.LIST_SUCCESS, payload }
    }

    function failure(error) {
        return { type: wayConstants.LIST_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request())
        return wayService.list().then(
            (data) => {
                dispatch(success(data))
                return true
            },
            (error) => {
                dispatch(failure(error))
                return false
            }
        )
    }
}

function show(id) {
    function request(id) {
        return { type: wayConstants.SHOW_PENDING, id }
    }

    function success(payload) {
        return { type: wayConstants.SHOW_SUCCESS, payload }
    }

    function failure(error) {
        return { type: wayConstants.SHOW_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request(id))
        return wayService.show(id).then(
            (data) => {
                dispatch(success(data))
                return true
            },
            (error) => {
                dispatch(failure(error))
                return false
            }
        )
    }
}

function search(term, redirect = false) {
    function request(id) {
        return { type: wayConstants.SEARCH_PENDING, id }
    }

    function success(payload) {
        return { type: wayConstants.SEARCH_SUCCESS, payload }
    }

    function failure(error) {
        return { type: wayConstants.SEARCH_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request(term))
        return wayService.search(term).then(
            (payload) => {
                dispatch(success(payload))
                if (redirect) history.push(links.post.search(term))
                return true
            },
            (error) => {
                dispatch(alertActions.error(error))
                dispatch(failure(error))
                return false
            }
        )
    }
}

const wayActions = {
    list,
    show,
    search,
}

export default wayActions
