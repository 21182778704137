import authConstants from 'stores/auth/auth.constants'
import { formatAuthData } from 'helpers/auth'

// initialize state with locally stored user if logged in
const storedAuth = JSON.parse(localStorage.getItem('auth'))
const initialState = storedAuth ? { ...storedAuth } : null

function authStore(state = initialState, { type, payload }) {
    switch (type) {
        case authConstants.LOGIN_SUCCESS:
            return formatAuthData(payload)

        case authConstants.LOGIN_PENDING:
        case authConstants.LOGIN_FAILURE:
        case authConstants.LOGOUT_SUCCESS:
        case authConstants.LOGOUT_DEVICES_SUCCESS:
            return formatAuthData(false)
        default:
            return state
    }
}

export default authStore
