import PropTypes from 'prop-types'
import CheminOptionType from './CheminOption.type'

const CheminCibleType = PropTypes.shape({
    name: PropTypes.string,
    duration: PropTypes.string,
    interventions: PropTypes.string,
    actions: PropTypes.arrayOf(CheminOptionType),
})

export default CheminCibleType
