import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import profileActions from 'stores/profile/profile.actions'

import CheminType from 'types/Chemin.type'
import useStyles from './PhaseContent.styles'
import CibleAccordion from './CibleAccordion/CibleAccordion'

const mapDispatchToProps = {
    updateChemin: profileActions.updateChemin,
}

PhaseContent.propTypes = {
    updateChemin: PropTypes.func.isRequired,
    chemin: CheminType.isRequired,
}

function PhaseContent({ chemin, updateChemin }) {
    const { cibles } = chemin
    const classes = useStyles()

    return (
        <div className={classes.inner}>
            <CibleAccordion
                chemin={chemin}
                cibles={cibles}
                updateChemin={updateChemin}
            />
        </div>
    )
}

export default connect(null, mapDispatchToProps)(PhaseContent)
