import React, { useState, useEffect } from "react";
import PostTypesEnum from 'constants/enums/PostTypesEnum'
import Layout from "components/Layout/Layout";
import ListHeader from "components/ListHeader/ListHeader";
import links from "helpers/links";
import PostSearchContent from "app/PostSearch/PostSearchContent/PostSearchContent";
import { connect } from 'react-redux'
import LoadingContainer from "components/LoadingContainer/LoadingContainer";
import postActions from '../../stores/post/post.actions'
import { useHistory } from "react-router-dom";
import { selectPosts } from '../../stores/post/post.selectors'
import PostType from '../../types/Post.type'
import { _ } from '../../helpers/lang'

const mapStateToProps = (state, { match }) => {
    const slug = match.params.slug
    return {
        slug: slug,
        posts: selectPosts(state),
    }
}

const mapDispatchToProps = {
    searchPost: postActions.search,
}

function PoliciesProceduresSearch({slug, posts, searchPost,}){
    const [isLoading, setIsLoading] = useState(true)
    const policyList = (Array.isArray(posts)) ? (posts.filter((p) => p.menu === PostTypesEnum.POLICY)) : []
    const history = useHistory()
    const handleSearch = (e) => {
        history.push(links.politiquesProcedures.search(e))
    }
    
    useEffect(async () => {
        await searchPost(slug)
        setIsLoading(false)
    }, [slug])

    return (
        <Layout>
            <ListHeader 
                title={_('app.policies_procedures')} 
                onSearch={handleSearch}
                isOnSubmitSearch={true}
                initialInputValue={slug}
            />
            <LoadingContainer isLoading={isLoading}>
                <PostSearchContent
                    policies={policyList}
                    isSinglePostSearch={true}
                />
            </LoadingContainer>
        </Layout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PoliciesProceduresSearch)