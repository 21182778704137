import app from './app'
import auth from './auth'
import date from './date'
import field from './field'
import alert from './alert'
import common from './common'
import validation from './validation'
import medication from './medication'
import drug from './drug'
import profil from './profil'
import modelShow from './modelShow'
import plan from './plan'

const fr = {
    translation: {
        app,
        alert,
        auth,
        common,
        date,
        field,
        validation,
        medication,
        drug,
        profil,
        modelShow,
        plan
    },
}

export default fr
