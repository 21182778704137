import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/Button/Button'
import useStyles from './TopbarMenu.styles'
import { _ } from 'helpers/lang'
import links from 'helpers/links'
import useOnClickOutside from 'hooks/useOnClickOutside'
import RefType from 'types/Ref.type'

TopbarMenu.propTypes = {
    opened: PropTypes.bool.isRequired,
    closeMenu: PropTypes.func.isRequired,
    boundaryRef: RefType,
}

TopbarMenu.defaultProps = {
    opened: true,
}

function TopbarMenu(props) {
    const classes = useStyles()
    const { opened, closeMenu, boundaryRef } = props

    useOnClickOutside(boundaryRef, closeMenu)

    if (!opened) return null

    return (
        <div className={classes.menu}>
            <Button className={classes.menubutton} to={links.account}>
                {_('app.my_account')}
            </Button>
            <Button className={classes.menubutton} to={links.logout}>
                {_('app.logout')}
            </Button>
        </div>
    )
}

export default TopbarMenu
