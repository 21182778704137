import { createSelector } from 'reselect'

export class WaysSelector {
    static selectWays(ways) {
        return ways
    }
}

export const selectWays = createSelector(
    (state) => state.ways,
    WaysSelector.selectWays
)