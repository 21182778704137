import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { selectTroubles } from "stores/trouble/trouble.selectors"
import troubleActions from "stores/trouble/trouble.actions"
import { useHistory } from "react-router-dom"
import links from "helpers/links"
import Layout from "components/Layout/Layout";
import LoadingContainer from "components/LoadingContainer/LoadingContainer";
import ListHeader from "components/ListHeader/ListHeader";
import PostSearchContent from "app/PostSearch/PostSearchContent/PostSearchContent";
import { _ } from '../../helpers/lang'

const mapStateToProps = (state, { match }) => {
    const slug = match.params.slug
    return {
        slug: slug,
        plans: selectTroubles(state),
    }
}

const mapDispatchToProps = {
    searchPlan: troubleActions.search,
}

function PlanSearch({slug, plans, searchPlan}){
    const [isLoading, setIsLoading] = useState(true)
    const history = useHistory()

    useEffect(async() => {
        await searchPlan(slug)
        setIsLoading(false)
    }, [slug])

    const handleSearch = (e) => {
        history.push(links.plan.search(e))
    }

    return (
        <Layout>
            <ListHeader 
                title={_('app.plan_de_soins')} 
                onSearch={handleSearch}
                isOnSubmitSearch={true}
                initialInputValue={slug}
            />
            <LoadingContainer isLoading={isLoading}>
                <PostSearchContent
                    troubles={plans}
                    isSinglePostSearch={true}
                />
            </LoadingContainer>
        </Layout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanSearch)