import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    spinner: {
        color: (props) =>
            props.color ? theme.palette[props.color].main : 'white',
    },

    boxCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

export default useStyles
