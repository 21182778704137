import { makeStyles } from '@material-ui/core/styles'
import { cycleColors } from 'helpers/theme'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 400,
        height: 120,
        margin: theme.spacing(0, 0.75, 1.5),
        borderRadius: 8,
        boxShadow: 'none',
        background: ({ index }) => theme.palette[cycleColors(index)].light,
    },
}))

export default useStyles
