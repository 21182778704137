import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'

import useStyles from './Modal.styles'
import Button from 'components/Button/Button'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const Modal = ({ open, handleClose, children }) => {
    const classes = useStyles()

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            classes={{ paper: classes.root }}
        >
            <DialogTitle disableTypography>
                <Typography variant="h2">Interventions</Typography>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default Modal
