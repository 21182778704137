import profileService from 'stores/profile/profile.service'
import profileConstants from 'stores/profile/profile.constants'
import alertActions from 'stores/alert/alert.actions'
import { _ } from 'helpers/lang'
import { history } from 'stores/root-store'
import links from 'helpers/links'

function list(filters = undefined) {
    function request(filters) {
        return { type: profileConstants.LIST_PENDING, filters }
    }

    function success(payload) {
        return { type: profileConstants.LIST_SUCCESS, payload }
    }

    function failure(error) {
        return { type: profileConstants.LIST_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request(filters))
        return profileService.list(filters).then(
            (data) => {
                dispatch(success(data))
                return true
            },
            (error) => {
                dispatch(failure(error))
                return false
            }
        )
    }
}

function show(id, redirect = false) {
    function request(id) {
        return { type: profileConstants.SHOW_PENDING, id }
    }

    function success(payload) {
        return { type: profileConstants.SHOW_SUCCESS, payload }
    }

    function failure(error) {
        return { type: profileConstants.SHOW_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request(id))
        return profileService.show(id).then(
            (payload) => {
                dispatch(success(payload))
                if (redirect)
                    history.push(links.profile.show(payload.data.number))
                return true
            },
            (error) => {
                dispatch(alertActions.error(error))
                dispatch(failure(error))
                return false
            }
        )
    }
}

function create(profile) {
    function request(profile) {
        return { type: profileConstants.CREATE_PENDING, profile }
    }

    function success(payload) {
        return { type: profileConstants.CREATE_SUCCESS, payload }
    }

    function failure(error) {
        return { type: profileConstants.CREATE_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request(profile))
        return profileService.create(profile).then(
            (payload) => {
                history.push(links.profile.show(payload.data.number))
                dispatch(alertActions.success(_('alert.createSuccess')))
                dispatch(success(payload))
                return true
            },
            (error) => {
                dispatch(alertActions.error(error))
                dispatch(failure(error))
                return false
            }
        )
    }
}

function update(profile) {
    function request(profile) {
        return { type: profileConstants.UPDATE_PENDING, profile }
    }

    function success(payload) {
        return { type: profileConstants.UPDATE_SUCCESS, payload }
    }

    function failure(error) {
        return { type: profileConstants.UPDATE_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request(profile))
        return profileService.update(profile).then(
            (payload) => {
                history.push(links.profile.show(payload.data.number))
                dispatch(alertActions.success(_('alert.updateSuccess')))
                dispatch(success(payload))
                return true
            },
            (error) => {
                dispatch(alertActions.error(error))
                dispatch(failure(error))
                return false
            }
        )
    }
}

function updateChemin(chemin) {
    function request(profile) {
        return { type: profileConstants.UPDATECHEMIN_PENDING, profile }
    }

    function success(payload) {
        return { type: profileConstants.UPDATECHEMIN_SUCCESS, payload }
    }

    function failure(error) {
        return { type: profileConstants.UPDATECHEMIN_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request(chemin))
        return profileService.updateChemin(chemin).then(
            (payload) => {
                dispatch(alertActions.success(_('alert.updateSuccess')))
                dispatch(success(payload))
                return true
            },
            (error) => {
                dispatch(alertActions.error(error))
                dispatch(failure(error))
                return false
            }
        )
    }
}

const profileActions = {
    list,
    show,
    create,
    update,
    updateChemin,
}

export default profileActions
