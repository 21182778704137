import drugConstants from 'stores/drug/drug.constants'

const initialState = []

// all state changes manipulations happen here
function drugStore(state = initialState, { type, payload }) {
    switch (type) {
        case drugConstants.SEARCH_SUCCESS:
        case drugConstants.LIST_SUCCESS:
            return payload.data
        case drugConstants.SHOW_SUCCESS:
            return [...initialState, payload.data]
        case drugConstants.SEARCH_FAILURE:
            return []
        default:
            return state
    }
}

export default drugStore
