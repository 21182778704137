import { httpClient } from 'helpers/http'

const resource = '/ways'

function list() {
    const data = httpClient.GET(`${resource}`)
    return data
}

function show(id) {
    return httpClient.GET(`${resource}/${id}`)
}

function create(drug) {
    const data = JSON.stringify(drug)
    return httpClient.POST(resource, { data })
}

function update(drug) {
    const data = JSON.stringify(drug)
    return httpClient.PUT(`${resource}/${drug.id}`, { data })
}

function destroy(drug) {
    return httpClient.DELETE(`${resource}/${drug.id}`)
}

function search(term) {
    return httpClient.GET(`${resource}/search/${term}`)
}

const drugService = {
    list,
    show,
    create,
    update,
    destroy,
    search,
}

export default drugService
