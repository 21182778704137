import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: '600',
        color: 'black',
        textDecoration: 'underline',
    },
    icon: {
        fontSize: '1.5em',
        verticalAlign: 'text-bottom',
        margin: theme.spacing(0, 0.25),
    },
}))

export default useStyles
