import React from 'react'

const Addicta = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        height="80"
        viewBox="0 0 609.3 119"
        {...props}
    >
        <path
            fill="#1d1d1b"
            d="M193.2,4v98H177.3V92.9a24.2,24.2,0,0,1-10.1,7.5,33.5,33.5,0,0,1-13.3,2.5,37.2,37.2,0,0,1-18.3-4.4,32.6,32.6,0,0,1-12.7-12.8,37.9,37.9,0,0,1-4.6-19,37.8,37.8,0,0,1,4.6-18.9,32.7,32.7,0,0,1,12.7-12.6,37.3,37.3,0,0,1,18.3-4.5A35.7,35.7,0,0,1,166.8,33a26.9,26.9,0,0,1,9.9,7.2V4ZM166.6,86.1a20.1,20.1,0,0,0,7.6-7.7,24.6,24.6,0,0,0,2.7-11.7,24.2,24.2,0,0,0-2.7-11.6,20.1,20.1,0,0,0-7.6-7.7,22.6,22.6,0,0,0-21.4,0,19.2,19.2,0,0,0-7.5,7.7,23.2,23.2,0,0,0-2.8,11.6,23.6,23.6,0,0,0,2.8,11.7,19.2,19.2,0,0,0,7.5,7.7,22.6,22.6,0,0,0,21.4,0Z"
        />
        <path
            fill="#1d1d1b"
            d="M290.6,4v98H274.7V92.9a24.2,24.2,0,0,1-10.1,7.5,33.5,33.5,0,0,1-13.3,2.5A37,37,0,0,1,233,98.5a32.6,32.6,0,0,1-12.7-12.8,38,38,0,0,1-4.7-19,37.8,37.8,0,0,1,4.7-18.9A32.7,32.7,0,0,1,233,35.2a37,37,0,0,1,18.3-4.5A35.7,35.7,0,0,1,264.2,33a26.9,26.9,0,0,1,9.9,7.2V4ZM264,86.1a19.2,19.2,0,0,0,7.5-7.7,23.6,23.6,0,0,0,2.8-11.7,23.2,23.2,0,0,0-2.8-11.6,19.2,19.2,0,0,0-7.5-7.7,22.6,22.6,0,0,0-21.4,0,19.2,19.2,0,0,0-7.5,7.7,23.2,23.2,0,0,0-2.8,11.6,23.6,23.6,0,0,0,2.8,11.7,19.2,19.2,0,0,0,7.5,7.7,22.6,22.6,0,0,0,21.4,0Z"
        />
        <path
            fill="#1d1d1b"
            d="M319.7,17a9.2,9.2,0,0,1-3.1-7.1,9.2,9.2,0,0,1,3.1-7.1A10.4,10.4,0,0,1,327.2,0a10.8,10.8,0,0,1,7.5,2.7,8.9,8.9,0,0,1,3.1,6.8,9.7,9.7,0,0,1-3,7.3,10.2,10.2,0,0,1-7.6,3A10.4,10.4,0,0,1,319.7,17Zm-.8,14.5h16.5V102H318.9Z"
        />
        <path
            fill="#1d1d1b"
            d="M376.3,98.3a34.5,34.5,0,0,1-13.6-12.9,36.5,36.5,0,0,1-4.9-18.7,36.4,36.4,0,0,1,4.9-18.6,34.2,34.2,0,0,1,13.6-12.8A40.6,40.6,0,0,1,396,30.7a38,38,0,0,1,18.3,4.2A27.8,27.8,0,0,1,426.2,47l-12.7,7.4a21.1,21.1,0,0,0-7.6-7.3,21.4,21.4,0,0,0-10-2.4,20.7,20.7,0,0,0-15.3,6c-4.1,4-6.1,9.3-6.1,16s2,12.1,6,16.1,9.1,6,15.4,6A19.7,19.7,0,0,0,413.5,79l12.7,7.4a29.6,29.6,0,0,1-12,12.3,37.9,37.9,0,0,1-18.2,4.2A41.5,41.5,0,0,1,376.3,98.3Z"
        />
        <path
            fill="#1d1d1b"
            d="M490.4,98.2a19.8,19.8,0,0,1-7.1,3.6,31.7,31.7,0,0,1-8.8,1.1c-7.7,0-13.7-2-17.9-6s-6.4-10-6.4-17.7v-34H438.6V32h11.6V15.9h16.5V32h18.9V45.2H466.7V78.8c0,3.4.9,6,2.5,7.8s4.1,2.7,7.3,2.7a14.4,14.4,0,0,0,9.3-2.9Z"
        />
        <path
            fill="#1d1d1b"
            d="M80.7,80.6H34.5L25.3,102H7.6L49.3,9.5H66.2L107.9,102H90ZM75,67.1,57.6,26.7,40.3,67.1Z"
        />
        <polygon
            fill="#f1c651"
            points="3.8 102 45.5 9.5 41.7 9.5 0 102 3.8 102"
        />
        <polygon
            fill="#f1c651"
            points="86.2 102 78.4 84.1 74.6 84.1 82.4 102 86.2 102"
        />
        <polygon
            fill="#f1c651"
            points="59.1 39.1 55.3 39.1 65.9 63.6 69.7 63.6 59.1 39.1"
        />
        <rect fill="#f1c651" y="115.5" width="107.9" height="3.48" />
        <path
            fill="#1d1d1b"
            d="M582.1,80.6H535.9L526.7,102H509L550.7,9.5h16.9L609.3,102H591.4Zm-5.7-13.5L559,26.7,541.7,67.1Z"
        />
        <polygon
            fill="#f1c651"
            points="505.2 102 546.9 9.5 543.1 9.5 501.4 102 505.2 102"
        />
        <polygon
            fill="#f1c651"
            points="587.6 102 579.8 84.1 576 84.1 583.8 102 587.6 102"
        />
        <polygon
            fill="#f1c651"
            points="560.5 39.1 556.7 39.1 567.3 63.6 571.1 63.6 560.5 39.1"
        />
        <rect fill="#f1c651" x="501.4" y="115.5" width="107.9" height="3.48" />
    </svg>
)

export default Addicta
