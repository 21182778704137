import React from 'react'

import DefinitionNode from '../nodes/DefinitionNode'

function EmphasisRenderer({ children }) {
    const { value } = children[0].props

    if (value.includes('--')) {
        return <DefinitionNode value={value}>{children}</DefinitionNode>
    }

    return <em>{children}</em>
}

export default EmphasisRenderer
