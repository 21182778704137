import { httpClient } from 'helpers/http'

const resource = '/profile'

function list(filters = undefined) {
    return httpClient.GET(resource + (filters || ''))
}

function show(number) {
    return httpClient.GET(`${resource}/${number}`)
}

function create(profile) {
    const data = JSON.stringify(profile)
    return httpClient.POST(resource, { data })
}

function update(profile) {
    const data = JSON.stringify(profile)
    return httpClient.PUT(`${resource}/${profile.id}`, { data })
}

function updateChemin(chemin) {
    const data = JSON.stringify(chemin)
    return httpClient.PUT(`/profile_chemin/${chemin.id}`, { data })
}

const profileService = {
    list,
    show,
    create,
    update,
    updateChemin,
}

export default profileService
