import { _ } from 'helpers/lang'
import authService from 'stores/auth/auth.service'
import authConstants from 'stores/auth/auth.constants'
import alertActions from 'stores/alert/alert.actions'
import configureScope from 'sentry/configureScope'
import { history } from 'stores/root-store'
import links from 'helpers/links'

function login(username, password) {
    function request(data) {
        return { type: authConstants.LOGIN_PENDING, data }
    }

    function success(payload) {
        return { type: authConstants.LOGIN_SUCCESS, payload }
    }

    function failure(error) {
        return { type: authConstants.LOGIN_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request({ username }))

        return authService.login(username, password).then(
            (data) => {
                dispatch(success(data))
                history.push(links.home)
                return true
            },
            (error) => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return false
            }
        )
    }
}

function logout(message = _('alert.loggedOut')) {
    function request(data) {
        return { type: authConstants.LOGOUT_PENDING, data }
    }

    function success(payload) {
        return { type: authConstants.LOGOUT_SUCCESS, payload }
    }

    function failure(error) {
        return { type: authConstants.LOGOUT_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request())

        return authService.logout().then(
            (data) => {
                configureScope(false) // tell sentry to forget auth user
                localStorage.removeItem('auth')
                dispatch(success(data))
                if (message) dispatch(alertActions.success(message))
                return true
            },
            (error) => {
                localStorage.removeItem('auth')
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return false
            }
        )
    }
}

function logoutAllDevices(message = _('alert.loggedOutAllDevices')) {
    function request(data) {
        return { type: authConstants.LOGOUT_DEVICES_PENDING, data }
    }

    function success(payload) {
        return { type: authConstants.LOGOUT_DEVICES_SUCCESS, payload }
    }

    function failure(error) {
        return { type: authConstants.LOGOUT_DEVICES_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request())

        return authService.logoutAllDevices().then(
            (data) => {
                configureScope(false) // tell sentry to forget auth user
                localStorage.removeItem('auth')
                dispatch(success(data))
                if (message) dispatch(alertActions.success(message))
                return true
            },
            (error) => {
                localStorage.removeItem('auth')
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return false
            }
        )
    }
}

function register(user) {
    function request(data) {
        return { type: authConstants.REGISTER_PENDING, data }
    }
    function success(payload) {
        return { type: authConstants.REGISTER_SUCCESS, payload }
    }
    function failure(error) {
        return { type: authConstants.REGISTER_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request(user))

        return authService.register(user).then(
            (data) => {
                dispatch(success(data))
                dispatch(alertActions.success(_('alert.registerSuccess')))
                return true
            },
            (error) => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return false
            }
        )
    }
}

function sendForgotPasswordMail(email) {
    function request(data) {
        return { type: authConstants.SEND_PASSWORD_MAIL_PENDING, data }
    }

    function success(payload) {
        return { type: authConstants.SEND_PASSWORD_MAIL_SUCCESS, payload }
    }

    function failure(error) {
        return { type: authConstants.SEND_PASSWORD_MAIL_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request(email))

        return authService.sendForgotPasswordMail(email).then(
            (data) => {
                dispatch(success(data))
                dispatch(alertActions.success(data))
                history.push(links.login)
                return true
            },
            (error) => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return false
            }
        )
    }
}

function sendConfirmationEmail(email) {
    function request(data) {
        return { type: authConstants.SEND_CONFIRMATION_MAIL_PENDING, data }
    }
    function success(payload) {
        return { type: authConstants.SEND_CONFIRMATION_MAIL_SUCCESS, payload }
    }
    function failure(error) {
        return { type: authConstants.SEND_CONFIRMATION_MAIL_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request({ email }))

        return authService.sendConfirmationEmail(email).then(
            (data) => {
                dispatch(success(data))
                dispatch(alertActions.success(data))
                return true
            },
            (error) => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return false
            }
        )
    }
}

function resetPassword(data) {
    function request(data) {
        return { type: authConstants.RESET_PASSWORD_PENDING, data }
    }

    function success(payload) {
        return { type: authConstants.RESET_PASSWORD_SUCCESS, payload }
    }

    function failure(error) {
        return { type: authConstants.RESET_PASSWORD_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request(data))

        return authService.resetPassword(data).then(
            (data) => {
                dispatch(success(data))
                dispatch(alertActions.success(data))
                this.props.history.push(links.login)
                return true
            },
            (error) => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return false
            }
        )
    }
}

const authActions = {
    login,
    logout,
    logoutAllDevices,
    register,
    sendForgotPasswordMail,
    sendConfirmationEmail,
    resetPassword,
}

export default authActions
