import React, { useContext, useEffect, useState } from 'react'
import ListButton from 'components/Button/ListButton'
import CategoryAccordions from 'components/CategoryAccordion/CategoryAccordion'
import { _ } from '../../helpers/lang'

function PostList({posts, link}) {
    return (
        <>
            {(Array.isArray(posts)) && posts.map((post) =>
                post.is_leaf && post.parent == null ? (
                    <ListButton
                        key={post.id}
                        to={link.show(post.slug)}
                    >
                        {post.name}
                    </ListButton>
                ) : post.parent == null && (
                    <CategoryAccordions
                        category={post.name}
                        model={post.children}
                        link={link}
                    >
                        {post.name}
                    </CategoryAccordions>
                ) 
            )}
        </>
    )
}

export default PostList