import Layout from 'components/Layout/Layout'
import { _ } from 'helpers/lang'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import { selectDrugBySlug } from 'stores/drug/drug.selectors'
import { selectDefinitions } from 'stores/definition/definition.selectors'
import NavButton from '../../components/Button/NavButton/NavButton'
import Header from '../../components/Header/Header'
import ModelShow from 'components/ModelShow/ModelShow'
import DrugType from '../../types/Drug.type'
import { initArrayOfObjectForFilter, initArrayOfStringForFilter } from 'utils/model'
import drugActions from '../../stores/drug/drug.actions'
import definitionActions from '../../stores/definition/definition.actions'
import links from 'helpers/links'
import ListTypesEnum from 'constants/enums/ListTypeEnum'
import Inner from 'components/Inner/Inner'
import DefinitionType from "../../types/Definition.type";

function mapStateToProps(state, { match }) {
    const { slug } = match.params
    return {
        slug: slug,
        drug: selectDrugBySlug(state, slug),
        definitions: selectDefinitions(state)
    }
}

const mapDispatchToProps = {
    showDrug: drugActions.show,
    listDefinitions: definitionActions.list
}

DrugShow.propTypes = {
    slug: PropTypes.string.isRequired,
    drug: DrugType.isRequired,
    showDrug: PropTypes.func.isRequired,
    definitions: DefinitionType.isRequired,
    listDefinitions: PropTypes.func.isRequired
}

function DrugShow(props) {
    const { slug, drug, showDrug, definitions, listDefinitions } = props
    const [isLoading, setIsLoading] = useState(true)



    useEffect(async () => {
        await showDrug(slug.split('-', 1)[0])
        await listDefinitions();
        setIsLoading(false)
    }, [])

    const nullVerification = (data) => {
        return (data == null ? _('Aucune information disponible') : data)
    }

    const headerInfos = (drug === undefined ? [] : [
        {
            label: _('drug.name_label'),
            data: nullVerification(drug.name)
        },
        {
            label: _('drug.alt'),
            data: nullVerification(drug.alt)
        },
        {
            label: _('drug.category'),
            data: (drug.category) ?
                [{
                    name: drug.category.name, dataKey: 'category', filterValue:
                        drug.category ?
                            drug.category.id :
                            null
                }
                ] :
                nullVerification(null),
        },
        {
            label: _('drug.dependency'),
            data: nullVerification((drug.dependency) ? (initArrayOfObjectForFilter(drug.dependency, 'dependencyName')) : null)
        },
    ])

    const selectorsInfos = (drug === undefined ? [] : [
        [
            { label: _('drug.clinical_observations'), introduction: nullVerification(drug.clinical_observation_intoxication_introduction), data: (drug.intoxication_observations) && initArrayOfStringForFilter(drug.intoxication_observations, 'intoxicationObservation') },
            { label: _('drug.strategy'), data: nullVerification(drug.intoxication_strategy) }
        ],
        [
            { label: _('drug.clinical_observation_physics'), introduction: nullVerification(drug.clinical_observation_physic_introduction), data: (drug.withdrawal_observation_physics) && initArrayOfStringForFilter(drug.withdrawal_observation_physics, 'withdrawalObservationPhysics') },
            { label: _('drug.clinical_observation_psychologics'), introduction: nullVerification(drug.clinical_observation_psycho_introduction), data: (drug.withdrawal_observation_psychologics) && initArrayOfStringForFilter(drug.withdrawal_observation_psychologics, 'withdrawalObservationPsychologics') },
            { label: _('drug.strategy'), data: nullVerification(drug.withdrawal_strategy), }
        ],
        [
            { data: nullVerification(drug.interactions) }
        ]
    ])

    return (
        <Layout title={drug ? drug.name : ''} isGreyBackground={true}>
            <div style={{ backgroundColor: '#f0f0f0' }}>
                <LoadingContainer isLoading={isLoading}>
                    <Header>
                        <NavButton title={drug ? drug.name : ''} />
                    </Header>
                    <Inner>
                        <ModelShow
                            description={drug && drug.description}
                            header={headerInfos}
                            images={drug.images ? drug.images : []}
                            ways={initArrayOfObjectForFilter(drug.ways, 'wayName')}
                            dosageInfo={[]}
                            selectorsInfos={selectorsInfos}
                            modelType={ListTypesEnum.Drug}
                            link={links.drug.list}
                            definitions={definitions}
                        />
                    </Inner>
                </LoadingContainer>
            </div>
        </Layout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(DrugShow)