import React from 'react'
import {Paper} from '@material-ui/core'
import Inner from 'components/Inner/Inner'
import AccordionWithScroll from 'components/Accordions/AccordionWithScroll';
import TextLinkMarkdown from 'components/TextLinkMarkdown/TextLinkMarkdown';

function PostDetail({post, headerSize = 50}) {
    const [expanded, setExpanded] = React.useState(false)
    
    const handleChange = (panel, isExpanded) => (
        setExpanded(isExpanded ? panel : false)
    )

    return (
        <Paper>
            <Inner>
                {post.map((section, index) => {
                    return (
                        <AccordionWithScroll
                            key={index}
                            index={index}
                            expanded={expanded === section.title}
                            title={section.title}
                            onChange={handleChange}
                            headerSize={headerSize}
                        >
                            <TextLinkMarkdown text={section.content} />
                        </AccordionWithScroll>
                    )
                })}
            </Inner>
        </Paper>
    );
}

export default PostDetail