import React from 'react'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import Button from './Button'
import useStyles from './Button.styles'

function LargeButton({ children, ...props }) {
    const classes = useStyles(props)

    return (
        <Button
            className={classes.largeButton}
            endIcon={<ChevronRightIcon />}
            {...props}
        >
            {children}
        </Button>
    )
}

export default LargeButton
