import troubleService from 'stores/trouble/trouble.service'
import troubleConstants from 'stores/trouble/trouble.constants'
import { history } from '../root-store'
import links from '../../helpers/links'
import alertActions from '../alert/alert.actions'

function list(filters = undefined) {
    function request(filters) {
        return { type: troubleConstants.LIST_PENDING, filters }
    }

    function success(payload) {
        return { type: troubleConstants.LIST_SUCCESS, payload }
    }

    function failure(error) {
        return { type: troubleConstants.LIST_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request(filters))
        return troubleService.list(filters).then(
            (data) => {
                dispatch(success(data))
                return true
            },
            (error) => {
                dispatch(failure(error))
                return false
            }
        )
    }
}

function show(id) {
    function request(id) {
        return { type: troubleConstants.SHOW_PENDING, id }
    }

    function success(payload) {
        return { type: troubleConstants.SHOW_SUCCESS, payload }
    }

    function failure(error) {
        return { type: troubleConstants.SHOW_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request(id))
        return troubleService.show(id).then(
            (payload) => {
                dispatch(success(payload))
                return true
            },
            (error) => {
                dispatch(failure(error))
                return false
            }
        )
    }
}

function search(term, redirect = false) {
    function request(id) {
        return { type: troubleConstants.SEARCH_PENDING, id }
    }

    function success(payload) {
        return { type: troubleConstants.SEARCH_SUCCESS, payload }
    }

    function failure(error) {
        return { type: troubleConstants.SEARCH_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request(term))
        return troubleService.search(term).then(
            (payload) => {
                dispatch(success(payload))
                if (redirect) history.push(links.post.search(term))
                return true
            },
            (error) => {
                dispatch(alertActions.error(error))
                dispatch(failure(error))
                return false
            }
        )
    }
}

const troubleActions = {
    list,
    show,
    search,
}

export default troubleActions
