import definitionConstants from 'stores/definition/definition.constants'

const initialState = []

// all state changes manipulations happen here
function definitionStore(state = initialState, { type, payload }) {
    switch (type) {
        case definitionConstants.LIST_SUCCESS:
            return payload.data
        default:
            return state
    }
}

export default definitionStore
