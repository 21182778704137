import { httpClient } from 'helpers/http'

const resource = '/definition'

function list(filters) {
    if(filters!==null && filters){
        let request = '?'
        for (const [key, value] of Object.entries(JSON.parse(filters))){
            request += `filter[${key}]=${value}&`
        }
        return httpClient.GET(`${resource}${(`${request.slice(0, -1)}`)}`)
    } else {
        return httpClient.GET(`${resource}`)
    }
}

const definitionService = {
    list,
}

export default definitionService
