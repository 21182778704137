import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout/Layout'
import { _ } from 'helpers/lang'
import { selectMedicaments } from 'stores/medicament/medicament.selectors'
import medicamentActions from '../../stores/medicament/medicament.actions'
import PropTypes from 'prop-types'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import MedicamentType from '../../types/Medicament.type'
import Inner from 'components/Inner/Inner'
import ModelShow from 'components/ModelShow/ModelShow'
import Header from 'components/Header/Header'
import { initArrayOfObjectForFilter, initArrayOfStringForFilter } from 'utils/model'
import links from 'helpers/links'
import NavButton from 'components/Button/NavButton/NavButton'
import {selectDefinitions} from "../../stores/definition/definition.selectors";
import definitionActions from "../../stores/definition/definition.actions";
import DefinitionType from "../../types/Definition.type";

function mapStateToProps(state, { match }) {
    const { slug } = match.params
    return {
        slug: slug,
        medicament: selectMedicaments(state),
        definitions: selectDefinitions(state)
    }
}

const mapDispatchToProps = {
    showMedicament: medicamentActions.show,
    listDefinitions: definitionActions.list
}

MedicamentShow.propTypes = {
    slug: PropTypes.string.isRequired,
    medicament: MedicamentType.isRequired,
    showMedicament: PropTypes.func.isRequired,
    definitions: DefinitionType.isRequired,
    listDefinitions: PropTypes.func.isRequired
}

function MedicamentShow(props) {
    const { slug, medicament, showMedicament, definitions, listDefinitions } = props
    const medication = medicament
    const [isLoading, setIsLoading] = useState(true)
    const selectors = [
        _('modelShow.secondary_effect'),
        _('modelShow.withdrawal'),
        _('modelShow.interaction')
    ]

    useEffect(async () => {
        await showMedicament(slug.split('-', 1)[0])
        await listDefinitions();
        setIsLoading(false)
    }, [])

    const nullVerification = (data) => {
        return (data == null ? _('Aucune information disponible') : data)
    }

    const getDependenciesToString = (dependencies) => {

        if (dependencies.length > 0) {
            let stringToReturn = ""
            dependencies.forEach(object => {
                stringToReturn += object.name + ", "
            });
            return stringToReturn
        }
        return null
    }

    const headerInfos = [
        {
            label: _('medication.name'),
            data: nullVerification(medication.name)
        },
        {
            label: _('medication.generic_name'),
            data: nullVerification(medication.generic_name)
        },
        {
            label: _('medication.indications'),
            data: nullVerification(medication.description)
        },
        {
            label: _('medication.category'),
            data: (medication.category) ?
                [{
                    name: medication.category.name, dataKey: 'category', filterValue:
                        medication.category ?
                            medication.category.id :
                            null
                }
                ] :
                nullVerification(null),
            dataKey: 'category',
            filterValue: medication.category ? medication.category.id : null
        },
       {
            label: _('medication.effects'),
            data: nullVerification(medication.effects)
        },

        {
            label: _('medication.action_duration'),
            data: nullVerification((medication.consumption_type))
        },

    ]

    const dosageInfo = [
        { label: _('medication.available_doses'), data: nullVerification(medication.available_doses) },
        { label: _('medication.dosage'), data: nullVerification(medication.dosage) }
    ]

    const selectorsInfos = [
        [
            { label: _('medication.symptoms'), data: (medication.secondary_effects) && initArrayOfStringForFilter(medication.secondary_effects, 'secondaryEffectSymptoms') },
            { label: _('medication.strategy'), data: nullVerification(medication.intoxication_strategy) }
        ],
        [
            { label: _('medication.clinical_observation_physics'), data: (medication.withdrawal_observation_physics) && initArrayOfStringForFilter(medication.withdrawal_observation_physics, 'withdrawalObservationPhysics') },
            { label: _('medication.clinical_observation_psychologics'), data: (medication.withdrawal_observation_psychologics) && initArrayOfStringForFilter(medication.withdrawal_observation_psychologics, 'withdrawalObservationPsychologics') },
            { label: _('medication.strategy'), data: nullVerification(medication.withdrawal_strategy) }
        ],
        [
            { data: nullVerification(medication.interactions) }
        ]
    ]

    return (
        <Layout title={_('app.medications')} isGreyBackground={true}>
            <LoadingContainer isLoading={isLoading}>
                <Header>
                    <NavButton title={ medication.generic_name ? medication.name + ' / ' + medication.generic_name : medication.name } />
                </Header>
                <Inner>
                    <ModelShow
                        description={""}
                        header={headerInfos}
                        images={medication.images ? medication.images : []}
                        ways={initArrayOfObjectForFilter(medicament.ways, 'wayName')}
                        dosageInfo={dosageInfo}
                        selectorsInfos={selectorsInfos}
                        link={links.medicament.list}
                        selectors={selectors}
                        definitions={definitions}
                    />
                </Inner>
            </LoadingContainer>
        </Layout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicamentShow)