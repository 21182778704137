import definitionService from 'stores/definition/definition.service'
import definitionConstants from 'stores/definition/definition.constants'

function list(filters = undefined) {
    function request(filters) {
        return { type: definitionConstants.LIST_PENDING, filters }
    }

    function success(payload) {
        return { type: definitionConstants.LIST_SUCCESS, payload }
    }

    function failure(error) {
        return { type: definitionConstants.LIST_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request(filters))
        return definitionService.list(filters).then(
            (data) => {
                dispatch(success(data))
                return true
            },
            (error) => {
                dispatch(failure(error))
                return false
            }
        )
    }
}



const definitionActions = {
    list,
}

export default definitionActions
