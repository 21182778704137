import { Divider, Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import Filters from '../Filters/Filters'
import useStyles from './Listing.styles'
import links from '../../helpers/links'
import ListButton from 'components/Button/ListButton'
import { _ } from '../../helpers/lang'

Listing.propTypes = {
}

export default function Listing({ list, listType, title, className, filterSections, onFilter, initialFilter, onRemoveAllAppliedFilters, definitions}) {
    const classes = useStyles()

    return (
        <div className={className}>
            <Grid container justifyContent="center"  className={className}>
                <Grid container item>
                    <Filters 
                        filterSections={filterSections} 
                        onFilter={onFilter} 
                        initialFilter={initialFilter}
                        onRemoveAllAppliedFilters={onRemoveAllAppliedFilters}
                        definitions={definitions}
                    />
                </Grid>
                <Grid
                    container
                    item
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Grid item xs={4} sm={3} md={2} lg={2}>
                        <Typography
                            className={classes.dividerTitle}
                            component={'p'}
                        >
                            {_('app.listing') + title.toLowerCase()}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        className={classes.divider}
                        xs={8}
                        sm={9}
                        md={10}
                        lg={10}
                    >
                        <Divider />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" >
                <Grid xs={12} md={12} lg={12} xl={12} item>
                        {(Array.isArray(list))&&list.map((listItem) => (
                            <ListButton
                                key={listItem.id}
                                to={links[listType].show(listItem.slug)}
                            >
                                {listItem.name}
                            </ListButton>
                        ))}
                </Grid>
            </Grid>
        </div>
    )
}
