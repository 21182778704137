import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    button: {
        minHeight: '50px',
        lineHeight: '1.3em',
        maxWidth: `calc(100vw - ${theme.spacing(8)}px)`,
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.dark,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    largeButton: {
        width: '100%',
        maxWidth: theme.spacing(35),
        '&>.MuiButton-label': {
            textAlign: 'left',
            justifyContent: 'space-between',
            '&>.MuiButton-iconSizeMedium > *:first-child': {
                fontSize: theme.spacing(3),
                color: theme.palette.yellow.main,
            },
        },
    },
    listButton:{
        '@media only screen and (maxWidth: 600px)': {
            margin: theme.spacing(1),
        },
        fontWeight: '400',
        fontSize: '0.95rem !important',
        maxWidth: '-webkit-fill-available !important',
        marginBottom: '16px',
        width: '100%',
        '&>.MuiButton-label': {
            textAlign: 'left',
            justifyContent: 'space-between',
            '&>.MuiButton-iconSizeMedium > *:first-child': {
                fontSize: theme.spacing(3),
                color: theme.palette.yellow.main,
            },
        },
    },
    buttonlist: {
        margin: theme.spacing(1, -1),
    },
    center: {
        textAlign: 'center',
        justifyContent: 'center',
    },
    filter: {
        color: theme.palette.yellow.main,
        textTransform: 'none',
    },
    filterSvg: {
        fill: theme.palette.yellow.main,
    },
}))

export default useStyles
