import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { IconButton, InputBase, Box, Grid, Paper } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import Layout from 'components/Layout/Layout'
import { selectProfiles } from 'stores/profile/profile.selectors'
import profileActions from 'stores/profile/profile.actions'
import ProfileListItem from 'components/ProfileListItem/ProfileListItem'
import useStyles from './ProfileList.styles'
import { history } from 'stores/root-store'
import ProfileType from 'types/Profile.type'
import { _ } from '../../helpers/lang'

const mapStateToProps = (state) => {
    return {
        profiles: selectProfiles(state),
    }
}

const mapDispatchToProps = {
    show: profileActions.show,
}

ProfileList.propTypes = {
    profiles: PropTypes.arrayOf(ProfileType),
    show: PropTypes.func.isRequired,
}

function ProfileList(props) {
    const classes = useStyles()
    const [number, setNumber] = useState('')

    const handleSearch = (e) => {
        e.preventDefault()
        if (number === '') return
        props.show(number, true)
    }

    return (
        <Layout title={_('app.profiles')}>
            <Box p={2} bgcolor="primary.light">
                <Grid container justifyContent="center">
                    <Paper
                        component="form"
                        onSubmit={handleSearch}
                        className={classes.paper}
                    >
                        <InputBase
                            color="primary"
                            variant="outlined"
                            size="small"
                            margin="dense"
                            placeholder="Numéro de dossier"
                            value={number}
                            classes={{ input: classes.searchInput }}
                            onChange={(e) => setNumber(e.target.value.trim())}
                        />
                        <IconButton
                            onClick={handleSearch}
                            color="primary"
                            aria-label="Chercher un profil"
                        >
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Grid>
            </Box>

            <div className={classes.results}>
                {props.profiles
                    .slice(0)
                    .reverse()
                    .map((profile) => (
                        <ProfileListItem key={profile.id} profile={profile} />
                    ))}
            </div>

            <IconButton
                onClick={() => history.push('/profils/new')}
                classes={{ root: classes.addButton }}
                color="primary"
                aria-label="Ajouter un profil"
            >
                <AddIcon />
            </IconButton>
        </Layout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileList)
