import React from "react"
import Layout from "components/Layout/Layout"
import ListHeader from "components/ListHeader/ListHeader"
import Inner from "components/Inner/Inner"
import LoadingContainer from "components/LoadingContainer/LoadingContainer"

function PostLayout({children, title, handleSearch, isLoading, link }){
    return (
        <Layout>
            <ListHeader title={title} onSearch={handleSearch} isOnSubmitSearch={true} link={link}  />
            <Inner>
                <LoadingContainer isLoading={isLoading}>
                    {children}
                </LoadingContainer>
            </Inner>
        </Layout>
    )
}

export default PostLayout