import React, {useRef, useEffect} from 'react'
import useStyles from './AccordionWithScroll.styles'
import { Accordion, AccordionSummary, AccordionDetails, Typography} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

function AccordionWithScroll({index, expanded, title, onChange, children, headerSize, isRisk = false}){
    const classes = useStyles()
    const ref = useRef()
    useEffect(() => {
        if(expanded){
            setTimeout(()=>{
                const topPosition = ref.current.offsetTop
                window.scrollTo(0, topPosition + headerSize)
            }, 50)
        }
    }, [expanded])
    const handleChange = (event, isExpanded) => {
        onChange(title, isExpanded)
    }
    return (
        <div ref={ref}>
            <Accordion
                TransitionProps={{timeout: 0}}
                expanded={expanded}
                onChange={handleChange}
            >
                <AccordionSummary
                    expandIcon={expanded ?
                        <RemoveIcon className={classes.buttonIcon} /> :
                        <AddIcon className={classes.buttonIcon} />
                    }
                    className={isRisk ? classes.summaryRisk : classes.summary}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography dangerouslySetInnerHTML={{ __html: title}} variant="body1"></Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    {children}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default AccordionWithScroll