import { httpClient } from 'helpers/http'

const resource = '/drug'

function list(filters) {
    if(filters!==null && filters){
        let request = '?'
        for (const [key, value] of Object.entries(JSON.parse(filters))){
            request += `filter[${key}]=${value}&`
        }
        return httpClient.GET(`${resource}${(`${request.slice(0, -1)}`)}`)
    } else {
        return httpClient.GET(`${resource}`)
    }
}

function show(id) {
    return httpClient.GET(`${resource}/${id}`)
}

function create(drug) {
    const data = JSON.stringify(drug)
    return httpClient.POST(resource, { data })
}

function update(drug) {
    const data = JSON.stringify(drug)
    return httpClient.PUT(`${resource}/${drug.id}`, { data })
}

function destroy(drug) {
    return httpClient.DELETE(`${resource}/${drug.id}`)
}

function search(term) {
    return httpClient.GET(`${resource}/search/${term}`)
}

const drugService = {
    list,
    show,
    create,
    update,
    destroy,
    search,
}

export default drugService
