import React, { useContext } from 'react'

import useStyles from './LoadingSpinner.styles'
import { CircularProgress } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { ColorContext } from '../../helpers/theme'

export default function LoadingSpinner({ style }) {
    const { colors } = useContext(ColorContext)
    const classes = useStyles({ color: colors.topbarColor })

    return (
        <Box style={style} className={classes.boxCenter}>
            {' '}
            <CircularProgress className={classes.spinner} />{' '}
        </Box>
    )
}
