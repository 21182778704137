const local = {
    environment: 'local',
    debugMode: true,
    debugHttpCalls: false,
    appUrl: 'http://addicta.test/',
    apiUrl: 'http://api.addicta.test/api/v1',
    pdfUrl: 'http://api.addicta.test/storage/pdf/',
}

export default local
