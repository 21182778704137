const validate = (values) => {
    const errors = {}

    if (!values.term.trim() || values.term.trim() === '') {
        errors.term = true
    }
    return errors
}

export default validate
