import { httpClient } from 'helpers/http'
import { handleAuth } from 'helpers/auth'

function login(email, password) {
    const data = JSON.stringify({ email, password, token_name: 'spa' })
    return httpClient.POST('/auth/login', { data }, [handleAuth])
}

function logout() {
    return httpClient.POST('/auth/logout')
}

function logoutAllDevices() {
    return httpClient.POST('/auth/logout-all-devices')
}

function register(user) {
    const data = JSON.stringify(user)
    return httpClient.POST('/auth/register', { data }, [handleAuth])
}

function sendForgotPasswordMail(email) {
    const data = JSON.stringify({ email })
    return httpClient.POST('/auth/password/forgot', { data })
}

function sendConfirmationEmail(email) {
    const data = JSON.stringify(email)
    return httpClient.POST('/auth/email/confirm/resend', { data })
}

function resetPassword(data) {
    data = JSON.stringify(data)
    return httpClient.POST('/auth/password/reset', { data })
}

const authService = {
    login,
    logout,
    logoutAllDevices,
    register,
    sendForgotPasswordMail,
    sendConfirmationEmail,
    resetPassword,
}

export default authService
