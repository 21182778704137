import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import List from '@material-ui/core/List'
import Inner from 'components/Inner/Inner'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PersonIcon from '@material-ui/icons/Person'
import EmailIcon from '@material-ui/icons/Email'
import Typography from '@material-ui/core/Typography'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import { _ } from 'helpers/lang'
import authActions from 'stores/auth/auth.actions'
import selectAuth from 'stores/auth/auth.selectors'
import Layout from 'components/Layout/Layout'
import Button from 'components/Button/Button'
import UserType from 'types/User.type'
import useStyles from './Account.styles'
import { useHistory } from 'react-router-dom'
import links from 'helpers/links'

function mapStateToProps(state) {
    return {
        user: selectAuth(state).user,
    }
}

const mapDispatchToProps = {
    sendForgotPasswordMail: authActions.sendForgotPasswordMail,
}

Account.propTypes = {
    user: UserType.isRequired,
    sendForgotPasswordMail: PropTypes.func.isRequired,
}

function Account(props) {
    const classes = useStyles()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const { sendForgotPasswordMail, user } = props

    return (
        <Layout container>
            <Inner>
                <LoadingContainer isLoading={isLoading}>
                    <div className={classes.buttonContainer}>
                        <Typography variant="h1">{_('app.viewAccount')}</Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <PersonIcon />
                                </ListItemIcon>
                                <ListItemText primary={user.name} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <EmailIcon />
                                </ListItemIcon>
                                <ListItemText primary={user.email} />
                            </ListItem>
                        </List>
                        <Button
                            className={classes.passwordButton}
                            disabled={disabled}
                            onClick={async () => {
                                setIsLoading(true)
                                await sendForgotPasswordMail(user.email)
                                setIsLoading(false)
                                setDisabled(true)
                            }}
                        >
                            {_('auth.requestPasswordReset')}
                        </Button>
                        <Button
                            className={classes.logoutButton}
                            onClick={() => history.push(links.logoutAllDevices)}
                        >
                            {_('auth.logoutAllDevices')}
                        </Button>
                    </div>
                </LoadingContainer>
            </Inner>
        </Layout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)
