import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    tr: {
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    td: {
        verticalAlign: 'top',
        padding: theme.spacing(1, 0),
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            paddingTop: 0,
        },
    },
    tdTitle: {
        fontWeight: 'bold',
        paddingRight: theme.spacing(1),
        width: '200px',
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(1),
        },
    },
    tdContent: {
        // textAlign: 'right',
    },
    '@global': {
        '.markdown': {
            marginTop: '-1em',
        },
    },
}))

export default useStyles
