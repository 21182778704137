import drugCategoryConstants from 'stores/drug-category/drug-category.constants'

const initialState = []

// all state changes manipulations happen here
function drugCategoryStore(state = initialState, { type, payload }) {
    switch (type) {
        case drugCategoryConstants.LIST_SUCCESS:
            return payload.data

        default:
            return state
    }
}

export default drugCategoryStore
