const medicamentConstants = {
    LIST_PENDING: 'MEDICAMENT/LIST_PENDING',
    LIST_SUCCESS: 'MEDICAMENT/LIST_SUCCESS',
    LIST_FAILURE: 'MEDICAMENT/LIST_FAILURE',

    SHOW_PENDING: 'MEDICAMENT/SHOW_PENDING',
    SHOW_SUCCESS: 'MEDICAMENT/SHOW_SUCCESS',
    SHOW_FAILURE: 'MEDICAMENT/SHOW_FAILURE',

    SEARCH_PENDING: 'MEDICAMENT/SEARCH_PENDING',
    SEARCH_SUCCESS: 'MEDICAMENT/SEARCH_SUCCESS',
    SEARCH_FAILURE: 'MEDICAMENT/SEARCH_FAILURE',

    CREATE_PENDING: 'MEDICAMENT/CREATE_PENDING',
    CREATE_SUCCESS: 'MEDICAMENT/CREATE_SUCCESS',
    CREATE_FAILURE: 'MEDICAMENT/CREATE_FAILURE',

    UPDATE_PENDING: 'MEDICAMENT/UPDATE_PENDING',
    UPDATE_SUCCESS: 'MEDICAMENT/UPDATE_SUCCESS',
    UPDATE_FAILURE: 'MEDICAMENT/UPDATE_FAILURE',

    DELETE_PENDING: 'MEDICAMENT/DELETE_PENDING',
    DELETE_SUCCESS: 'MEDICAMENT/DELETE_SUCCESS',
    DELETE_FAILURE: 'MEDICAMENT/DELETE_FAILURE',
}

export default medicamentConstants
