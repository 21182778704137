import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    Card: {
        borderRadius: 0,
        boxShadow: 'none',
        background: theme.palette.yellow.light,
    },
    CardHeader: {
        paddingBottom: 0,
    },
    CardActions: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    CardContent: {
        paddingTop: 0,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}))

export default useStyles
