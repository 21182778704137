import { httpClient } from 'helpers/http'

const resource = '/dependency_types'

function list() {
    return httpClient.GET(`${resource}`)
}

function show(id) {
    return httpClient.GET(`${resource}/${id}`)
}

function create(dependencyType) {
    const data = JSON.stringify(dependencyType)
    return httpClient.POST(resource, { data })
}

function update(dependencyType) {
    const data = JSON.stringify(dependencyType)
    return httpClient.PUT(`${resource}/${dependencyType.id}`, { data })
}

function destroy(dependencyType) {
    return httpClient.DELETE(`${resource}/${dependencyType.id}`)
}

function search(term) {
    return httpClient.GET(`${resource}/search/${term}`)
}

const dependencyTypeService = {
    list,
    show,
    create,
    update,
    destroy,
    search,
}

export default dependencyTypeService
