import React from 'react'
import PropTypes from 'prop-types'
import useStyles from './Header.styles'
import Grid from '@material-ui/core/Grid'

Header.propTypes = {
    children: PropTypes.any,
}

function Header(props) {
    const classes = useStyles()
    const { children } = props

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid xs={12} md={10} lg={8} xl={6} item>
                    {children}
                </Grid>
            </Grid>
        </div>
    )
}

export default Header
