import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(0.5, 0, 0.5, 2),
        display: 'flex',
        alignItems: 'center',
        boxShadow: 'none',
    },
    searchInput: {
        background: 'white',
    },
    searchButton: {
        height: theme.spacing(5),
    },
    addButton: {
        position: 'fixed',
        bottom: theme.spacing(9),
        right: theme.spacing(2),
        background: theme.palette.secondary.main,
        color: 'black',
        '&:hover': {
            background: theme.palette.secondary.light,
        },
    },
    results: {
        background: 'white',
        padding: theme.spacing(0, 3, 3),
    },
}))

export default useStyles
