const links = {
    home: '/',
    login: '/login',
    logout: '/logout',
    logoutAllDevices: '/logout-all-devices',
    account: '/account',
    notfound: '/not-found',
    more: '/plus',
    password: {
        forgot: '/password/forgot',
    },
    plan: {
        list: `/plans`,
        show: (slug, searchTerm = "") => `/plans/${slug}/${searchTerm}`,
        search: (slug) => `/plans/search/${slug}`,
    },
    drug: {
        list: `/drugs`,
        show: (slug) => `/drugs/${slug}`,
    },
    medicament: {
        list: `/medicaments`,
        show: (slug) => `/medicaments/${slug}`,
    },
    post: {
        list: (slug) => `/menu/${slug}`,
        show: (slug) => `/posts/${slug}`,
        search: (slug) => `/posts/search/${slug}`,
    },
    profile: {
        list: '/profils',
        new: '/profils/new',
        edit: (slug) => `/profils/edit/${slug}`,
        show: (slug) => `/profils/show/${slug}`,
        phase: (slug, phase) => `/profils/show/${slug}/phase/${phase}`,
    },
    guidePratique: {
        list: `/guides-de-pratique/`,
        show: (slug, searchTerm = "") => `/guides-de-pratique/${slug}/${searchTerm}`,
        search: (slug) => `/guides-de-pratique/search/${slug}`
    },
    programmeClinique: {
        list: `/programme-clinique/`,
        show: (slug, searchTerm = "") => `/programme-clinique/${slug}/${searchTerm}`,
        search: (slug) => `/programme-clinique/search/${slug}`,
    },
    politiquesProcedures: {
        list: `/politiques-et-procedures/`,
        show: (slug, searchTerm = "") => `/politiques-et-procedures/${slug}/${searchTerm}`,
        search: (slug) => `/politiques-et-procedures/search/${slug}`,
    },
}

export default links
