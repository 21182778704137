import { removeEmpty } from 'utils/functions'
import { required, email } from 'utils/validation'

const validate = (values) => {
    const errors = {
        email: email(values.email),
        password: required(values.password),
    }

    return removeEmpty(errors)
}

export default validate
