import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import authActions from 'stores/auth/auth.actions'
import ResetPasswordForm from './ResetPasswordForm/ResetPasswordForm'
import { HistoryType, MatchType } from 'types/ReactRouter.type'

const mapStateToProps = (state, { history, match }) => {
    return {
        history,
        match,
    }
}

const mapDispatchToProps = {
    resetPassword: authActions.resetPassword,
}

const propTypes = {
    history: HistoryType.isRequired,
    match: MatchType.isRequired,
    resetPassword: PropTypes.func.isRequired,
}

class ResetPassword extends React.Component {
    handleSubmit = (data, { setSubmitting }) => {
        setSubmitting(true)
        this.props.resetPassword(data).then((success) => {
            if (!success) setSubmitting(false)
        })
    }

    render() {
        return (
            <ResetPasswordForm
                handleSubmit={this.handleSubmit}
                token={this.props.match.params.token}
                email={this.props.match.params.email}
            />
        )
    }
}

ResetPassword.propTypes = propTypes

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
