import alertConstants from 'stores/alert/alert.constants'
import { _ } from 'helpers/lang'
import { displayHumanFutureDate } from 'utils/dates'

function success(message, component = null) {
    return {
        type: alertConstants.SUCCESS,
        message,
        component,
    }
}

function error(message, component = null) {
    return { type: alertConstants.ERROR, message, component }
}

function warning(message, component = null) {
    return { type: alertConstants.WARNING, message, component }
}

function info(message, component = null) {
    return { type: alertConstants.INFO, message, component }
}

function clear(index) {
    return { type: alertConstants.CLEAR, index }
}

// notify account expiration
function expiration({ user }) {
    const date = user.end ? displayHumanFutureDate(user.end) : false
    if (!date) return { type: null }
    return info(_('app.expiration', { date }))
}

const alertActions = {
    success,
    error,
    warning,
    info,
    clear,
    expiration,
}

export default alertActions
