const profileConstants = {
    LIST_PENDING: 'PROFILE/LIST_PENDING',
    LIST_SUCCESS: 'PROFILE/LIST_SUCCESS',
    LIST_FAILURE: 'PROFILE/LIST_FAILURE',

    SHOW_PENDING: 'PROFILE/SHOW_PENDING',
    SHOW_SUCCESS: 'PROFILE/SHOW_SUCCESS',
    SHOW_FAILURE: 'PROFILE/SHOW_FAILURE',

    CREATE_PENDING: 'PROFILE/CREATE_PENDING',
    CREATE_SUCCESS: 'PROFILE/CREATE_SUCCESS',
    CREATE_FAILURE: 'PROFILE/CREATE_FAILURE',

    UPDATE_PENDING: 'PROFILE/UPDATE_PENDING',
    UPDATE_SUCCESS: 'PROFILE/UPDATE_SUCCESS',
    UPDATE_FAILURE: 'PROFILE/UPDATE_FAILURE',

    UPDATECHEMIN_PENDING: 'PROFILE/UPDATECHEMIN_PENDING',
    UPDATECHEMIN_SUCCESS: 'PROFILE/UPDATECHEMIN_SUCCESS',
    UPDATECHEMIN_FAILURE: 'PROFILE/UPDATECHEMIN_FAILURE',

    DELETE_PENDING: 'PROFILE/DELETE_PENDING',
    DELETE_SUCCESS: 'PROFILE/DELETE_SUCCESS',
    DELETE_FAILURE: 'PROFILE/DELETE_FAILURE',
}

export default profileConstants
