import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        flexGrow: 1,
    },
    appbar: {
        color: 'black',
        background: (props) =>
            props.color ? theme.palette[props.color].main : 'white',
    },
    toolbar: {
        paddingRight: 0,
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        marginRight: theme.spacing(2),
    },
}))

export default useStyles
