import { httpClient } from 'helpers/http'

const resource = '/medicament-category'

function list() {
    return httpClient.GET(resource)
}

function show(id) {
    return httpClient.GET(`${resource}/${id}`)
}

function create(medicamentCategory) {
    const data = JSON.stringify(medicamentCategory)
    return httpClient.POST(resource, { data })
}

function update(medicamentCategory) {
    const data = JSON.stringify(medicamentCategory)
    return httpClient.PUT(`${resource}/${medicamentCategory.id}`, { data })
}

function destroy(medicamentCategory) {
    return httpClient.DELETE(`${resource}/${medicamentCategory.id}`)
}

const medicamentCategoryService = {
    list,
    show,
    create,
    update,
    destroy,
}

export default medicamentCategoryService
