import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'

import { _ } from 'helpers/lang'

import MoreIcon from 'assets/svg/more.svg'
import PoliciesProceduresIcon from 'assets/svg/tasks-solid.svg'
import GuideDePratiqueIcon from 'assets/svg/guide-de-pratique.svg'
import ProgrammeCliniqueIcon from 'assets/svg/programme-clinique.svg'
import TreatmentPlanIcon from 'assets/svg/plan-de-soins.svg'

import useStyles from './Bottombar.styles'
import links from 'helpers/links'

const Bottombar = () => {
    const classes = useStyles()
    const history = useHistory()
    const { pathname } = useLocation()

    const handleClick = (url) => {
        history.push(url)
    }

    const actionClasses = {
        root: classes.actionRoot,
        wrapper: classes.actionWrapper,
        label: classes.actionLabel,
    }

    return (
        <BottomNavigation showLabels className={classes.bottombar}>
            <BottomNavigationAction
                label={_('app.policies_procedures')}
                icon={
                    <img
                        alt="Politiques et procedures"
                        src={PoliciesProceduresIcon}
                    />
                }
                classes={actionClasses}
                className={
                    pathname.startsWith(links.politiquesProcedures.list)
                        ? classes.active
                        : ''
                }
                onClick={() => handleClick(links.politiquesProcedures.list)}
            />
            <BottomNavigationAction
                label={_('app.programme_clinique')}
                icon={
                    <img
                        alt="Programmes cliniques"
                        src={ProgrammeCliniqueIcon}
                    />
                }
                classes={actionClasses}
                className={
                    pathname.startsWith(links.programmeClinique.list)
                        ? classes.active
                        : ''
                }
                onClick={() => handleClick(links.programmeClinique.list)}
            />
            <BottomNavigationAction
                label={_('app.guides_de_pratique')}
                icon={
                    <img alt="Guide de pratiques" src={GuideDePratiqueIcon} />
                }
                classes={actionClasses}
                className={
                    pathname.startsWith(links.guidePratique.list)
                        ? classes.active
                        : ''
                }
                onClick={() => handleClick(links.guidePratique.list)}
            />
            <BottomNavigationAction
                label={_('app.plan_de_soins')}
                icon={<img alt="Plan de soins" src={TreatmentPlanIcon} />}
                classes={actionClasses}
                className={
                    pathname.startsWith(links.plan.list) ? classes.active : ''
                }
                onClick={() => handleClick(links.plan.list)}
            />
            <BottomNavigationAction
                label={_('app.more')}
                icon={<img alt="Plus" src={MoreIcon} />}
                classes={actionClasses}
                className={
                    pathname.startsWith(links.more) || 
                    pathname.startsWith(links.drug.list) || 
                    pathname.startsWith(links.medicament.list) ? 
                    classes.active : ''
                }
                onClick={() => handleClick(links.more)}
            />
        </BottomNavigation>
    )
}

export default Bottombar
