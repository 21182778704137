import { createSelector } from 'reselect'

export class PostsSelector {
    static selectPosts(posts) {
        return posts
    }

    static selectPostsByType(posts, type) {
        if (posts.length > 0) return posts.filter((p) => p.menu === type)
        return posts
    }

    static selectPostBySlug(posts, slug) {
        return (Array.isArray(posts))? posts.find((d) => d.slug === slug):posts
    }
}

export const selectPosts = createSelector(
    (state) => state.posts,
    PostsSelector.selectPosts
)

export const selectPostsByType = createSelector(
    (state) => state.posts,
    (state, type) => type,
    PostsSelector.selectPostsByType
)

export const selectPostBySlug = createSelector(
    (state) => state.posts,
    (state, slug) => slug,
    PostsSelector.selectPostBySlug
)
