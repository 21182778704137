import PropTypes from 'prop-types'
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { _ } from 'helpers/lang'
import Layout from 'components/Layout/Layout'
import ProfileCard from './ProfileCard/ProfileCard'
import CheminCard from 'components/CheminCard/CheminCard'
import { selectProfileByNumber } from 'stores/profile/profile.selectors'
import profileActions from 'stores/profile/profile.actions'
import useStyles from './ProfileShow.styles'
import ProfileType from 'types/Profile.type'
import { displayDate } from 'utils/dates'
import { getStartDates } from 'utils/profil'
import {
    selectRisksByTroubleId,
    selectTroubleById,
} from 'stores/trouble/trouble.selectors'
import TroublePlanType from 'types/TroublePlan.type'
import { history } from 'stores/root-store'
import links from 'helpers/links'
import SpecChip from 'components/Spec/SpecChip'
import { selectDrugs } from 'stores/drug/drug.selectors'
import { selectMedicaments } from 'stores/medicament/medicament.selectors'
import DrugType from 'types/Drug.type'
import MedicamentType from 'types/Medicament.type'
import TroubleType from 'types/Trouble.type'
import PlanAccordions from 'components/PlanAccordions/PlanAccordions'

const mapStateToProps = (state, { match }) => {
    const profile = selectProfileByNumber(state, match.params.slug)
    const risks = profile
        ? selectRisksByTroubleId(state, profile.trouble_id)
        : []
    const trouble = profile
        ? selectTroubleById(state, profile.trouble_id)
        : null
    return {
        profile,
        trouble,
        risks,
        drugs: selectDrugs(state),
        medicaments: selectMedicaments(state),
    }
}

const mapDispatchToProps = {
    show: profileActions.show,
}

ProfileShow.propTypes = {
    profile: ProfileType,
    trouble: TroubleType,
    risks: PropTypes.arrayOf(TroublePlanType),
    drugs: PropTypes.arrayOf(DrugType),
    medicaments: PropTypes.arrayOf(MedicamentType),
    show: PropTypes.func.isRequired,
}

function ProfileShow({ profile, trouble, risks, drugs, medicaments, show }) {
    const classes = useStyles()

    const reloadProfile = () => show(profile.number)

    useEffect(() => {
        if (!profile) return
        reloadProfile()
    }, []) // empty deps array will make useEffect emulate componentDidMount()

    if (!profile || !risks) return null

    const drugList = profile.drugs.map(
        (id) => drugs.find((d) => d.id === id).name
    )

    const medicamentList = profile.medicaments.map(
        (id) => medicaments.find((d) => d.id === id).name
    )

    const hasChemins = () => profile.chemins && profile.chemins.length > 0
    const startDates = getStartDates(profile)
    return (
        <Layout title={_('app.profiles')}>
            <ProfileCard profile={profile} reloadProfile={reloadProfile}>
                <SpecChip
                    label="Type de consommateur"
                    color="yellow"
                    data={[profile.consommateur]}
                />
                <SpecChip
                    label="Types de consommation"
                    color="yellow"
                    data={drugList}
                />
                <SpecChip
                    label="Médicaments"
                    color="yellow"
                    data={medicamentList}
                />
                <SpecChip
                    label="Plan de soins"
                    color="yellow"
                    data={[trouble.name]}
                />
                <SpecChip
                    label="Programme"
                    color="yellow"
                    data={[profile.programme]}
                />
                <SpecChip label="Stade" color="yellow" data={[profile.stade]} />
            </ProfileCard>

            <div className={classes.inner}>
                <div className={classes.titleGroup}>
                    <Typography variant="h1" align="center" gutterBottom>
                        {_('profil.cheminTitle')}
                    </Typography>
                    <Typography paragraph align="center">
                        {_('profil.started_at')}{' '}
                        <strong>{displayDate(profile.start)}</strong>
                    </Typography>
                </div>

                {hasChemins() && (
                    <div className={classes.cardGroup}>
                        {profile.chemins.map((chemin, i) => {
                            const progress = 100 //getCheminProgress(chemin.semaines)
                            const handleClick = () =>
                                history.push(
                                    links.profile.phase(
                                        profile.number,
                                        chemin.phase
                                    )
                                )
                            return (
                                <CheminCard
                                    key={chemin.id}
                                    index={i}
                                    progress={progress}
                                    name={chemin.name}
                                    phase={chemin.phase}
                                    date={startDates[i]}
                                    onClick={handleClick}
                                />
                            )
                        })}
                    </div>
                )}

                <div className={classes.titleGroup}>
                    <Typography variant="h1" align="center" gutterBottom>
                        {_('profil.risquesTitle')}
                    </Typography>
                </div>

                <Box px={0.75}>
                    <PlanAccordions plans={risks} risk />
                </Box>
            </div>
        </Layout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileShow)
