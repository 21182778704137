import React, { useContext, useRef, useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import { ColorContext } from 'helpers/theme'
import AddictaSmall from 'components/Logo/AddictaSmall'
import SearchInput from 'components/SearchInput/SearchInput'
import useStyles from './Topbar.styles'
import ElevationScroll from './Topbar.helpers'
import ToggleButton from './ToggleButton/ToggleButton'
import TopbarMenu from './TopbarMenu/TopbarMenu'

const Topbar = (props) => {
    const {deactivateSearch} = props
    const { colors } = useContext(ColorContext)
    const classes = useStyles({ color: colors.topbarColor })
    const [openMenu, setOpenMenu] = useState(false)
    const ref = useRef()

    return (
        <div className={classes.wrapper} ref={ref}>
            <ElevationScroll {...props}>
                <AppBar classes={{ root: classes.appbar }}>
                    <Toolbar
                        variant="dense"
                        classes={{ root: classes.toolbar }}
                    >
                        <AddictaSmall className={classes.logo} />
                        <div className={classes.title}></div>
                        {(!deactivateSearch)&&<SearchInput />}
                        <ToggleButton
                            onClick={() => setOpenMenu(!openMenu)}
                            active={openMenu}
                        />
                    </Toolbar>

                    <TopbarMenu
                        opened={openMenu}
                        boundaryRef={ref}
                        closeMenu={() => setOpenMenu(false)}
                    />
                </AppBar>
            </ElevationScroll>
        </div>
    )
}

export default Topbar
