import { httpClient } from 'helpers/http'

const resource = '/medicament'

function list(filters) {
    if(filters!==null){
        let request = '?'
        for (const [key, value] of Object.entries(JSON.parse(filters))){
            request += `filter[${key}]=${value}&`
        }
        return httpClient.GET(`${resource}${(`${request.slice(0, -1)}`)}`)
    } else {
        return httpClient.GET(`${resource}`)
    }
}

function show(id) {
    return httpClient.GET(`${resource}/${id}`)
}

function create(medicament) {
    const data = JSON.stringify(medicament)
    return httpClient.POST(resource, { data })
}

function update(medicament) {
    const data = JSON.stringify(medicament)
    return httpClient.PUT(`${resource}/${medicament.id}`, { data })
}

function destroy(medicament) {
    return httpClient.DELETE(`${resource}/${medicament.id}`)
}

function search(term) {
    return httpClient.GET(`${resource}/search/${term}`)
}

const medicamentService = {
    list,
    show,
    create,
    update,
    destroy,
    search,
}

export default medicamentService
