import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import BaseButton from '@material-ui/core/Button'

import useStyles from './Button.styles'

Button.propTypes = {
    className: PropTypes.string,
    to: PropTypes.string,
    // https://material-ui.com/api/button/
    children: PropTypes.any,
    classes: PropTypes.object,
    color: PropTypes.oneOf(['primary', 'secondary', 'default', 'inherit']),
    component: PropTypes.elementType,
    disabled: PropTypes.bool,
    disableElevation: PropTypes.bool,
    disableFocusRipple: PropTypes.bool,
    disableRipple: PropTypes.bool,
    endIcon: PropTypes.node,
    startIcon: PropTypes.node,
    fullWidth: PropTypes.bool,
    size: PropTypes.oneOf(['large', 'medium', 'small']),
    variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
}

Button.defaultProps = {
    variant: 'contained',
    color: 'primary',
    size: 'medium',
    disableElevation: true,
}

function Button(props) {
    const history = useHistory()

    function handleClick(to) {
        return () => history.push(to)
    }

    const { children, to, className, ...rest } = props

    const classes = useStyles(props)

    return (
        <BaseButton
            className={clsx(classes.button, className)}
            onClick={handleClick(to)}
            {...rest}
        >
            {children}
        </BaseButton>
    )
}

export default Button
