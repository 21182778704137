import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(6, 0, 6),
        minHeight: '100vh',
        width: '100%',
        boxSizing: 'border-box',
        backgroundColor: ({ isGreyBackground }) =>
        isGreyBackground ? '#f0f0f0' : 'transparent',
    },
    '@global': {
        strong: {
            fontWeight: 'bold',
        },
    },
}))

export default useStyles
