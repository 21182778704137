import React, { useEffect, useState } from 'react'
import { selectPostsByType } from 'stores/post/post.selectors'
import postActions from 'stores/post/post.actions'
import PostLayout from 'components/PostLayout/PostLayout'
import links from 'helpers/links'
import PostList from 'components/PostList/PostList'
import { _ } from '../../helpers/lang'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
    return {
        posts: selectPostsByType(state, 'policy-procedure'),
    }
}

const mapDispatchToProps = {
    listPost: postActions.list,
}

function PoliciesProceduresList(props) {
    const { posts, listPost } = props
    const [isLoading, setIsLoading] = useState(true)
    const history = useHistory()
    const handleSearch = (e) => {
        history.push(links.politiquesProcedures.search(e))
    }
    useEffect(async () => {
        await listPost()
        setIsLoading(false)
    }, [setIsLoading])

    return (
        <PostLayout title={_('app.policies_procedures')} handleSearch={handleSearch} isLoading={isLoading} link={links.home}>
            <PostList
                posts={posts}
                link={links.politiquesProcedures}
            />
        </PostLayout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PoliciesProceduresList)
