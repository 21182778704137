import { httpClient } from 'helpers/http'

const resource = '/trouble'

function list(filters = undefined) {
    return httpClient.GET(resource + (filters || ''))
}

function show(id) {
    return httpClient.GET(`${resource}/${id}`)
}

function search(term) {
    return httpClient.GET(`${resource}/search/${term}`)
}

const troubleService = {
    list,
    show,
    search,
}

export default troubleService
