import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: theme.spacing(1, 1, 0, 0),
        borderRadius: 7,
        fontWeight: 'bold',
        backgroundColor: '#f1c651'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        padding: theme.spacing(2, 3, 2, 3),
        textAlign: 'center',
        [theme.breakpoints.up('xs')]: {
            width: '80%',
        },
        [theme.breakpoints.up('md')]: {
            width: '40%',
        },
    },
    tooltip: {
        fontSize: '16px',
        color: '#f1c651',
        fontWeight: 700,
        lineHeight: 1.2,
    }
}))

export default useStyles
