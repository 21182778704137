import React from 'react'
import { Grid } from '../../../node_modules/@material-ui/core/index'
import Layout from 'components/Layout/Layout'
import HomeContent from './HomeContent'
import PropTypes from 'prop-types'
import useStyles from './HomeContent.styles'
import SearchListInput from 'components/SearchInput/SearchListInput'
import PostType from '../../types/Post.type'
import { useHistory } from 'react-router-dom'
import links from 'helpers/links'
import Inner from 'components/Inner/Inner'
import { _ } from 'helpers/lang'

Home.propTypes = {
    posts: PropTypes.arrayOf(PostType),
}

function Home() {
    const classes = useStyles()
    const history = useHistory()
    const handleSearch = (e) => {
        history.push(links.post.search(e))
    }

    return (
        <Layout title={_('app.home')} hideNavHeader={true} deactivateSearch={true}>
            <div className={classes.header}>
                <SearchListInput
                    placeholder={`${_('app.By_keyword')}`}
                    onSearch={handleSearch}
                    isOnSubmitSearch={true}
                />
            </div>
            <HomeContent />
        </Layout>
    )
}

export default Home
