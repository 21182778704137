import drugService from 'stores/drug/drug.service'
import drugConstants from 'stores/drug/drug.constants'
import { history } from '../root-store'
import links from '../../helpers/links'
import alertActions from '../alert/alert.actions'

function list(filters = undefined) {
    function request(filters) {
        return { type: drugConstants.LIST_PENDING, filters }
    }

    function success(payload) {
        return { type: drugConstants.LIST_SUCCESS, payload }
    }

    function failure(error) {
        return { type: drugConstants.LIST_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request(filters))
        return drugService.list(filters).then(
            (data) => {
                dispatch(success(data))
                return true
            },
            (error) => {
                dispatch(failure(error))
                return false
            }
        )
    }
}

function show(id) {
    function request(id) {
        return { type: drugConstants.SHOW_PENDING, id }
    }

    function success(payload) {
        return { type: drugConstants.SHOW_SUCCESS, payload }
    }

    function failure(error) {
        return { type: drugConstants.SHOW_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request(id))
        return drugService.show(id).then(
            (data) => {
                dispatch(success(data))
                return true
            },
            (error) => {
                dispatch(failure(error))
                return false
            }
        )
    }
}

function search(term, redirect = false) {
    function request(id) {
        return { type: drugConstants.SEARCH_PENDING, id }
    }

    function success(payload) {
        return { type: drugConstants.SEARCH_SUCCESS, payload }
    }

    function failure(error) {
        return { type: drugConstants.SEARCH_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request(term))
        return drugService.search(term).then(
            (payload) => {
                dispatch(success(payload))
                if (redirect) history.push(links.post.search(term))
                return true
            },
            (error) => {
                dispatch(alertActions.error(error))
                dispatch(failure(error))
                return false
            }
        )
    }
}

const drugActions = {
    list,
    show,
    search,
}

export default drugActions
