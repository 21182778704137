import { httpClient } from 'helpers/http'

const resource = '/drug-category'

function list() {
    return httpClient.GET(resource)
}

function show(id) {
    return httpClient.GET(`${resource}/${id}`)
}

function create(drugCategory) {
    const data = JSON.stringify(drugCategory)
    return httpClient.POST(resource, { data })
}

function update(drugCategory) {
    const data = JSON.stringify(drugCategory)
    return httpClient.PUT(`${resource}/${drugCategory.id}`, { data })
}

function destroy(drugCategory) {
    return httpClient.DELETE(`${resource}/${drugCategory.id}`)
}

const drugCategoryService = {
    list,
    show,
    create,
    update,
    destroy,
}

export default drugCategoryService
