import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0, 0, 0),
    },
    resultDetail: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '16px 0'
    },
    categoryImage: {
        width: '50px',
    },
}))

export default useStyles
