import React from 'react'
import { useSelector } from 'react-redux'

import { history } from 'stores/root-store'
import links from 'helpers/links'

const mapState = (state) => ({ auth: state.auth })

const useAuth = (protect = false) => {
    const { auth } = useSelector(mapState)
    const user = auth ? auth.user : null
    const token = auth ? auth.token : null
    const isAuthenticated = !!user

    React.useEffect(() => {
        if (protect && !isAuthenticated) {
            history.push(links.login)
        }
    }, [isAuthenticated])

    return { isAuthenticated, user, token }
}

export default useAuth
