
export const convertFormToObjectValues = (formValues) => {
    const object = {};
    Object.keys(formValues).forEach(fieldKey => {
        const field = formValues[fieldKey];
        const keys = Object.keys(field);
        if(keys.length > 0){
            const values = keys.map(key => field[key] && key).filter(Boolean);
            if(values.length > 0){
                object[fieldKey]=values;
            }
        }
    })
    return object;
}