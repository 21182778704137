import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    Card: {
        borderRadius: 0,
        boxShadow: 'none',
        background: theme.palette.purple.light,
    },
    CardHeader: {
        paddingBottom: 0,
    },
    CardContent: {
        paddingTop: 0,
    },
}))

export default useStyles
