import React, { useContext } from 'react'
import useStyles from './LoadingContainer.styles'
import { CircularProgress } from '@material-ui/core'
import { ColorContext } from '../../helpers/theme'

export default function LoadingContainer({children, isLoading }) {
    const { colors } = useContext(ColorContext)
    const classes = useStyles({ color: colors.topbarColor, isLoading })

    return (
        <div className={classes.root}>
            <div className={classes.container}>{children}</div>
            {isLoading&&<span className={classes.spinnerWrapper}><CircularProgress className={classes.spinner}/></span>}
        </div>
    )
}
