import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    inner: {
        background: 'white',
        padding: theme.spacing(3),
    },
}))

export default useStyles
