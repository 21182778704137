import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import authStore from 'stores/auth/auth.reducer'
import postStore from 'stores/post/post.reducer'
import alertStore from 'stores/alert/alert.reducer'
import profileStore from 'stores/profile/profile.reducer'
import troubleStore from 'stores/trouble/trouble.reducer'
import drugStore from 'stores/drug/drug.reducer'
import medicamentStore from 'stores/medicament/medicament.reducer'
import drugCategoryStore from 'stores/drug-category/drug-category.reducer'
import medicamentCategoryStore from 'stores/medicament-category/medicament-category.reducer'
import widgetStore from 'stores/widget/widget.reducer'
import wayStore from './way/way.reducer'
import dependencyTypeStore from './dependencyType/dependencyType.reducer'
import clinicalObservationStore from './clinicalObservation/clinicalObservation.reducer'
import definitionStore from "./definition/definition.reducer";

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        auth: authStore,
        alerts: alertStore,
        profiles: profileStore,
        dependencyTypes: dependencyTypeStore,
        drugs: drugStore,
        drugCategories: drugCategoryStore,
        medicaments: medicamentStore,
        medicamentCategories: medicamentCategoryStore,
        posts: postStore,
        troubles: troubleStore,
        widgets: widgetStore,
        ways: wayStore,
        clinicalObservations:clinicalObservationStore,
        definitions:definitionStore
    })

export default createRootReducer
