import React from "react"
import Markdown from "components/Markdown/Markdown"
import MediaLink from "components/MediaLink/MediaLink"

function TextLinkMarkdown({text}){

    const transformStringToArray = (string) => {
        return string ? string.replaceAll('<p><a', '***')
            .replaceAll('</a></p>', '***')
            .split('***') : []
    }

    const containsFileExtension = (link) => {
        let isFileLink = false
        const fileTypes = ['.pdf', '.docx', '.pptx', '.xlsx', '/admin/elfinder/']
        fileTypes.forEach((fileType) => {
            if (link.indexOf(fileType) >= 0) {
                isFileLink = true
            }
        })
        return isFileLink
    }

    function stripHtml(html)
    {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    //Here we want to find a tag with a file extension link
    const displayMarkDownOrMediaLink = (string) => {
        return <Markdown source={string} />
    }

    const fuseArrayElementNotMediaLink = (array) => {
        let newArray = []
        let string = ''
        array.forEach((element) => {
            string = `${string}${element}`
        })
        if(string!==''){
            newArray.push(string)
        }
        return newArray
    }
    return (
        <div>
            {fuseArrayElementNotMediaLink(transformStringToArray(text))
            .map((string) => { return displayMarkDownOrMediaLink(string) })}
        </div>
    )
}

export default TextLinkMarkdown