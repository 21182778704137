import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    home: {
        backgroundColor: theme.palette.yellow.home,
        width: '100%',
        height: '120px',
        borderRadius: '8px',
        borderColor: theme.palette.yellow.contour,
        textTransform: 'none',
        border: '1px solid black'
    },
    icon: {
        fontSize: '2em',
        margin: '0.5em',
    },
}))

export default useStyles
