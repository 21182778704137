import { createSelector } from 'reselect'

export class ProfilesSelector {
    static selectProfiles(profiles) {
        return profiles
    }

    static selectProfileByNumber(profiles, number) {
        return profiles.find((d) => d.number === number)
    }
}

export const selectProfiles = createSelector(
    (state) => state.profiles,
    ProfilesSelector.selectProfiles
)

export const selectProfileByNumber = createSelector(
    (state) => state.profiles,
    (state, nunber) => nunber,
    ProfilesSelector.selectProfileByNumber
)
