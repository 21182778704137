import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: '100%',
        // margin: '12px auto 24px',
    },
}))

export default useStyles
