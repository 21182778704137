import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import authActions from 'stores/auth/auth.actions'
import alertActions from 'stores/alert/alert.actions'
import { isOnline } from 'helpers/data'
import { auth } from 'helpers/auth'
import LoginForm from './LoginForm/LoginForm'
import { HistoryType } from 'types/ReactRouter.type'

const mapStateToProps = (state, { history }) => {
    return { history }
}

const mapDispatchToProps = {
    error: alertActions.error,
    logout: authActions.logout,
    logoutAllDevices: authActions.logoutAllDevices,
    login: authActions.login,
}

const propTypes = {
    history: HistoryType.isRequired,
    error: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    logoutAllDevices: PropTypes.func.isRequired,
    requestLogoutAllDevices: PropTypes.bool,
}

const defaultProps = {
    requestLogoutAllDevices: false,
}
class Login extends React.Component {
    componentDidMount() {
        if (auth()) {
            if (this.props.requestLogoutAllDevices) {
                this.props.logoutAllDevices()
            } else {
                this.props.logout()
            }
        }
    }

    handleSubmit = ({ email, password }, { setSubmitting }) => {
        if (!isOnline()) window.location.reload() // in case the form is still used when offline

        if (email && password) {
            setSubmitting(true)

            this.props.login(email, password).then((success) => {
                if (!success) setSubmitting(false)
            })
        }
    }

    render() {
        return (
            <LoginForm
                error={this.props.error}
                handleSubmit={this.handleSubmit}
            />
        )
    }
}

Login.propTypes = propTypes
Login.defaultProps = defaultProps

export default connect(mapStateToProps, mapDispatchToProps)(Login)
