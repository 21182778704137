import { makeStyles } from '@material-ui/core/styles'
import { alpha } from '@material-ui/core/styles/colorManipulator'

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'hidden',
        padding: '0px',
        '&:last-child': {
            margin:'0px !important',
        }
    },
    h4:{
        fontWeight: '500',
        marginTop: '2em',
        fontWeight: 'bold'
    }
}))

export default useStyles
