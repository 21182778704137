import * as ReactRouterPropTypes from 'react-router-prop-types'

export const HistoryType = ReactRouterPropTypes.history
export const LocationType = ReactRouterPropTypes.location
export const MatchType = ReactRouterPropTypes.match
export const RouteType = ReactRouterPropTypes.route // for react-router-config

const ReactRouterType = {
    HistoryType,
    LocationType,
    MatchType,
    RouteType,
}

export default ReactRouterType
