import React from 'react'
import Typography from '@material-ui/core/Typography'

function HeadingRenderer({ level, children }) {
    return (
        <Typography paragraph variant={`h${level}`} component={`h${level}`}>
            {children}
        </Typography>
    )
}

export default HeadingRenderer
