import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    logoutButton: {
        backgroundColor: theme.palette.red.main,
        color: 'white',
        display:'flex',
        marginTop: '10px'
    },
    buttonContainer:{
        
    },
    passwordButton:{
        display:'flex'
    },
}))

export default useStyles
