import React from 'react'
import { _ } from 'helpers/lang'
import useStyles from './HomeContent.styles'
import Inner from 'components/Inner/Inner'
import links from 'helpers/links'
import { ReactComponent as PoliciesProceduresIcon } from '../../assets/svg/tasks-solid.svg'
import { ReactComponent as GuideDePratiqueIcon } from '../../assets/svg/guide-de-pratique.svg'
import { ReactComponent as ProgrammeCliniqueIcon } from '../../assets/svg/programme-clinique.svg'
import { ReactComponent as TreatmentPlanIcon } from '../../assets/svg/plan-de-soins.svg'
import { ReactComponent as DrugIcon } from '../../assets/svg/drugs.svg'
import { ReactComponent as MedicationIcon } from '../../assets/svg/medicaments.svg'
import HomeBox from '../../components/HomeBox/HomeBox'
import Grid from '@material-ui/core/Grid'

function HomeContent() {
    const classes = useStyles()

    return (
        <Inner>
            <Grid container className={classes.root} spacing={3} columns={3}>
                <Grid item xs={6}>
                    <HomeBox
                        label={_('app.policies_procedures')}
                        icon={<PoliciesProceduresIcon />}
                        to={links.politiquesProcedures.list}
                    />
                </Grid>

                <Grid item xs={6}>
                    <HomeBox
                        label={_('app.programme_clinique')}
                        icon={<ProgrammeCliniqueIcon />}
                        to={links.programmeClinique.list}
                    />
                </Grid>
                <Grid item xs={6}>
                    <HomeBox
                        label={_('app.guides_de_pratique')}
                        icon={<GuideDePratiqueIcon />}
                        to={links.guidePratique.list}
                    />
                </Grid>
                <Grid item xs={6}>
                    <HomeBox
                        label={_('app.plan_de_soins')}
                        icon={<TreatmentPlanIcon />}
                        to={links.plan.list}
                    />
                </Grid>
                <Grid item xs={6}>
                    <HomeBox
                        label={_('app.drugs')}
                        icon={<DrugIcon />}
                        to={links.drug.list}
                    />
                </Grid>
                <Grid item xs={6}>
                    <HomeBox
                        label={_('app.medications')}
                        icon={<MedicationIcon />}
                        to={links.medicament.list}
                    />
                </Grid>
            </Grid>
        </Inner>
    )
}

export default HomeContent
