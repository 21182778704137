import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: theme.spacing(6),
        width: theme.spacing(6),
        color: props.active ? 'white' : 'black',
        background: props.active ? 'black' : 'transparent',
    }),
}))

export default useStyles
