import PropTypes from 'prop-types'

const UserType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    admin: PropTypes.bool,
})

export default UserType
