import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    searchBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        background: 'white',
        padding: '10px',
        width: '100%',
    },
    inputDiv: {
        paddingLeft: '15px',
        paddingRight: '0px',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'left',
    },
    input: {
        width: '90%',
        '@media only screen and (min-width: 600px)': {
            width: '93%'
        },
        '@media only screen and (maxWidth: 390px)': {
            width: '88%'
        },
        height: '100%',
        display: 'flex', 
        justifyContent: 'right'
    },
    searchIconHeader: {
        transform: 'scale(1.3)',
    },
    headerDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    formContainer:{
        width: '100%',
    },
    root:{
        padding: theme.spacing(2, 2)
    },
    button:{
        backgroundColor: 'white', 
        border: '0px', 
        display: 'flex', 
        justifyContent: 'right', 
        alignItems: 'right',
        cursor: 'pointer'
    }

}))

export default useStyles
