import postConstants from 'stores/post/post.constants'

const initialState = []

// all state changes manipulations happen here
function postStore(state = initialState, { type, payload }) {
    switch (type) {
        case postConstants.SEARCH_SUCCESS:
        case postConstants.LIST_SUCCESS:
        case postConstants.SHOW_SUCCESS:
            return payload.data
        case postConstants.SEARCH_FAILURE:
            return {}
        // case postConstants.SHOW_SUCCESS:
        //     // adds the item with id has key in the items list state
        //     // using an object as the items list allows to overwrite the item with a newly fetched version of itself
        //     // whereas using an array would only push a duplicate version
        //     return { ...state, [payload.data.id]: payload.data }

        // case postConstants.CREATE_SUCCESS:
        // case postConstants.UPDATE_SUCCESS:
        //     return {
        //         ...state,
        //         [payload.data.id]: payload.data,
        //     }

        // case postConstants.DELETE_SUCCESS:
        //     // returns a copy of the state without the deleted item
        //     let newState = { ...state }
        //     delete newState[payload.id]
        //     return newState

        default:
            return state
    }
}

export default postStore
