import PropTypes from 'prop-types'

const TroublePlanType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    text_a: PropTypes.string.isRequired,
    text_b: PropTypes.string.isRequired,
    text_c: PropTypes.string.isRequired,
    text_d: PropTypes.string.isRequired,
    text_e: PropTypes.string.isRequired,
    argumentation: PropTypes.string.isRequired,
    is_risk: PropTypes.oneOf([0, 1]).isRequired,
    medical_prescription: PropTypes.string.isRequired,
})

export default TroublePlanType
