import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        paddingLeft: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            height: theme.spacing(6),
            width: '2px',
        },
        '&:last-child': {
            marginBottom: 0,
        },
    },
    chip: {
        margin: theme.spacing(1, 1, 0, 0),
        borderRadius: 7,
        fontWeight: 'bold',
        backgroundColor: '#f1c651'
    },
    emptyValue: {
        height: theme.spacing(4),
        fontWeight: 'bold',
        lineHeight: '2em',
    },
    text:{
        fontWeight:500,
        color: 'black'
    },
    tooltip: {
        fontSize: '16px',
        color: '#f1c651',
        fontWeight: 700,
        lineHeight: 1.2,
    }
}))

export default useStyles
