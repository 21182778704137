import React, { useState, useEffect } from "react";
import PostTypesEnum from 'constants/enums/PostTypesEnum'
import Layout from "components/Layout/Layout";
import LoadingContainer from "components/LoadingContainer/LoadingContainer";
import ListHeader from "components/ListHeader/ListHeader";
import PostSearchContent from "app/PostSearch/PostSearchContent/PostSearchContent";
import { connect } from 'react-redux'
import postActions from '../../stores/post/post.actions'
import { selectPosts } from '../../stores/post/post.selectors'
import { useHistory } from "react-router-dom";
import links from "helpers/links";

import { _ } from '../../helpers/lang'

const mapStateToProps = (state, { match }) => {
    const slug = match.params.slug
    return {
        slug: slug,
        posts: selectPosts(state),
    }
}

const mapDispatchToProps = {
    searchPost: postActions.search,
}

function ClinicalProgramSearch({ slug, posts, searchPost, }) {
    const [isLoading, setIsLoading] = useState(true)
    const programList = (Array.isArray(posts)) ? (posts.filter((p) => p.menu === PostTypesEnum.PROGRAMME)) : []
    const history = useHistory()

    useEffect(async() => {
        await searchPost(slug)
        setIsLoading(false)
    }, [slug])

    const handleSearch = (e) => {
        history.push(links.programmeClinique.search(e))
    }

    return (
        <Layout>
            <ListHeader 
                title={_('app.programme_clinique')} 
                onSearch={handleSearch}
                isOnSubmitSearch={true}
                initialInputValue={slug}
            />
            <LoadingContainer isLoading={isLoading}>
                <PostSearchContent
                    programs={programList}
                    isSinglePostSearch={true}
                />
            </LoadingContainer>
        </Layout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicalProgramSearch) 