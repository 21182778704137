import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import useStyles from './HomeBox.styles'
import {SvgIcon, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box';

HomeBox.propTypes = {
    to: PropTypes.string,
    icon: PropTypes.node,
    label: PropTypes.node,
}

function HomeBox(props) {
    const history = useHistory()

    function handleClick(to) {
        return () => history.push(to)
    }

    const { label, to, icon } = props

    const classes = useStyles()

    return (
        
        <Box
            component={'button'}
            className={classes.home}
            onClick={handleClick(to)}
        >
            <SvgIcon className={classes.icon}>{icon}</SvgIcon>
            <Typography variant={'h3'}>{label}</Typography>
        </Box>

    )
}

export default HomeBox
