import React from 'react'
import { useHistory } from 'react-router-dom'
import { _ } from 'helpers/lang'
import Button from './Button'

export const BackButton = () => {
    const history = useHistory()

    return <Button onClick={history.goBack}>{_('common.back')}</Button>
}

export default BackButton
