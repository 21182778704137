import widgetConstants from 'stores/widget/widget.constants'

const initialState = []

// all state changes manipulations happen here
function widgetStore(state = initialState, { type, payload }) {
    switch (type) {
        case widgetConstants.LIST_SUCCESS:
            return payload.data
        default:
            return state
    }
}

export default widgetStore
