import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import useStyles from './ToggleButton.styles'

ToggleButton.propTypes = {
    onClick: PropTypes.func.isRequired,
}

function ToggleButton(props) {
    const classes = useStyles(props)
    const className = clsx(classes.root)

    return (
        <div className={className} onClick={props.onClick}>
            <AccountCircleIcon />
        </div>
    )
}

export default ToggleButton
