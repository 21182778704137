import { createSelector } from 'reselect'

export class WidgetsSelector {
    static selectWidgets(widgets) {
        return widgets
    }

    static selectWidgetById(widgets, id) {
        return widgets.find((t) => t.id === id)
    }
}

export const selectWidgets = createSelector(
    (state) => state.widgets,
    WidgetsSelector.selectWidgets
)

export const selectWidgetById = createSelector(
    (state) => state.widgets,
    (state, id) => id,
    WidgetsSelector.selectWidgetById
)
