import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#f0f0f0',
        width: '100%',
    },
}))

export default useStyles
