import { createSelector } from 'reselect'
import groupBy from 'lodash/groupBy'

export class MedicamentsSelector {
    static selectMedicaments(medicaments) {
        return medicaments
    }

    static selectMedicamentsByCategory(medicaments, slug) {
        if (slug) {
            return medicaments.filter((d) => d.category.slug === slug)
        }

        return groupBy(medicaments, (d) => d.category.id)
    }

    static selectMedicamentBySlug(medicaments, slug) {
        return medicaments.find((d) => d.slug === slug)
    }
}

export const selectMedicaments = createSelector(
    (state) => state.medicaments,
    MedicamentsSelector.selectMedicaments
)

export const selectMedicamentsByCategory = createSelector(
    (state) => state.medicaments,
    (state, slug) => slug,
    MedicamentsSelector.selectMedicamentsByCategory
)

export const selectMedicamentBySlug = createSelector(
    (state) => state.medicaments,
    (state, slug) => slug,
    MedicamentsSelector.selectMedicamentBySlug
)
