import wayConstants from 'stores/way/way.constants'

const initialState = []

// all state changes manipulations happen here
function wayStore(state = initialState, { type, payload }) {
    switch (type) {
        case wayConstants.SEARCH_SUCCESS:
        case wayConstants.LIST_SUCCESS:
            return payload.data
        case wayConstants.SHOW_SUCCESS:
            return [...initialState, payload.data]
        case wayConstants.SEARCH_FAILURE:
            return []
        default:
            return state
    }
}

export default wayStore
