import PropTypes from 'prop-types'

const Post = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    fullname: PropTypes.string.isRequired,
    menu: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    depth: PropTypes.number.isRequired,
    is_leaf: PropTypes.bool.isRequired,
    text: PropTypes.string,
    updated_at: PropTypes.string.isRequired,
    objective: PropTypes.string,
    control_number: PropTypes.string,
    replaces: PropTypes.string,
    last_revision_date: PropTypes.string,
    scheduled_revision_date: PropTypes.string,
    effective_date: PropTypes.string,
    approved_by: PropTypes.string
}

const PostWithRelations = {
    children: PropTypes.arrayOf(PropTypes.shape(Post)),
    parent: PropTypes.shape(Post),
}

const PostType = PropTypes.shape(PostWithRelations)

export default PostType
