import React from 'react'
import Typography from '@material-ui/core/Typography'
import useStyles from './Spec.styles'
import SingleSpecChip from './SingleSpecChip'

SpecChip.propTypes = {

}

export default function SpecChip({ label, data, link, definitions }) {
    const classes = useStyles()

    const Chips = () => {
        if (!data || !data.length) {
            return <div className={classes.emptyValue}>-</div>
        }

        return <> {data.map((d) => (
                <SingleSpecChip
                    link={link} 
                    label={d.name}
                    keyName={d.name}
                    key={d.name}
                    dataKey={d.dataKey}
                    filterValue={d.filterValue}
                    definitions={definitions}
                />
        ))}</>
    }

    return (
        <div className={classes.root} >
            <Typography variant="h6">{label}</Typography>
                {Chips()}
        </div>
    )
}
