import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    dividerTitle: {
        margin: theme.spacing(1, 1),
        width: '100%',
        fontSize: '0.8em',
    },
}))

export default useStyles
