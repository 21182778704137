import { makeStyles } from '@material-ui/core/styles'
import { alpha } from '@material-ui/core/styles/colorManipulator'
const useStyles = makeStyles((theme) => ({
    tab: {
        color: theme.palette.green.main,
    },
    indicator: {
        backgroundColor: theme.palette.green.main,
    },
    details: {
        padding: theme.spacing(3, 2, 2),
        backgroundColor: alpha(theme.palette.white.main, 0.6),
        border: ({ risk }) =>
            `2px solid ${
                risk ? theme.palette.red[500] : theme.palette.grey[200]
            }`,
    },
}))

export default useStyles
