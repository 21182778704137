import React, { useState } from 'react'
import BarChartIcon from '@material-ui/icons/BarChart'
import { Chip, Paper, Typography } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import useStyles from './SingleWay.styles'
import { theme } from 'helpers/theme'
import {
    Line,
    LineChart,
    Tooltip,
    XAxis,
    YAxis,
    ResponsiveContainer,
} from 'recharts'
import { useHistory } from 'react-router-dom'
import { capitalizeFirstLetter } from 'utils/functions'
import { Tooltip as MuiTooltip } from '@material-ui/core';

export default function SingleWay({ way, modelType, link, definitions }) {
    const history = useHistory()
    const classes = useStyles()
    const [open, setOpen] = useState(false)

    const chartData = [
        { name: 'Début', value: way.start_effect },
        {
            name: 'Pic',
            value: way.peak_effect + way.start_effect,
        },
        { name: 'Durée', value: way.duration_effect },
    ]

    const handleClick = (e) => {
        history.push(link, { filters: { [way.dataKey]: way.filterValue }})
    }
    const definition = _.find(definitions, (definition) => definition.name.toLowerCase().trim().includes(way.name.toLowerCase().trim().slice(0, -1)));
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <MuiTooltip title={definition !== undefined ? definition.text : ''} classes={{ tooltip: classes.tooltip }}>
                    <Chip
                        classes={{ root: classes.chip }}
                        variant={'outlined'}
                        key={way.name}
                        size="small"
                        label={capitalizeFirstLetter(way.name)}
                        onClick={handleClick}
                    />
                </MuiTooltip>
                <BarChartIcon
                    onClick={() => setOpen(true)}
                    style={{
                        color: theme.palette.yellow.main,
                        fontSize: 30,
                        padding: 0,
                    }}
                />
            </div>

            <Modal
                className={classes.modal}
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-title"
                aria-describedby="singleway-modal-body"
            >
                <Paper className={classes.paper}>
                    <Typography
                        id="modal-title"
                        variant="h2"
                        style={{ marginBottom: 45 }}
                    >
                        Durée de l'action <br />
                        {way.name}
                    </Typography>
                    <ResponsiveContainer width="100%" height={200}>
                        <LineChart
                            id="singleway-modal-body"
                            width={730}
                            height={250}
                            data={chartData}
                        >
                            <XAxis dataKey="name" />
                            <Tooltip
                                formatter={(value) => [value, 'Heures']}
                            ></Tooltip>
                            <YAxis
                                dataKey="value"
                                label={{
                                    value: 'Heures',
                                    angle: -90,
                                    position: 'insideLeft',
                                }}
                            />
                            <Line type="monotone" dataKey="value" />
                        </LineChart>
                    </ResponsiveContainer>
                </Paper>
            </Modal>
        </>
    )
}
