import moment from 'moment'

import { _ } from 'helpers/lang'

export const required = (value) =>
    (value && value !== 'null' ? value.toString().trim() : false)
        ? undefined
        : _('validation.required')

export const maxLength = (max) => (value) =>
    value && value.length > max ? _('validation.maxLength', { max }) : undefined

export const minLength = (min) => (value) =>
    value && value.length < min ? _('validation.minLength', { min }) : undefined

export const exactLength = (target) => (value) =>
    value && value.length !== target
        ? _('validation.exactLength', { target })
        : undefined

export const maxArrayLength = (max) => (value) =>
    value && value.length > max
        ? _('validation.maxArrayLength', { max })
        : undefined

export const minArrayLength = (min) => (value) =>
    value && value.length < min
        ? _('validation.minArrayLength', { min })
        : undefined

export const number = (value) =>
    value && Number.isNaN(Number(value)) ? _('validation.number') : undefined

export const noNumber = (value) =>
    value && /\d/i.test(value) ? _('validation.noNumber') : undefined

export const minValue = (min) => (value) =>
    value && value < min ? _('validation.minValue', { min }) : undefined

export const maxValue = (max) => (value) =>
    value && value > max ? _('validation.maxValue', { max }) : undefined

export const email = (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? _('validation.email')
        : undefined

export const tooYoung = (value) =>
    value && value < 13 ? _('validation.tooYoung') : undefined

export const alphaNumeric = (value) =>
    value && /[^a-zA-Z0-9]/i.test(value)
        ? _('validation.alphaNumeric')
        : undefined

export const phoneNumber = (value) =>
    value && !/^\d{10}$/i.test(value) ? _('validation.phoneNumber') : undefined

export const password = (value) =>
    value && !/^(?=.)(?=.*\d).{8,}$/i.test(value)
        ? _('validation.password')
        : undefined

export const passwordMatch = (target) => (value) =>
    value && target !== value ? _('validation.passwordMatch') : undefined

export const passwordDifferent = (target) => (value) =>
    value && target === value ? _('validation.passwordDifferent') : undefined

export const latitude = (value) =>
    value &&
    !/^(\+|-)?(?:90(?:(?:\.0{1,8})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,8})?))$/i.test(
        value
    )
        ? _('validation.latitude')
        : undefined

export const longitude = (value) =>
    value &&
    !/^(\+|-)?(?:180(?:(?:\.0{1,8})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,8})?))$/i.test(
        value
    )
        ? _('validation.longitude')
        : undefined

export const dateAfter = (start) => (date, format) => {
    const end = typeof date === 'object' ? moment(date) : moment(date, format) // use format to parse if date is not a Date object

    return date && end.isBefore(start, 'day')
        ? _('validation.dateAfter')
        : undefined
}

export const postalCode = (value) =>
    value &&
    !/[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ]\s[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/i.test(
        value
    )
        ? _('validation.postalCode')
        : undefined

export const date = (value) =>
    value &&
    !/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/i.test(value)
        ? _('validation.date')
        : undefined

export const maxChoice = (count) => (value) =>
    value && value.length > count
        ? _('validation.maxChoice', { count })
        : undefined

export const minChoice = (count) => (value) =>
    value && value.length < count
        ? _('validation.minChoice', { count })
        : undefined

export const differentThan = (target) => (value) =>
    value && target === value ? _('validation.differentThan') : undefined

// validation groups
export const fields = {
    name: [required, noNumber, maxLength(75)],
    email: [required, email],
    password: [required, password],
}

const validation = {
    fields,
    required,
    maxLength,
    minLength,
    exactLength,
    maxArrayLength,
    minArrayLength,
    number,
    noNumber,
    minValue,
    maxValue,
    email,
    tooYoung,
    alphaNumeric,
    phoneNumber,
    passwordMatch,
    passwordDifferent,
    latitude,
    longitude,
    dateAfter,
    postalCode,
    date,
    maxChoice,
    minChoice,
    differentThan,
}

export default validation
