export const isDataExpired = () => {
    const now = window.moment().format('X')
    const updated_at = JSON.parse(localStorage.getItem('updated_at'))
    const expires_at = updated_at + 60 * 5

    return now >= expires_at
}

export const isOnline = () => {
    const offline = localStorage.getItem('offline') === 'true'
    return !offline
}
