import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
        position: 'relative',
        backgroundColor: theme.palette.primary.light,
    },
}))

export default useStyles
