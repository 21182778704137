import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Layout from 'components/Layout/Layout'
import {selectTroubleBySlug} from 'stores/trouble/trouble.selectors'
import troubleActions from 'stores/trouble/trouble.actions'
import troubleTabs from 'constants/lists/trouble-tabs'
import Inner from 'components/Inner/Inner'
import PlanAccordions from 'components/PlanAccordions/PlanAccordions'
import TabPanel from 'components/TabPanel/TabPanel'
import NavButton from 'components/Button/NavButton/NavButton'
import {_} from '../../helpers/lang'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import AccordionWithScroll from 'components/Accordions/AccordionWithScroll'
import Markdown from 'components/Markdown/Markdown'

const mapStateToProps = (state, {match}) => {
    return {
        slug: match.params.slug,
        trouble: selectTroubleBySlug(state, match.params.slug),
        searchTerm: match.params.searchTerm
    }
}

const mapDispatchToProps = {
    showPlan: troubleActions.show,
}

function PlanShow({slug, trouble, showPlan, searchTerm}) {
    const [expanded, setExpanded] = React.useState(false)
    const handleExpand = (panel, isExpanded) => (
        setExpanded(isExpanded ? panel : false)
    )
    const [tab, setTab] = useState(0)
    const handleChange = (e, v) => setTab(v)
    const [isLoading, setIsLoading] = useState(true)
    let plans = []

    useEffect(async () => {
        await showPlan(slug)
        setIsLoading(false)
    }, [])

    if (trouble !== undefined) {
        plans = trouble.plans
        plans = plans.sort((a, b) => String(a.name).toLowerCase() > String(b.name).toLowerCase() ? 1 : -1)
    }

    const changeDefinitionObjectToTextString = (definitionArray) => {
        let definitionText = ''
        Array.isArray(definitionArray) && definitionArray.forEach((definition) => {
            definitionText += `###### ${definition.name} 
            \n${definition.text}
            \n`
        })

        return definitionText
    }

    function formatTrouble() {
        if (trouble) {
            if (searchTerm !== '' && searchTerm !== undefined) {
                if (trouble && trouble.definition !== null && trouble.lexicon !== undefined)
                    trouble.definition = trouble.definition.replace(new RegExp(searchTerm, 'gi'), '<mark>' + searchTerm + '</mark>');

                if (trouble && trouble.lexicon !== null && trouble.lexicon !== undefined)
                    trouble.lexicon = trouble.lexicon.replace(new RegExp(searchTerm, 'gi'), '<mark>' + searchTerm + '</mark>');

                if (trouble && trouble.critere !== null && trouble.lexicon !== undefined)
                    trouble.critere = trouble.critere.replace(new RegExp(searchTerm, 'gi'), '<mark>' + searchTerm + '</mark>');

                if (trouble && trouble.biological !== null && trouble.lexicon !== undefined)
                    trouble.biological = trouble.biological.replace(new RegExp(searchTerm, 'gi'), '<mark>' + searchTerm + '</mark>');

                if (trouble && trouble.psychological !== null && trouble.lexicon !== undefined)
                    trouble.psychological = trouble.psychological.replace(new RegExp(searchTerm, 'gi'), '<mark>' + searchTerm + '</mark>');

                if (trouble && trouble.social !== null && trouble.lexicon !== undefined)
                    trouble.social = trouble.social.replace(new RegExp(searchTerm, 'gi'), '<mark>' + searchTerm + '</mark>');
            }
        }
        return trouble;
    }


    function formatPlans() {

        if (plans) {
            plans.forEach((postText) => {

                if (searchTerm !== '' && searchTerm !== undefined) {
                    if (postText && postText.argumentation !== null) {
                        postText.argumentation = postText.argumentation.replace(new RegExp(searchTerm, 'gi'), '<mark>' + searchTerm + '</mark>');
                    }
                    if (postText && postText.medical_prescription !== null) {
                        postText.medical_prescription = postText.medical_prescription.replace(new RegExp(searchTerm, 'gi'), '<mark>' + searchTerm + '</mark>');
                    }
                    if (postText && postText.text_a !== null) {
                        postText.text_a = postText.text_a.replace(new RegExp(searchTerm, 'gi'), '<mark>' + searchTerm + '</mark>');
                    }
                    if (postText && postText.text_b !== null) {
                        postText.text_b = postText.text_b.replace(new RegExp(searchTerm, 'gi'), '<mark>' + searchTerm + '</mark>');
                    }
                    if (postText && postText.text_c !== null) {
                        postText.text_c = postText.text_c.replace(new RegExp(searchTerm, 'gi'), '<mark>' + searchTerm + '</mark>');
                    }
                    if (postText && postText.text_d !== null) {
                        postText.text_d = postText.text_d.replace(new RegExp(searchTerm, 'gi'), '<mark>' + searchTerm + '</mark>');
                    }
                    if (postText && postText.text_e !== null) {
                        postText.text_e = postText.text_e.replace(new RegExp(searchTerm, 'gi'), '<mark>' + searchTerm + '</mark>');
                    }
                }
            })
        }
        return plans;
    }

    trouble = formatTrouble();
    return (
        <Layout title={_('app.plan_de_soins')}>
            {isLoading || trouble === undefined ? (
                <LoadingSpinner style={{marginTop: '5%'}}/>
            ) : (
                <div>
                    <div style={{backgroundColor: '#f0f0f0'}}>
                        <Typography variant="h4">
                            <NavButton title={trouble.name}/>
                        </Typography>
                    </div>
                    <Tabs
                        value={tab}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="secondary"
                        variant="scrollable"
                        scrollButtons="on"
                    >
                        {troubleTabs.map((tab, index) => (
                            <Tab key={index} label={tab}/>
                        ))}
                    </Tabs>

                    <Inner>
                        <TabPanel value={tab} index={0}>
                            <AccordionWithScroll
                                key={1}
                                index={1}
                                expanded={expanded === _('plan.Definition')}
                                title={_('plan.Definition')}
                                onChange={handleExpand}
                                headerSize={120}
                            >
                                <Markdown source={trouble.definition}/>
                            </AccordionWithScroll>
                            <AccordionWithScroll
                                key={2}
                                index={2}
                                expanded={expanded === _('plan.Lexicon')}
                                title={_('plan.Lexicon')}
                                onChange={handleExpand}
                                headerSize={120}
                            >
                                <Markdown
                                    source={(trouble.lexicon) && changeDefinitionObjectToTextString(trouble.lexicon)}/>
                            </AccordionWithScroll>
                            <AccordionWithScroll
                                key={3}
                                index={3}
                                expanded={expanded === _('plan.Criteria')}
                                title={_('plan.Criteria')}
                                onChange={handleExpand}
                                headerSize={120}
                            >
                                <Markdown source={trouble.critere}/>
                            </AccordionWithScroll>
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            <AccordionWithScroll
                                key={4}
                                index={4}
                                expanded={expanded === _('plan.Biological')}
                                title={_('plan.Biological')}
                                onChange={handleExpand}
                                headerSize={120}
                            >
                                <Markdown source={trouble.biological}/>
                            </AccordionWithScroll>
                            <AccordionWithScroll
                                key={5}
                                index={5}
                                expanded={expanded === _('plan.Psychological')}
                                title={_('plan.Psychological')}
                                onChange={handleExpand}
                                headerSize={120}
                            >
                                <Markdown source={trouble.psychological}/>
                            </AccordionWithScroll>
                            <AccordionWithScroll
                                key={6}
                                index={6}
                                expanded={expanded === _('plan.Social')}
                                title={_('plan.Social')}
                                onChange={handleExpand}
                                headerSize={120}
                            >
                                <Markdown source={trouble.social}/>
                            </AccordionWithScroll>
                        </TabPanel>
                        <TabPanel value={tab} index={2}>
                            <div style={{'@media only screen and (maxWidth: 600px)': {padding: '8px'}}}>
                                {formatPlans().map((plan) => {
                                    if (plan.is_risk === 0) {
                                        return (
                                            <div style={{marginTop: "12px"}}>
                                                <PlanAccordions plans={[plan]} onExpand={handleExpand}
                                                                expanded={expanded} searchTerm={searchTerm}/>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div style={{marginTop: "12px"}}>
                                                <PlanAccordions plans={[plan]} onExpand={handleExpand}
                                                                expanded={expanded} searchTerm={searchTerm} risk/>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </TabPanel>


                    </Inner>
                </div>
            )}
        </Layout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanShow)
