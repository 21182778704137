import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'hidden',
    },
    summary: {
        backgroundColor: theme.palette.primary.light,
    },
    // details: {
    //     padding: theme.spacing(1, 2, 2),
    //     backgroundColor: 'white',
    // },
    details: {
        position: 'relative',
        padding: theme.spacing(1),
        backgroundColor: 'white',
        border: () => `2px solid ${theme.palette.grey[200]}`,
        '& .MuiButton-root': {
            maxWidth: 'calc(100% - 1rem)',
        },
    },
    actionContainer: {
        position: 'relative',
    },
}))

export default useStyles
