import React from 'react'
import { Switch, Route } from 'react-router-dom'

import PrivateRoute from 'components/PrivateRoute/PrivateRoute'

import Home from './Home/Home'
import More from './More/More'
import Login from './Login/Login'
import Account from './Account/Account'
import ResetPassword from './ResetPassword/ResetPassword'
import ForgotPassword from './ForgotPassword/ForgotPassword'
import NotFound from './NotFound/NotFound'

import ProfileList from './ProfileList/ProfileList'
import ProfileForm from './ProfileForm/ProfileForm'
import ProfileShow from './ProfileShow/ProfileShow'
import ProfileShowPhase from './ProfileShowPhase/ProfileShowPhase'

import DrugList from './Drug/DrugList'
import DrugShow from './Drug/DrugShow'

import MedicamentList from './Medication/MedicationList'
import MedicamentShow from './Medication/MedicamentShow'

import PlanShow from './Plan/PlanShow'
import PlanList from './Plan/PlanList'
import PlanSearch from './Plan/PlanSearch'

import GuidePratiqueList from './GuidePratique/GuidePratiqueList'
import GuidePratiqueShow from './GuidePratique/GuidePratiqueShow'
import PracticalGuideSearch from './GuidePratique/PracticalGuideSearch'

import PostSearch from './PostSearch/PostSearch'
import PostShow from './PostShow/PostShow'

import ProgrammeCliniqueList from './ClinicalProgram/ProgrammeCliniqueList'
import ClinicalProgramShow from './ClinicalProgram/ClinicalProgramShow'
import ClinicalProgramSearch from './ClinicalProgram/ClinicalProgramSearch'

import PoliciesProceduresShow from './PoliciesProcedures/PoliciesProceduresShow'
import PoliciesProceduresList from './PoliciesProcedures/PoliciesProceduresList'
import PoliciesProceduresSearch from './PoliciesProcedures/PoliciesProceduresSearch'

// PROPS DE ROUTE
// path
// exact
// strict
// sensitive
// component

// PROPS DE PRIVATEROUTE
// protect          utiliser la PrivateRoute qui vérifie que le user est authentifié
// inverse          inverse les conditions - s'assure que le user n'est PAS authentifié
// redirect         quand `protect` est `true`, va rediriger vers `/` ou `redirectPath` si le user est pas authentifié
// redirectPath     override le path à rediriger lorsque `protect` et `redirect` sont true
// message          `false` ou `string` - le message à afficher lors d'une redirection

export const routeConfig = [
    // AUTH & PASSWORDS
    {
        path: '/login',
        exact: true,
        protect: true,
        inverse: true,
        redirect: true,
        redirectPath: '/',
        message: false,
        component: Login,
    },
    {
        path: '/logout',
        exact: true,
        redirect: true,
        message: false,
        component: Login,
    },
    {
        path: '/logout-all-devices',
        exact: true,
        component: (props) => <Login requestLogoutAllDevices {...props} />,
    },
    {
        path: '/password/forgot',
        exact: true,
        component: ForgotPassword,
    },
    {
        path: '/password/reset/:token/:email',
        exact: true,
        component: ResetPassword,
    },

    // PAGES INDIVIDUELLES
    {
        path: '/',
        exact: true,
        protect: true,
        redirect: true,
        component: Home,
        breadcrumb: 'Accueil',
    },
    {
        path: '/account',
        protect: true,
        exact: true,
        component: Account,
    },
    {
        path: '/plus',
        protect: true,
        exact: true,
        component: More,
    },

    // DROGUES
    {
        path: '/drugs',
        exact: true,
        protect: true,
        redirect: true,
        component: DrugList,
        breadcrumb: 'Drugs',
    },
    {
        path: '/drugs/:slug',
        exact: true,
        protect: true,
        redirect: true,
        component: DrugShow,
    },

    // MEDICAMENTS
    {
        path: '/medicaments',
        exact: true,
        protect: true,
        redirect: true,
        component: MedicamentList,
        breadcrumb: 'Médicaments',
    },
    {
        path: '/medicaments/:slug',
        exact: true,
        protect: true,
        redirect: true,
        component: MedicamentShow,
    },

    // PLANS
    {
        path: '/plans',
        exact: true,
        protect: true,
        redirect: true,
        component: PlanList,
        breadcrumb: 'Plan de soins',
    },
    {
        path: '/plans/:slug',
        exact: true,
        protect: true,
        redirect: true,
        component: PlanShow,
    },
    {
        path: '/plans/:slug/:searchTerm',
        exact: true,
        protect: true,
        redirect: true,
        component: PlanShow,
    },
    {
        path: '/plans/search/:slug',
        exact: true,
        protect: true,
        redirect: true,
        component: PlanSearch,
    },
    // POST
    {
        path: '/posts',
        exact: true,
        protect: true,
        redirect: true,
        component: Home,
        breadcrumb: 'Articles',
    },
    {
        path: '/posts/:slug',
        exact: true,
        protect: true,
        redirect: true,
        component: PostShow,
    },
    {
        path: '/posts/search/:slug',
        exact: true,
        protect: true,
        redirect: true,
        component: PostSearch,
    },

    // GUIDE PRATIQUES
    {
        path: '/guides-de-pratique',
        exact: true,
        protect: true,
        redirect: true,
        component: GuidePratiqueList,
        breadcrumb: 'Guides de pratique',
    },
    {
        path: '/guides-de-pratique/:slug',
        exact: true,
        protect: true,
        redirect: true,
        component: GuidePratiqueShow,
    },
    {
        path: '/guides-de-pratique/:slug/:searchTerm',
        exact: true,
        protect: true,
        redirect: true,
        component: GuidePratiqueShow,
    },
    {
        path: '/guides-de-pratique/search/:slug',
        exact: true,
        protect: true,
        redirect: true,
        component: PracticalGuideSearch,
    },
    

    // PROGRAMME CLINIQUE
    {
        path: '/programme-clinique',
        exact: true,
        protect: true,
        redirect: true,
        component: ProgrammeCliniqueList,
        breadcrumb: 'Guides de pratique',
    },
    {
        path: '/programme-clinique/:slug',
        exact: true,
        protect: true,
        redirect: true,
        component: ClinicalProgramShow,
    },
    {
        path: '/programme-clinique/:slug/:searchTerm',
        exact: true,
        protect: true,
        redirect: true,
        component: ClinicalProgramShow,
    },
    {
        path: '/programme-clinique/search/:slug',
        exact: true,
        protect: true,
        redirect: true,
        component: ClinicalProgramSearch,
    },

    // PROFILES
    {
        path: '/profils',
        exact: true,
        protect: true,
        redirect: true,
        component: ProfileList,
    },
    {
        path: '/profils/new',
        exact: true,
        protect: true,
        redirect: true,
        component: ProfileForm,
    },
    {
        path: '/profils/edit/:slug',
        exact: true,
        protect: true,
        redirect: true,
        component: ProfileForm,
    },
    {
        path: '/profils/show/:slug',
        exact: true,
        protect: true,
        redirect: true,
        component: ProfileShow,
    },
    {
        path: '/profils/show/:slug/phase/:phase?',
        protect: true,
        redirect: true,
        component: ProfileShowPhase,
    },

    // Policies & Procedures
    {
        path: '/politiques-et-procedures/',
        exact: true,
        protect: true,
        redirect: true,
        component: PoliciesProceduresList,
        breadcrumb: 'Politiques et procédures',
    },
    {
        path: '/politiques-et-procedures/:slug',
        exact: true,
        protect: true,
        redirect: true,
        component: PoliciesProceduresShow,
    },
    {
        path: '/politiques-et-procedures/:slug/:searchTerm',
        exact: true,
        protect: true,
        redirect: true,
        component: PoliciesProceduresShow,
    },
    {
        path: '/politiques-et-procedures/search/:slug',
        exact: true,
        protect: true,
        redirect: true,
        component: PoliciesProceduresSearch,
    },

    // NOT FOUND
    {
        path: '/not-found',
        exact: true,
        component: NotFound,
    },
    {
        component: NotFound,
    },
]

function RouteWithSubRoutes(props) {
    const { protect, routes, component: Component, ...restProps } = props

    const R = protect ? PrivateRoute : Route

    return (
        <R
            {...restProps}
            render={(renderProps) => (
                // pass the sub-routes down to keep nesting
                <Component {...renderProps} routes={routes} />
            )}
        />
    )
}

export const Routes = () => (
    <Switch>
        {routeConfig.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
        ))}
    </Switch>
)

export default Routes
