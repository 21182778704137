import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    inner: {
        background: 'white',
        padding: theme.spacing(0, 3, 3),
    },
    titleGroup: {
        padding: theme.spacing(5, 0, 3),
    },
    cardGroup: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        maxWidth: 1000,
        margin: 'auto',
    },
}))

export default useStyles
