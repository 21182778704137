import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    Card: {
        borderRadius: '4px',
        boxShadow: 'none',
    },
    CardHeader: {
        paddingBottom: 0,
    },
    CardContent: {
        paddingTop: 0,
        backgroundColor: theme.palette.primary.light,
    },
}))

export default useStyles
