import { httpClient } from 'helpers/http'

const resource = '/post'

function list(filters = undefined) {
    return httpClient.GET(resource + (filters || ''))
}

function show(id) {
    return httpClient.GET(`${resource}/${id}`)
}

function search(term) {
    return httpClient.GET(`${resource}/search/${term}`)
}

function create(post) {
    const data = JSON.stringify(post)
    return httpClient.POST(resource, { data })
}

function update(post) {
    const data = JSON.stringify(post)
    return httpClient.PUT(`${resource}/${post.id}`, { data })
}

function destroy(post) {
    return httpClient.DELETE(`${resource}/${post.id}`)
}

const postService = {
    list,
    show,
    search,
    create,
    update,
    destroy,
}

export default postService
