import React from 'react'
import { createTheme } from '@material-ui/core/styles'
import MuiGrey from '@material-ui/core/colors/grey'

const grey = {
    ...MuiGrey,
    light: '#f0f0f0',
}
const blue = {
    main: '#5bc2e8',
    light: '#d4edf9 ',
}

const purple = {
    main: '#a194ee',
    light: '#d4cef6',
}

const green = {
    main: '#b7bf0e',
    light: '#f4f5db',
}

const yellow = {
    main: '#f1c651',
    home: '#fdf6e2',
    contour: '#F8E5AF',
    light: '#fff1cc',
}

const pink = {
    main: '#f0817f',
    light: '#fbd9d9',
}

const white = {
    main: '#FFF',
    light: '#FFF',
}

const red = {
    main: '#EB0000'
}

export const cycleColors = (number) => {
    const colors = ['yellow', 'pink', 'blue', 'green', 'purple']
    const l = colors.length
    number = number > l - 1 ? number - l : number // cycle thru colors
    return number >= 0 ? colors[number] : colors[0]
}

export const theme = createTheme({
    typography: {
        fontFamily: [
            'Montserrat',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),

        h1: {
            fontSize: 20,
            fontWeight: 'bold',
            lineHeight: '1.3em',
        },
        h2: {
            fontSize: 18,
            fontWeight: 'bold',
            lineHeight: '1.3em',
        },
        h3: {
            fontSize: 16,
            fontWeight: 'normal',
            lineHeight: '1.3em',
        },
        h4: {
            fontSize: 15,
            fontWeight: 'bold',
            lineHeight: '1.3em',
        },
        h5: {
            fontSize: 12,
            fontWeight: 'bold',
            lineHeight: '1.3em',
            textTransform: 'uppercase',
        },
        h6: {
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1.3em',
        },
        body1: {
            fontSize: 14,
            lineHeight: '1.6em',
        },
    },
    palette: {
        primary: grey,
        secondary: yellow,
        blue,
        purple,
        green,
        yellow,
        pink,

        grey,
        white,
        red,
        // 'programme-clinique': blue,
        // medicament: purple,
        // drug: green,
        // 'plan-de-soins': yellow,
        // 'guides-de-pratique': pink,
    },
    overrides: {
        MuiButton: {
            containedPrimary: {
                color: 'white',
            },
        },
        MuiTabs: {
            root: {
                backgroundColor: grey[800],
                paddingTop: 8,
                paddingBottom: 8,
            },
        },
        MuiTab: {
            root: {
                maxWidth: '33vw',
                minWidth: 0,
                fontSize: 12,
                lineHeight: 1.3,
                color: 'white',
            },
            textColorSecondary: {
                color: 'white',
            },
            textColorInherit: {
                color: 'white',
            },
        },
        MuiTabScrollButton: {
            root: {
                width: 25,
                color: 'white',
            },
        },
        MuiAccordion: {
            root: {
                boxShadow: 'none',
                backgroundColor: grey.light,
                border: 'none',
                marginBottom: 16,
                '&:before': {
                    content: 'none',
                },
            },
        },
        MuiAccordionSummary: {
            root: {
                '&$expanded': {
                    minHeight: 0,
                },
            },
            content: {
                '&$expanded': {
                    margin: '12px 0',
                },
            },
        },
        MuiChip: {
            outlined: {
                color: yellow.main,
                border: `1px solid ${yellow.main}`,
            },
        },
        props: {
            MuiAccordion: {
                square: true,
            },
        },
    },
})

export const ColorContext = React.createContext({
    colors: { topbarColor: 'yellow', bgColor: 'grey' },

    setBgColor: () => {},
})
