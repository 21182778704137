import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

import { _ } from 'helpers/lang'
import links from 'helpers/links'
import Button from 'components/Button/Button'
import useStyles from './ForgotPasswordForm.styles'
import validate from './ForgotPasswordForm.helpers'

ForgotPasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
}

function ForgotPasswordForm({ handleSubmit }) {
    const classes = useStyles()

    return (
        <Container className={classes.container} component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Typography variant="h1">
                    {_('auth.forgotPasswordTitle')}
                </Typography>

                <Formik
                    initialValues={{ email: '' }}
                    validate={validate}
                    onSubmit={handleSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                        } = props

                        const disabled = isSubmitting

                        return (
                            <Form className={classes.form}>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label={_('field.email')}
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.email && touched.email}
                                    helperText={
                                        errors.email && touched.email
                                            ? errors.email
                                            : undefined
                                    }
                                />

                                <div className={classes.buttonlist}>
                                    <Button type="submit" disabled={disabled}>
                                        {_('auth.forgotPasswordButton')}
                                    </Button>
                                </div>

                                <Grid container>
                                    <Grid item xs>
                                        <Link
                                            className={classes.link}
                                            to={links.login}
                                        >
                                            {_('auth.loginButton')}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </Container>
    )
}

export default ForgotPasswordForm
