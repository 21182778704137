import { httpClient } from 'helpers/http'

const resource = '/clinical_observations'

function list() {
    return httpClient.GET(`${resource}`)
}

function show(id) {
    return httpClient.GET(`${resource}/${id}`)
}

function create(clinicalObservation) {
    const data = JSON.stringify(clinicalObservation)
    return httpClient.POST(resource, { data })
}

function update(clinicalObservation) {
    const data = JSON.stringify(clinicalObservation)
    return httpClient.PUT(`${resource}/${clinicalObservation.id}`, { data })
}

function destroy(clinicalObservation) {
    return httpClient.DELETE(`${resource}/${clinicalObservation.id}`)
}

function search(term) {
    return httpClient.GET(`${resource}/search/${term}`)
}

const clinicalObservationService = {
    list,
    show,
    create,
    update,
    destroy,
    search,
}

export default clinicalObservationService
