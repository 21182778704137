import React from 'react'
import PropTypes from 'prop-types'
import MuiAvatar from '@material-ui/core/Avatar'

import AvatarM from 'assets/svg/gender/m.svg'
import AvatarF from 'assets/svg/gender/f.svg'
// import useStyles from './Avatar.styles'

Avatar.propTypes = {
    gender: PropTypes.oneOf(['f', 'm']),
}

Avatar.defaultProps = {
    gender: 'f',
}

const iconList = {
    f: AvatarF,
    m: AvatarM,
}

export default function Avatar({ gender }) {
    // const classes = useStyles()
    return <MuiAvatar alt={gender} src={iconList[gender]} />
}
