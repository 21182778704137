import { makeStyles } from '@material-ui/core/styles'
import { alpha } from '@material-ui/core/styles/colorManipulator'

const useStyles = makeStyles((theme) => ({
    root:{
        backgroundColor: "#f0f0f0", 
        padding: "2px", 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "space-between",
        marginBottom: "10px"
    },
    leftPart:{
        display: "flex", 
        justifyContent: "flex-start", 
        alignItems: "center",
    },
    rightPart:{
        display: "flex", 
        justifyContent: "flex-end", 
        alignItems: "center"
    },
    leftIcon:{
        marginLeft: "10px",
        color: "black"
    },
    text:{
        marginLeft: "10px",
        fontSize:'0.8em !important',
        padding:'10px',
        fontWeight: 'bold !important',
        color: 'black',
        textTransform: 'uppercase'
    },
    rightIcon:{
        color: theme.palette.yellow.main,
        marginRight: "10px"
    }
}))

export default useStyles