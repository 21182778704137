import store from 'stores/root-store'
import selectAuth from 'stores/auth/auth.selectors'
import { isOnline } from './data'

export const auth = () => JSON.parse(localStorage.getItem('auth'))

export const authHeader = () => {
    // return authorization header with token
    if (auth()) {
        const { token } = auth()
        return { Authorization: `Bearer ${token}` }
    }

    return {}
}

export const isAuthenticated = () => {
    if (auth()) {
        return true
    }

    return false
}

export const formatAuthData = (data) => {
    let authData = {
        token: null,
        user: null,
    }

    if (data !== false) {
        const { user, token } = data
        authData = {
            token,
            user,
        }
    }

    return authData
}

// store token in local storage to keep user logged in between page refreshes
export function handleAuth(response) {
    if (!response.token) return response

    // login successful if there's a token in the response
    localStorage.setItem('auth', JSON.stringify(formatAuthData(response)))
}

// unused since switching to laravel/sanctum
// but token expiration is still supported
// so it could be useful
// so i'm leaving this here
// stop crying
// eslint-disable-next-line no-unused-vars
function shouldRefreshToken() {
    let expired = false

    try {
        const expiration = selectAuth(store.getState()).exp
        const now = Date.now() / 1000
        const tolerance = 60 * 60 * 6 // 6 hours
        if (expiration - now <= tolerance) {
            expired = true
        }
    } catch (err) {
        expired = true
    }

    return isAuthenticated() && isOnline() && expired
}
