import React from "react"
import Layout from "components/Layout/Layout"
import Inner from "components/Inner/Inner"
import ListHeader from "components/ListHeader/ListHeader"
import LoadingContainer from "components/LoadingContainer/LoadingContainer"
import links from "helpers/links"

function ModelLayout({ children, handleSearch, title, isLoading }) {
    return (
        <Layout container title={title}>
            <ListHeader
                title={title}
                onSearch={handleSearch}
                isOnSubmitSearch={false}
                link={links.more}
            />
            <Inner>
                <LoadingContainer isLoading={isLoading}>
                    {children}
                </LoadingContainer>
            </Inner>
        </Layout>)
}

export default ModelLayout