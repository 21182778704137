import React from 'react'
import Typography from '@material-ui/core/Typography'
import { _ } from 'helpers/lang'
import useStyles from './PostSearchContent.styles'
import Inner from 'components/Inner/Inner'
import links from 'helpers/links'
import SinglePostSearch from './SinglePostSearch/SinglePostSearch'
import DrugsIcon from '../../../assets/svg/drugs.svg'
import MedicamentsIcon from '../../../assets/svg/medicaments.svg'
import PlanDeSoinsIcon from '../../../assets/svg/plan-de-soins.svg'
import PoliciesProceduresIcon from 'assets/svg/tasks-solid.svg'
import GuideDePratiqueIcon from 'assets/svg/guide-de-pratique.svg'
import ProgrammeCliniqueIcon from 'assets/svg/programme-clinique.svg'

function PostSearchContent(props) {
    const { programs, policies, guides, drugs, medicaments, troubles, isSinglePostSearch, searchTerm } = props
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Inner>
                {(drugs ? drugs.length === 0 : true) &&
                    (medicaments ? medicaments.length === 0 : true) &&
                    (troubles ? troubles.length === 0 : true) &&
                    (programs ? programs.length === 0 : true) &&
                    (policies ? policies.length === 0 : true) &&
                    (guides ? guides.length === 0 : true) ? (
                    <Typography variant="h2" align="center" paragraph>
                        {_('app.No_results')}
                    </Typography>
                ) : null}
                <SinglePostSearch
                    singlePost={troubles}
                    icon={PlanDeSoinsIcon}
                    link={links.plan}
                    title={_('app.plan_de_soins')}
                    isSinglePostSearch={isSinglePostSearch}
                    searchTerm={searchTerm}
                />

                <SinglePostSearch
                    singlePost={guides}
                    icon={GuideDePratiqueIcon}
                    link={links.guidePratique}
                    title={_('app.guides_de_pratique')}
                    isSinglePostSearch={isSinglePostSearch}
                    searchTerm={searchTerm}
                />

                <SinglePostSearch
                    singlePost={drugs}
                    icon={DrugsIcon}
                    link={links.drug}
                    title={_('app.drugs')}
                    isSinglePostSearch={isSinglePostSearch}
                />

                <SinglePostSearch
                    singlePost={medicaments}
                    icon={MedicamentsIcon}
                    link={links.medicament}
                    title={_('app.medications')}
                    isSinglePostSearch={isSinglePostSearch}
                />

                <SinglePostSearch
                    singlePost={programs}
                    icon={ProgrammeCliniqueIcon}
                    link={links.programmeClinique}
                    title={_('app.programme_clinique')}
                    isSinglePostSearch={isSinglePostSearch}
                    searchTerm={searchTerm}
                />

                <SinglePostSearch
                    singlePost={policies}
                    icon={PoliciesProceduresIcon}
                    link={links.politiquesProcedures}
                    title={_('app.policies_procedures')}
                    isSinglePostSearch={isSinglePostSearch}
                    searchTerm={searchTerm}
                />

            </Inner>
        </div>
    )
}

export default PostSearchContent
