import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    spinner: {
        color: (props) =>
            props.color ? theme.palette[props.color].main : 'white',
    },

    boxCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    container:{
        opacity: ({isLoading})=> isLoading ? 0.3 : 1
    },
    root:{
        position:'relative',
        minHeight:'10em'
    },
    spinnerWrapper:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    }
}))

export default useStyles
