import links from 'helpers/links'

export const removeEmpty = (obj) => {
    Object.keys(obj).forEach(
        (key) =>
            (obj[key] === '' || obj[key] === ' ' || obj[key] == null) &&
            delete obj[key]
    )
    return obj
}

export const flattenChildren = (data) => {
    // copy data without children property
    let result = [...data]

    data.forEach((d) => {
        if (d.children) {
            result = [...result, ...flattenChildren(d.children)]
        }
    })

    return result
}

export const formatPhone = (string) => string.replace(/-|\(|\)|\.|\s/g, '') // removes - . ( ) and spaces

export const getTitleFromSlug = (string) => {
    const a = string.split('-')
    if (!isNaN(a[0])) {
        a.shift()
    }
    a[0] = a[0][0].toUpperCase() + a[0].substring(1)
    return a.join(' ')
}

export const getPostParentUrl = (post) =>
    post.parent ? links.post(post.parent) : links.menu(post.menu)

export const displayDrugName = (drugs, id) =>
    drugs.find((d) => d.id === id).name

export const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() ? `${string.charAt(0).toUpperCase()}${string.slice(1)}` : string
