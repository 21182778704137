import PropTypes from 'prop-types'
import CategoryType from './Category.type'
import DrugWayType from './DrugWay.type'
import MediaType from './Media.type'

const DrugType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    alt: PropTypes.string,
    interactions: PropTypes.string,
    images: PropTypes.arrayOf(MediaType),
    category: CategoryType.isRequired,
    dependency: PropTypes.arrayOf(PropTypes.string).isRequired,
    ways: PropTypes.arrayOf(DrugWayType).isRequired,
    intoxication_observations: PropTypes.array,
    intoxication_strategy: PropTypes.string,
    withdrawal_observations: PropTypes.array,
    withdrawal_observation_physics: PropTypes.array,
    withdrawal_observation_psychologics: PropTypes.array,
    withdrawal_strategy: PropTypes.string,
    clinical_observation_intoxication_introduction: PropTypes.string,
    clinical_observation_physic_introduction: PropTypes.string,
    clinical_observation_psycho_introduction : PropTypes.string
})

export default DrugType
