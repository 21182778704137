const production = {
    environment: 'production',
    debugMode: false,
    debugHttpCalls: false,
    appUrl: 'https://dev.addicta.ca/',
    apiUrl: 'https://api-dev.addicta.ca/api/v1',
    pdfUrl: 'https://api-dev.addicta.ca/storage/pdf/',
}

export default production
