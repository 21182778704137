import { removeEmpty } from 'utils/functions'
import { email } from 'utils/validation'

const validate = (values) => {
    const errors = {
        email: email(values.email),
    }

    return removeEmpty(errors)
}

export default validate
