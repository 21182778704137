import React, { useEffect, useState } from 'react'
import PostTypesEnum from 'constants/enums/PostTypesEnum'
import Layout from 'components/Layout/Layout'
import PostSearchContent from './PostSearchContent/PostSearchContent'
import { connect } from 'react-redux'
import postActions from '../../stores/post/post.actions'
import { selectPosts } from '../../stores/post/post.selectors'
import Header from 'components/Header/Header'
import NavButton from 'components/Button/NavButton/NavButton'
import drugActions from '../../stores/drug/drug.actions'
import { selectDrugs } from '../../stores/drug/drug.selectors'
import ListHeader from 'components/ListHeader/ListHeader'
import { selectMedicaments } from '../../stores/medicament/medicament.selectors'
import SearchListInput from 'components/SearchInput/SearchListInput'
import medicamentActions from '../../stores/medicament/medicament.actions'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import { selectTroubles } from '../../stores/trouble/trouble.selectors'
import { useHistory } from 'react-router-dom'
import troubleActions from '../../stores/trouble/trouble.actions'
import links from 'helpers/links'
import { _ } from '../../helpers/lang'

const mapStateToProps = (state, { match }) => {
    const slug = match.params.slug
    return {
        slug: slug,
        posts: selectPosts(state),
        drugs: selectDrugs(state),
        medicaments: selectMedicaments(state),
        troubles: selectTroubles(state),
    }
}

const mapDispatchToProps = {
    searchPost: postActions.search,
    searchDrug: drugActions.search,
    searchMedicament: medicamentActions.search,
    searchTrouble: troubleActions.search,
}

PostSearch.propTypes = {

}

function PostSearch({
    slug,
    posts,
    drugs,
    medicaments,
    troubles,
    searchPost,
    searchDrug,
    searchMedicament,
    searchTrouble,
}) {
    const [isLoading, setIsLoading] = useState(true)
    const history = useHistory()
    const handleSearch = (e) => {
        history.push(links.post.search(e))
    }

    useEffect(async () => {
        await Promise.all([
            searchPost(slug),
            searchDrug(slug),
            searchMedicament(slug),
            searchTrouble(slug)
        ])
        setIsLoading(false)
    }, [slug])

    const filterPostListByType = (postList, type) => {
        return postList.length > 0 ? postList.filter((p) => p.menu === type) : []
    }

    const programList = (posts) ? (filterPostListByType(posts, PostTypesEnum.PROGRAMME)) : []
    const policyList = (posts) ? (filterPostListByType(posts, PostTypesEnum.POLICY)) : []
    const guideList = (posts) ? (filterPostListByType(posts, PostTypesEnum.GUIDE)) : []

    return (
        <Layout title={'Recherche'} deactivateSearch={true}>
            <ListHeader title={_('app.Results')} onSearch={handleSearch} initialInputValue={slug} isOnSubmitSearch={true} />
            <LoadingContainer isLoading={isLoading}>
                <PostSearchContent
                    programs={programList}
                    policies={policyList}
                    guides={guideList}
                    drugs={drugs}
                    medicaments={medicaments}
                    troubles={troubles}
                    searchTerm={slug}
                />
            </LoadingContainer>
        </Layout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PostSearch)
